define("bet-ember/helpers/multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.multiple = multiple;
  function multiple(params) {
    if (_getNumber(params[0]) == null) {
      return null;
    }
    var res = JSON.parse(params[0]);
    for (var i = 1; i < params.length; i++) {
      if (_getNumber(params[i]) == null) {
        return null;
      }
      res *= JSON.parse(params[i]);
    }
    return res;
  }
  var _default = _exports.default = Ember.Helper.helper(multiple);
});