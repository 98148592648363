define("bet-ember/helpers/get-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getYear = getYear;
  function getYear([date]) {
    var d = getDateOnly(date);
    return d.getFullYear();
  }
  var _default = _exports.default = Ember.Helper.helper(getYear);
});