define("bet-ember/helpers/activity-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activityTypes = activityTypes;
  _exports.default = void 0;
  function activityTypes([profileTypeCode, specialityIdList]) {
    return this.get('activityTypeService').getByProfileTypeCodeAndSpecIds(profileTypeCode, specialityIdList);
  }
  var _default = _exports.default = Ember.Helper.extend({
    activityTypeService: Ember.inject.service('activity-type'),
    compute: activityTypes,
    onRefreshChange: Ember.observer('activityTypeService.refresh', function () {
      this.recompute();
    })
  });
});