define("bet-ember/components/utils/option-list-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: ''
  });
});