define("bet-ember/components/actions-grid-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    setTop() {
      var shellH = 0;
      try {
        shellH = parseInt($(this.element).find('.dropdown-target').css('height').replace('px', ''));
      } catch (err) {}
      var ulH = 0;
      try {
        ulH = parseInt($(this.element).find('ul').css('height').replace('px', ''));
      } catch (err) {}
      var top = $(this.element).find('.dropdown-target').offset().top - $(window).scrollTop() + shellH;
      var viewPortH = $(window).height();
      if (top + ulH > viewPortH) {
        $(this.element).find('.dropdown-target').addClass('direction-up');
      } else {
        $(this.element).find('.dropdown-target').removeClass('direction-up');
      }
      $(this.element).find('ul').css("top", top);
    },
    setLeft() {
      $(this.element).find('ul').css("left", $(this.element).find('.dropdown-target').offset().left - ($(this.element).find('.table-responsive').scrollLeft() || 0));
    },
    onElementInserted: Ember.on('didInsertElement', function () {
      if (this.get('fixedPosition') == true) {
        $(this.element).find('.dropdown-target').unbind('click');
        var _this = this;
        $(this.element).find('.dropdown-target').bind('click', function (e) {
          _this.setTop();
          _this.setLeft();
          $(document).unbind('scroll');
          $(document).bind('scroll', function () {
            _this.setTop();
          });
          $('.table-responsive').bind('scroll', function () {
            _this.setLeft();
          });
        });
      }
    }),
    willDestroyElement: Ember.on('willDestroyElement', function () {
      this._super(...arguments);
      if (this.get('fixedPosition') == true) {
        $(document).unbind('scroll');
        $(this.element).find('.dropdown-target').unbind('click');
      }
    })
  });
});