define("bet-ember/components/progress-bar-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['progress double-color'],
    onDidRender: Ember.on('didRender', Ember.observer('progress', function () {
      $(this.element).find('.percent-indicator.primary').css('width', $(this.element).width());
    }))
  });
});