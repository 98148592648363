define("bet-ember/initializers/utils", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    let app = arguments[1] || arguments[0];
    $.fn.isOnScreen = function (callback, offsets) {
      offsets = offsets || {};
      var height = this.outerHeight();
      var width = this.outerWidth();
      if (!width || !height) {
        return false;
      }
      var win = $(window);
      var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
      };
      viewport.right = viewport.left + win.width();
      viewport.bottom = viewport.top + win.height();
      var bounds = this.offset();
      bounds.right = bounds.left + width;
      bounds.bottom = bounds.top + height;
      var deltas = {
        top: viewport.bottom - bounds.top,
        left: viewport.right - bounds.left,
        bottom: bounds.bottom - (viewport.top + (offsets.top || 0)),
        right: bounds.right - viewport.left
      };
      if (typeof callback == 'function') {
        return callback.call(this, deltas);
      }
      return deltas.top > 0 && deltas.left > 0 && deltas.right > 0 && deltas.bottom > 0;
    };
    window.precision = function (a) {
      if (!isFinite(a)) return 0;
      var e = 1,
        p = 0;
      while (Math.round(a * e) / e !== a) {
        e *= 10;
        p++;
      }
      return p;
    };
    window.serializeTime = function (time) {
      time = time || {};
      var hour = pad(time.hour || 0, 2);
      var minute = pad(time.minute || 0, 2);
      var second = pad(time.second || 0, 2);
      var nano = pad(time.nano || 0, 2);
      return hour + ":" + minute + ":" + second + "." + nano;
    };
    window.base64toBlob = function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {
        type: contentType
      });
      return blob;
    };
    window.pad = function (val, len) {
      val = String(val);
      len = len || 2;
      while (val.length < len) {
        val = "0" + val;
      }
      return val;
    };
    window.customSerialize = function (obj, prop, forceKiss) {
      obj = getObject(obj);
      if (prop) {
        prop = getObject(getProperty(obj, prop));
      } else {
        prop = obj;
      }
      if (prop && prop.store) {
        if (prop.get('id') && forceKiss) {
          return null;
        }
        var serializer = prop.store.serializerFor(prop.constructor.modelName);
        if (serializer && serializer.simpleSerialize) {
          return serializer.simpleSerialize(prop, true);
        } else {
          return prop.serialize();
        }
      }
      return null;
    };
    window.customSerializeArray = function (obj, prop, forceKiss) {
      obj = getObject(obj);
      if (forceKiss) {
        return [];
      }
      var arr = [];
      (getObject(getProperty(obj, prop)) || []).toArray().forEach(item => {
        var serializedItem = customSerialize(item);
        if (serializedItem) {
          arr.push(serializedItem);
        }
      });
      return arr;
    };
    window.setProperty = function (obj, prop, val, transform) {
      try {
        if (transform) {
          val = transform(val);
        }
        if (obj.set) {
          obj.set(prop, val);
        } else {
          obj[prop] = val;
        }
      } catch (ex) {}
    };
    window.getProperty = function (obj, prop, defVal) {
      defVal = defVal != null ? defVal : null;
      if (obj == null) {
        return defVal || null;
      }
      try {
        if (obj.get) {
          return obj.get(prop) != null ? obj.get(prop) : defVal;
        } else {
          if (prop.indexOf('.') !== -1) {
            var parts = prop.split('.');
            parts.forEach(part => {
              obj = getProperty(obj, part);
            });
            return obj != null ? obj : defVal;
          } else {
            return obj[prop] != null ? obj[prop] : defVal;
          }
        }
      } catch (ex) {
        return defVal || null;
      }
    };
    window.objectToArray = function (obj) {
      return Object.keys(obj).map(key => obj[key]) || [];
    };
    window.getObject = function (obj) {
      if (obj && obj.constructor && obj.constructor.modelName) {
        return obj;
      }
      if (obj && obj.content !== undefined) {
        return obj.content;
      }
      return obj;
    };
    window._getVal = function (val) {
      try {
        return JSON.parse(val);
      } catch (ex) {
        if (val == "undefined" || val == undefined) {
          return null;
        }
        return val;
      }
    };
    window._getNumber = function (val) {
      try {
        val = JSON.parse(val);
        if (isNaN(val)) {
          return null;
        } else {
          return val;
        }
      } catch (ex) {
        return null;
      }
    };
    window.copyObject = function (obj) {
      return JSON.parse(JSON.stringify(obj));
    };
    window.createNewElement = function (type, innerHTML, cssClass) {
      var el = document.createElement(type);
      if (type == "td") {
        el.setAttribute('class', 'text');
      }
      if (innerHTML) {
        el.innerHTML = innerHTML;
      }
      if (cssClass) {
        $(el).addClass(cssClass);
      }
      return el;
    };
    window.logger = function (val) {
      console.log(val);
    };
    window.TryParse = function (val, key) {
      if (val != null) {
        if (key) {
          if (val.get && val.get) {
            try {
              return JSON.parse(val.get(key));
            } catch (ex) {
              return val.get(key);
            }
          } else {
            try {
              return JSON.parse(val[key]);
            } catch (ex) {
              return val[key];
            }
          }
        } else {
          try {
            return JSON.parse(val);
          } catch (ex) {
            return val;
          }
        }
      }
      return null;
    };
    window._removeNulls = function (obj) {
      if (Array.isArray(obj)) {
        for (var i = obj.length - 1; i >= 0; i--) {
          obj[i] = _removeNulls(obj[i]);
          if (obj[i] === null) {
            obj.splice(i, 1);
          }
        }
        if (obj.length <= 0) {
          return null;
        }
      } else if (typeof obj === 'object') {
        for (var k in obj) {
          if (obj[k] === null) {
            delete obj[k];
          } else {
            obj[k] = _removeNulls(obj[k]);
            if (obj[k] === null) {
              delete obj[k];
            }
          }
        }
        if ($.isEmptyObject(obj)) {
          return null;
        }
      } else {
        return obj;
      }
      return obj;
    };
    window.customDateFormat = function (date, mask, utc) {
      var dF = {
        masks: {
          "default": "ddd mmm dd yyyy HH:MM:ss",
          shortDate: "m/d/yy",
          mediumDate: "mmm d, yyyy",
          longDate: "mmmm d, yyyy",
          fullDate: "dddd, mmmm d, yyyy",
          shortTime: "h:MM TT",
          mediumTime: "h:MM:ss TT",
          longTime: "h:MM:ss TT Z",
          isoDate: "yyyy-mm-dd",
          isoTime: "HH:MM:ss",
          isoDateTime: "yyyy-mm-dd'T'HH:MM:ss",
          isoUtcDateTime: "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'"
        },
        // Internationalization strings
        intl: {
          dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
      };
      var token = /d{1,4}|m{1,4}|yy(?:yy)?|([HhMsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g,
        timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g,
        timezoneClip = /[^-+\dA-Z]/g,
        pad = function (val, len) {
          val = String(val);
          len = len || 2;
          while (val.length < len) {
            val = "0" + val;
          }
          return val;
        };
      // You can't provide utc if you skip other args (use the "UTC:" mask prefix)
      if (arguments.length == 1 && Object.prototype.toString.call(date) == "[object String]" && !/\d/.test(date)) {
        mask = date;
        date = undefined;
      }

      // Passing date through Date applies Date.parse, if necessary
      //date = date ? new Date(date) : new Date;
      //  if (isNaN(date)) throw SyntaxError("invalid date");

      date = date ? new Date(date) : null;
      if (isNaN(date) || date == null) {
        return null;
      }
      mask = String(dF.masks[mask] || mask || dF.masks["default"]);

      // Allow setting the utc argument via the mask
      if (mask.slice(0, 4) == "UTC:") {
        mask = mask.slice(4);
        utc = true;
      }
      var _ = utc ? "getUTC" : "get",
        d = date[_ + "Date"](),
        D = date[_ + "Day"](),
        m = date[_ + "Month"](),
        y = date[_ + "FullYear"](),
        H = date[_ + "Hours"](),
        M = date[_ + "Minutes"](),
        s = date[_ + "Seconds"](),
        L = date[_ + "Milliseconds"](),
        o = utc ? 0 : date.getTimezoneOffset(),
        flags = {
          d: d,
          dd: pad(d),
          ddd: dF.intl.dayNames[D],
          dddd: dF.intl.dayNames[D + 7],
          m: m + 1,
          mm: pad(m + 1),
          mmm: dF.intl.monthNames[m],
          mmmm: dF.intl.monthNames[m + 12],
          yy: String(y).slice(2),
          yyyy: y,
          h: H % 12 || 12,
          hh: pad(H % 12 || 12),
          H: H,
          HH: pad(H),
          M: M,
          MM: pad(M),
          s: s,
          ss: pad(s),
          l: pad(L, 3),
          L: pad(L > 99 ? Math.round(L / 10) : L),
          t: H < 12 ? "a" : "p",
          tt: H < 12 ? "am" : "pm",
          T: H < 12 ? "A" : "P",
          TT: H < 12 ? "AM" : "PM",
          Z: utc ? "UTC" : (String(date).match(timezone) || [""]).pop().replace(timezoneClip, ""),
          o: (o > 0 ? "-" : "+") + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
          S: ["th", "st", "nd", "rd"][d % 10 > 3 ? 0 : (d % 100 - d % 10 != 10) * d % 10]
        };
      return mask.replace(token, function ($0) {
        return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
      });
    };
    Ember.TextSupport.reopen({
      attributeBindings: ['data-match', 'data-match-error']
    });
    Array.prototype.clean = function () {
      var newArray = new Array();
      for (var i = 0; i < this.length; i++) {
        if (this[i] != null) {
          newArray.push(this[i]);
        }
      }
      return newArray;
    };
    Array.prototype.contains = function (val) {
      for (var i = 0; i < this.length; i++) {
        if (this[i] == val) {
          return true;
        }
      }
      return false;
    };
    Array.prototype.containsByKey = function (key, val) {
      for (var i = 0; i < this.length; i++) {
        if (this[i] && this[i][key] == val) {
          return true;
        }
      }
      return false;
    };
    Array.prototype.sortNumberArray = function (decs) {
      this.sort(function (a, b) {
        if (decs) {
          return b - a;
        } else {
          return a - b;
        }
      });
      return this;
    };
    Array.prototype.sortByNumberKey = function (key, desc) {
      if (desc) {
        this.sort(function (a, b) {
          return getProperty(b, key) - getProperty(a, key);
        });
      } else {
        this.sort(function (a, b) {
          return getProperty(a, key) - getProperty(b, key);
        });
      }
      return this;
    };
    Array.prototype.sortByStringKey = function (key, desc) {
      if (desc) {
        this.sort(function (a, b) {
          var str1 = "" + (getProperty(a, key) || "");
          var str2 = "" + (getProperty(b, key) || "");
          return str2.localeCompare(str1);
        });
      } else {
        this.sort(function (a, b) {
          var str1 = "" + (getProperty(a, key) || "");
          var str2 = "" + (getProperty(b, key) || "");
          return str1.localeCompare(str2);
        });
      }
      return this;
    };
    window.extractKeyFromPath = function () {
      var currentPath = Ember.__app_path__;
      if (currentPath) {
        var parts = currentPath.split('.');
        if (parts.length > 1) {
          return parts[1];
        }
      }
      return '';
    };
    window.arraysAreEquals = function (array1, array2) {
      if (!array1 || !array2 || !Array.isArray(array1) || !Array.isArray(array2) || array1.length != array2.length) return false;
      for (var i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
          if (!arraysAreEquals(array1[i], array2[i])) return false;
        }
        /**REQUIRES OBJECT COMPARE**/else if (array1[i] instanceof Object && array2[i] instanceof Object) {
          // recurse into another objects
          //console.log("Recursing to compare ", this[propName],"with",object2[propName], " both named \""+propName+"\"");
          if (!objectsAreEquals(array1[i], array2[i])) return false;
        } else if (array1[i] != array2[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    };
    window.objectsAreEquals = function (object1, object2) {
      //For the first loop, we only check for types
      for (propName in object1) {
        //Check for inherited methods and properties - like .equals itself
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwnProperty
        //Return false if the return value is different
        if (object1.hasOwnProperty(propName) != object2.hasOwnProperty(propName)) {
          return false;
        }
        //Check instance type
        else if (typeof object1[propName] != typeof object2[propName]) {
          //Different types => not equal
          return false;
        }
      }
      //Now a deeper check using other objects property names
      for (propName in object2) {
        //We must check instances anyway, there may be a property that only exists in object2
        //I wonder, if remembering the checked values from the first loop would be faster or not
        if (object1.hasOwnProperty(propName) != object2.hasOwnProperty(propName)) {
          return false;
        } else if (typeof object1[propName] != typeof object2[propName]) {
          return false;
        }
        //If the property is inherited, do not check any more (it must be equa if both objects inherit it)
        if (!object1.hasOwnProperty(propName)) continue;

        //Now the detail check and recursion

        //This returns the script back to the array comparing
        /**REQUIRES Array.equals**/
        if (object1[propName] instanceof Array && object2[propName] instanceof Array) {
          // recurse into the nested arrays
          if (!arraysAreEquals(object1[propName], object2[propName])) return false;
        } else if (object1[propName] instanceof Object && object2[propName] instanceof Object) {
          // recurse into another objects
          //console.log("Recursing to compare ", this[propName],"with",object2[propName], " both named \""+propName+"\"");
          if (!objectsAreEquals(object1[propName], object2[propName])) return false;
        }
        //Normal value comparison for strings and numbers
        else if (object1[propName] != object2[propName]) {
          return false;
        }
      }
      //If everything passed, let's say YES
      return true;
    };
    window.monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    window.weekDayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    window.getNextDayWeek = function (date) {
      date = getDateOnly(date, true);
      date = new Date(date.setDate(date.getDate() + 1));
      return date.getWeekNumber();
    };
    window.timeToString = function (time) {
      if (time && time.hour != null && time.minute != null && time.second != null) {
        return pad(time.hour || 0, 2) + ":" + pad(time.minute || 0, 2) + ":" + pad(time.second || 0, 2);
      } else {
        return null;
      }
    };
    window.getUTCFromDate = function (date) {
      try {
        date = date || new Date();
        return date.getTime();
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    window.getUTCThruDate = function (date) {
      try {
        var timestamp = new Date(date);
        var value = timestamp.getTime() + 60 * 60 * 24 * 1000 - 1000;
        return value;
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    window.getLocalFromDate = function (date) {
      try {
        date = date || new Date();
        return date.getTime() + date.getTimezoneOffset() * 60 * 1000;
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    window.getLocalThruDate = function (date) {
      try {
        date = date || new Date();
        var timestamp = new Date(date);
        var value = timestamp.getTime() + (60 * 60 * 24 * 1000 - 1000) + date.getTimezoneOffset() * 60 * 1000;
        return value;
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    window.getServerDate = function (date) {
      try {
        var timestamp = new Date(date);
        return timestamp.getTime();
      } catch (err) {
        console.log(err);
        return null;
      }
    };
    window.getBeginningOfDay = function (date) {
      date = getDateOnly(date);
      date.setSeconds(0);
      date.setMinutes(0);
      date.setHours(0);
      return new Date(date);
    };
    window.getEndOfDay = function (date) {
      date = getDateOnly(date);
      date.setSeconds(59);
      date.setMinutes(59);
      date.setHours(23);
      return new Date(date);
    };
    window.getBeginningOfWeek = function (date) {
      date = getBeginningOfDay(date);
      var day = date.getDay();
      return new Date(date.setDate(date.getDate() - day + (day == 0 ? -6 : 1)));
    };
    window.getEndOfWeek = function (date) {
      date = getBeginningOfWeek(date);
      return new Date(date.setDate(date.getDate() + 6));
    };
    window.getBeginningOfMonth = function (date) {
      date = getBeginningOfDay(date);
      return new Date(date.setDate(1));
    };
    window.getEndOfMonth = function (date) {
      date = getBeginningOfMonth(date);
      date = new Date(date.setMonth(date.getMonth() + 1));
      date = date.setDate(date.getDate() - 1);
      return new Date(date);
    };
    window.getDateOnly = function (dateTime, startOfDay) {
      dateTime = dateTime || new Date();
      dateTime = new Date(dateTime);
      var date = new Date();
      date.setFullYear(dateTime.getFullYear());
      date.setMonth(dateTime.getMonth(), dateTime.getDate());
      // date.setDate(dateTime.getDate());
      if (startOfDay) {
        date.setHours(0);
      } else {
        date.setHours(12);
      }
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    };
    window.getDateOnlyUTC = function (dateTime, startOfDay) {
      dateTime = dateTime || new Date();
      dateTime = new Date(dateTime);
      var date = new Date();
      date.setUTCFullYear(dateTime.getFullYear());
      date.setUTCMonth(dateTime.getMonth(), dateTime.getDate());
      // date.setUTCDate(dateTime.getDate());
      if (startOfDay) {
        date.setUTCHours(0);
      } else {
        date.setUTCHours(12);
      }
      date.setUTCMinutes(0);
      date.setUTCSeconds(0);
      date.setUTCMilliseconds(0);
      return date;
    };
    window.getTimeOnly = function (date) {
      date = new Date(date);
      return pad(date.getHours(), 2) + ":" + pad(date.getMinutes(), 2);
    };
    window.getTimeOnlyWithSec = function (date) {
      date = new Date(date);
      return pad(date.getHours(), 2) + ":" + pad(date.getMinutes(), 2) + ":" + pad(date.getSeconds(), 2);
    };
    window.getDaysBetween = function (val1, val2) {
      if (val1 == null) {
        return null;
      }
      if (val2 == null) {
        return null;
      }
      val1 = new Date(val1);
      val1.setHours(0);
      val1.setMinutes(0);
      val1.setSeconds(0);
      val2 = new Date(val2);
      val2.setHours(0);
      val2.setMinutes(0);
      val2.setSeconds(0);
      val1 = val1.getTime();
      val2 = val2.getTime();
      var dif = Math.round((val1 - val2) / (1000 * 60 * 60 * 24));
      if (Math.abs(dif) == 0) {
        return 0;
      }
      return dif;
    };
    window.getDayOfWeekName = function (date) {
      var weekdays = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
      date = date ? new Date(date) : new Date();
      var day = date.getDay();
      //Return the element that corresponds to that index.
      return weekdays[day];
    };
    window.getMonday = function (d) {
      d = d ? new Date(d) : new Date();
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    };
    Date.prototype.getWeekNumber = function () {
      var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
      var dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    };
    window.scrollToTop = function () {
      $("html, body").animate({
        scrollTop: 0
      }, 1);
    };
    window.sepDigits = function (val) {
      val = val + "";
      var parts = val.split("").reverse();
      var res = "";
      for (var i = 0; i < parts.length; i++) {
        res += parts[i];
        if (i != 0 && (i + 1) % 3 == 0) {
          res += " ";
        }
      }
      return res.split("").reverse().join("");
    };
    window.urlB64ToUint8Array = function (base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    };
    app.inject('controller', 'utils', 'service:utils');
    app.inject('component', 'utils', 'service:utils');
    app.inject('route', 'utils', 'service:utils');
    app.inject('router', 'utils', 'service:utils');
    app.inject('template', 'utils', 'service:utils');
    app.inject('helper', 'utils', 'service:utils');
    app.inject('mixin', 'utils', 'service:utils');
  }
  var _default = _exports.default = {
    name: 'utils',
    initialize
  };
});