define("bet-ember/components/events/auto-bet/market-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    actions: {
      loadMarket(marketPosition) {
        this.set('market.marketPosition', marketPosition);
        if (this.get('onLoadMarket')) {
          this.get('onLoadMarket')(this.get('market'), marketPosition);
        }
        // this.store.query('market',{
        //   endPoint: "marketBooks",
        //   queryParams: {
        //     marketIds: this.get('market.marketId')
        //   }
        // }).catch((err) => {
        //   logger(err);
        // });
      }
    }
  });
});