define("bet-ember/components/pagination-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    model: {},
    tagName: "",
    suppressOnChangePageSize: true,
    onInit: Ember.on('init', function () {
      if (this.get('usePreferredPageSize') == false) {
        this.set('pageSize', 10);
      } else {
        this.set('pageSize', this.storage.getItem('preferredPageSize'));
      }
    }),
    onDidInsertElement: Ember.on('didInsertElement', function () {
      this.set('suppressOnChangePageSize', false);
    }),
    itemCount: Ember.computed('model.@each', function () {
      if (this.get('model')) {
        return this.get('model').itemCount;
      }
      return 0;
    }),
    currentPage: Ember.computed('model.@each', 'pageSize', function () {
      if (this.get('model')) {
        return parseInt(this.get('model').startIndex / this.get('pageSize') + 1);
      }
      return 0;
    }),
    pageCount: Ember.computed('model.@each', 'pageSize', function () {
      if (this.get('model')) {
        return Math.ceil(this.get('model').itemCount / this.get('pageSize'));
      }
      return 0;
    }),
    pages: Ember.computed('model.@each', 'pageCount', function () {
      if (this.get('suppressOnChangePageSize') == false && this.get('onPageChange')) {
        var pageCount = this.get('pageCount');
        if (pageCount) {
          var arr = [];
          for (var i = 1; i <= pageCount; i++) {
            arr.push(i);
          }
          return arr;
        } else {
          return [0];
        }
      }
    }),
    pageSizeObserver: Ember.observer('pageSize', 'itemCount', function () {
      if (this.get('anchorToBottomFunc') == false) {
        return;
      }
      var pageSize = this.get('pageSize') || 10;
      pageSize = JSON.parse(pageSize);
      var itemCount = this.get('itemCount') || 0;
      itemCount = JSON.parse(itemCount);
      if (pageSize > 10 && itemCount > 10) {
        $('body').addClass('sticked-pagination');
      } else {
        $('body').removeClass('sticked-pagination');
      }
    }),
    pageSizeObs: Ember.observer('pageSize', function () {
      var pageSize = this.get('pageSize') || 10;
      if (this.get('usePreferredPageSize') != false) {
        this.storage.setItem('preferredPageSize', pageSize);
      }
      if (this.get('suppressOnChangePageSize') == false && this.get('onPageChange')) {
        this.get('onPageChange')(1, pageSize);
      }
    }),
    currentPageObserver: Ember.observer('currentPage', function () {
      this.set('pageNumber', this.get('currentPage'));
    }),
    actions: {
      nextPage() {
        let nextPage = this.get('currentPage');
        if (this.get('currentPage') < this.get('pageCount')) {
          nextPage++;
        }
        return this.get('onPageChange')(nextPage, this.get('pageSize'));
      },
      previousPage() {
        let previousPage = this.get('currentPage');
        if (this.get('currentPage') > 1) {
          previousPage--;
        }
        return this.get('onPageChange')(previousPage, this.get('pageSize'));
      },
      changePage(page) {
        page = TryParse(page) || 1;
        if (page > TryParse(this.get('pageCount'))) {
          page = TryParse(this.get('pageCount'));
        } else if (page < 1) {
          paget = 1;
        }
        this.set('btnsStatus', 'LOADING');
        return this.get('onPageChange')(page, this.get('pageSize')).then(() => this.set('btnsStatus', 'READY'));
      }
    }
  });
});