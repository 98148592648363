define("bet-ember/helpers/pretty-reg-num", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._prettyRegNum = _prettyRegNum;
  _exports.default = void 0;
  function _prettyRegNum([val]) {
    return prettyRegNum(val);
  }
  var _default = _exports.default = Ember.Helper.helper(_prettyRegNum);
});