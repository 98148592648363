define("bet-ember/templates/components/file-select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "29CMHMG8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label line-fix\"],[8],[0,\"\\n\\n    \"],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showProgresBar\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"progress-bar-component\",null,[[\"progress\"],[[24,[\"progress\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[24,[\"cssClass\"]],[24,[\"cssClass\"]],\"btn btn-default mr-5 btn-block search-btns\"],null]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"onClick\"]],[8],[1,[22,\"buttonText\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"selectedFile\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[28,\"if\",[[24,[\"cssClass\"]],[24,[\"cssClass\"]],\"btn btn-default mr-5 btn-block search-btns\"],null]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[0,\"Remove\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"accept\",\"class\"],[\"file\",[24,[\"file\"]],[24,[\"accept\"]],\"file-uploader-input hidden\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/file-select-component.hbs"
    }
  });
});