define("bet-ember/helpers/number-to-arr-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arr = arr;
  _exports.default = void 0;
  function arr([count, start]) {
    var elements = [];
    for (var i = start || 0; i < count; i++) {
      elements.push(i);
    }
    return elements;
  }
  var _default = _exports.default = Ember.Helper.helper(arr);
});