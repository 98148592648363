define("bet-ember/templates/components/direct-file-select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IRhSL3Ii",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"value\",\"accept\",\"class\"],[\"file\",[24,[\"file\"]],[24,[\"accept\"]],\"file-uploader-input hidden\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/direct-file-select-component.hbs"
    }
  });
});