define("bet-ember/mixins/custom-serializer-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    simpleSerialize(record, forceKiss) {
      record = getObject(record);
      if (!record) {
        return null;
      }
      var {
        fields,
        objects,
        arrays
      } = this.get('properties');
      fields = fields || [];
      objects = objects || [];
      arrays = arrays || [];
      fields = fields.concat(['id', 'fromDate', 'thruDate']);
      var json = {};
      fields.forEach(key => {
        if (typeof key == 'object') {
          var fieldVal = getProperty(record, key.key);
          if (key.transform) {
            fieldVal = key.transform(fieldVal);
          }
          json[key.key] = fieldVal;
        } else {
          json[key] = getProperty(record, key);
        }
      });
      objects.forEach(key => {
        if (typeof key == 'object') {
          var customKey = key.key;
          var customForceKiss = key.forceKiss;
          if (customForceKiss === 'idOnly') {
            try {
              var id = getProperty(getProperty(record, customKey), 'id');
              if (id) {
                json[customKey] = {
                  id: id
                };
              }
            } catch (err) {}
            ;
          } else if (!customForceKiss) {
            json[customKey] = customSerialize(record, customKey);
          }
        } else {
          json[key] = customSerialize(record, key);
        }
      });
      arrays.forEach(key => {
        if (typeof key == 'object') {
          var customKey = key.key;
          var customForceKiss = key.forceKiss;
          if (!customForceKiss) {
            json[customKey] = customSerializeArray(record, customKey);
          }
        } else {
          json[key] = customSerializeArray(record, key);
        }
      });
      return json;
    }
  });
});