define("bet-ember/mixins/store-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    modelNamesToRemoveAllNew: Ember.computed(function () {
      return ['user', 'project', 'tech', 'user-project-role', 'user-tech', 'project-tech', 'project-request'];
    }),
    removeAllNew(model) {
      if (model && model.eachRelationship) {
        model.eachRelationship((name, descriptor) => {
          if (descriptor.kind == "hasMany") {
            model.get(name).filter(item => {
              return item.get('id') == null;
            }).forEach(item => {
              model.get(name).removeObject(item);
            });
          }
        });
      }
      var modelNames = this.get('modelNamesToRemoveAllNew');
      for (var i = 0; i < modelNames.length; i++) {
        var items = this.peekAll(modelNames[i]);
        try {
          items = items.toArray();
        } catch (err) {
          try {
            items = items.content.toArray();
          } catch (err) {}
        }
        try {
          items.forEach(function (record) {
            if (record && record.get && record.get('isNew')) {
              if (record.clearRelationships) {
                record.clearRelationships();
              }
              this.unloadRecord(record);
            }
          }, this);
        } catch (err) {}
      }
    },
    unloadRecords() {
      for (var i = 0; i < arguments.length; i++) {
        var modelName = arguments[i].name;
        var ids = arguments[i].ids;
        if (typeof ids === "string") {
          ids = ids.split(',');
        } else if (Array.isArray(ids)) {
          ids = ids;
        } else {
          return;
        }
        for (var j = 0; j < ids.length; j++) {
          var record = this.peekRecord(modelName, ids[i]);
          if (record && record.unloadRecord) {
            record.unloadRecord();
          }
        }
      }
    },
    getRecord(type, id) {
      var record = this.peekRecord(type, id);
      if (record) {
        return Ember.RSVP.Promise.resolve(record);
      }
      return this.findRecord(type, id, {
        reload: true
      });
    }
  });
});