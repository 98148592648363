define("bet-ember/components/locale-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    setPreferredLanguage: Ember.on('init', function () {
      var langCode = this.storage.getItem('preferredLanguage') || "bg";
      this.set('langCode', langCode);
      this.set('intl.locale', langCode + '-' + langCode);
    }),
    actions: {
      setLanguage: function (langCode) {
        this.set('intl.locale', langCode + '-' + langCode);
        this.storage.setItem('preferredLanguage', langCode);
        this.set('langCode', langCode);
      }
    }
  });
});