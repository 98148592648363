define("bet-ember/components/menus/top-menu-component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    session: Ember.inject.service('session'),
    actions: {
      makeBet() {
        return this.customAjax.call({
          type: "POST",
          endPoint: "bet/makeBet"
        }).then(payload => {
          logger(payload);
        }).catch(err => {
          logger(err);
        });
      },
      login() {
        return this.customAjax.call({
          type: "POST",
          endPoint: "login"
        }).then(payload => {
          logger(payload);
        }).catch(err => {
          logger(err);
        });
      },
      goto(path) {
        $('#menu.main-nav').removeClass('toggle');
        $('.user-panel').removeClass('toggle');
        this.router.transitionTo(path);
      },
      toggleUserPanel() {
        $('#menu.main-nav').removeClass('toggle');
        $('.user-panel').toggleClass('toggle');
      },
      toggleMenu() {
        $('.user-panel').removeClass('toggle');
        $('#menu.main-nav').toggleClass('toggle');
      }
    }
  });
});