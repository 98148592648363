define("bet-ember/routes/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.MainRoute.extend({
    session: Ember.inject.service('session'),
    beforeModel: function (transition) {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('login');
        return;
      }
      var promises = {};
      Ember.customServices.forEach(item => {
        if (this.get(item[0]) && this.get(item[0]).setup) {
          promises[item[0]] = this.get(item[0]).setup().catch(() => {});
        }
      });
      return Ember.RSVP.hashSettled(promises);
    }
  });
});