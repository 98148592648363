define("bet-ember/instance-initializers/web-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    var servicesToSetup = ['storage'];
    servicesToSetup.forEach(function (serviceName) {
      var service = app.lookup('service:' + serviceName);
      if (service && service.setup) {
        service.setup();
      }
    });
  }
  var _default = _exports.default = {
    name: 'web-client',
    initialize
  };
});