define("bet-ember/helpers/reverse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reverse = reverse;
  function reverse([arr]) {
    arr = arr || [];
    return arr.reverse();
  }
  var _default = _exports.default = Ember.Helper.helper(reverse);
});