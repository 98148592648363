define("bet-ember/templates/components/draw-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rO7DZ7/M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[11,\"id\",[22,\"componentId\"]],[11,\"style\",[29,[\"height: \",[28,\"or\",[[24,[\"height\"]],480],null],\"px; width: 100%;\"]]],[8],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/draw-component.hbs"
    }
  });
});