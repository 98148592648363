define("bet-ember/components/bets/search-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.FilterCustomComponent.extend({
    filterFields: Ember.computed(function () {
      var all = this.eventTypeService.get('all').map(p => "event-type-" + p.get('id'));
      all.push({
        field: "marketStartTimeFrom",
        transform: val => {
          return getBeginningOfDay(val).getTime();
        }
      });
      all.push({
        field: "marketStartTimeTo",
        transform: val => {
          return getEndOfDay(val).getTime();
        }
      });
      all.push("timeFrame");
      all.push("matrixId");
      return all;
    }),
    modelName: 'bet',
    endPoint: 'bets',
    preRequest(filter) {
      if (filter.timeFrame == "today") {
        filter.marketStartTimeFrom = getBeginningOfDay().getTime();
        filter.marketStartTimeTo = getEndOfDay().getTime();
      } else if (filter.timeFrame == "thisWeek") {
        filter.marketStartTimeFrom = getBeginningOfWeek().getTime();
        filter.marketStartTimeTo = getEndOfWeek().getTime();
      }
      delete filter.timeFrame;
    },
    actions: {
      checkCurrentBet(item) {
        return this.customAjax.call({
          type: "POST",
          endPoint: "bet/checkCurrent?betId=" + item.get('betId')
        }).then(payload => {
          logger(payload);
        }).catch(err => {
          logger(err);
        });
      },
      checkClearedBet(item) {
        return this.customAjax.call({
          type: "POST",
          endPoint: "bet/checkCleared?betId=" + item.get('betId')
        }).then(payload => {
          logger(payload);
        }).catch(err => {
          logger(err);
        });
      },
      delete(model) {
        return model.destroyRecord().then(() => {
          this.alert.show({
            message: this.intl.t('deleted')
          });
          this.get('items').removeObject(model);
        }).catch(err => {
          this.alert.displayServerErrors(err);
          model.rollback();
        });
      }
    }
  });
});