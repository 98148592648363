define("bet-ember/templates/home/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0H8HxoK+",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[1,[22,\"events/search-component\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/home/events.hbs"
    }
  });
});