define("bet-ember/helpers/fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fixed = fixed;
  function fixed([val, precision]) {
    var precision = precision != null ? precision : 2;
    try {
      return JSON.parse(val).toFixed(JSON.parse(precision));
    } catch (err) {
      return 0;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(fixed);
});