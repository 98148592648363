define("bet-ember/templates/components/summernote-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "09wwtB2Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"id\",[22,\"componentId\"]],[8],[9],[0,\"\\n  \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/summernote-component.hbs"
    }
  });
});