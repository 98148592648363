define("bet-ember/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;
  function add(params) {
    var res = 0;
    if (_getNumber(params[0]) != null) {
      res = JSON.parse(params[0]);
    }
    for (var i = 1; i < params.length; i++) {
      if (_getNumber(params[i]) != null) {
        res += JSON.parse(params[i]);
      }
    }
    return res;
  }
  var _default = _exports.default = Ember.Helper.helper(add);
});