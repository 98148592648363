define("bet-ember/helpers/is-customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    currentUser: Ember.inject.service('current-user'),
    compute() {
      let currentUser = this.get('currentUser');
      if (currentUser && currentUser.get('highestRole.roleType.roleTypeCode') == "USER_ROLE_CUSTOMER") {
        return true;
      }
      return false;
    }
  });
});