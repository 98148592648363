define("bet-ember/components/slider-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    onCompInit: Ember.on('didInsertElement', 'didUpdate', Ember.observer('value', function () {
      var value = this.get('value');
      this.set('internalValue', value);
    })),
    selectValue(value) {
      this.set('internalValue', value);
      if (this.get('onChange')) {
        this.get('onChange')(value);
      }
      if (this.get('_onChange')) {
        this.get('_onChange')(value);
      }
    },
    actions: {
      changed(value) {
        this.selectValue(value);
      }
    }
  });
});