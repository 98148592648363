define("bet-ember/mixins/remember-scroll-possition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    manualScrollInit: false,
    onInitRememberScroll: Ember.on('didInsertElement', function () {
      if (this.get('manualScrollInit')) {
        return;
      }
      this.initRememberScrollPossition();
    }),
    initRememberScrollPossition() {
      $(document).scroll(this.rememberPossition.bind(this));
      var state = this.storage.getSessionState(this.get('scrollSessionStateId'));
      if (state && state.scrollY) {
        this.setScrollPossition(state.scrollY);
      } else {
        scrollToTop();
      }
    },
    setScrollPossition(scrollY) {
      setTimeout(() => {
        logger(document.documentElement.scrollHeight + " - " + scrollY);
        if (document.documentElement.scrollHeight < scrollY) {
          this.setScrollPossition(scrollY);
        } else {
          setTimeout(() => {
            window.scrollTo(0, scrollY);
          }, 100);
        }
      }, 100);
    },
    rememberPossition(e) {
      this.storage.setSessionState({
        id: this.get('scrollSessionStateId'),
        scrollY: window.scrollY
      });
    },
    onWillDestroyComp: Ember.on('willDestroyElement', function () {
      try {
        $(document).off('scroll');
      } catch (e) {
        logger(e);
      }
    })
  });
});