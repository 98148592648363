define("bet-ember/templates/components/locale-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dcobK9g2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"custom-select-wrap\"],[8],[1,[28,\"select-component\",null,[[\"options\",\"displayMember\",\"valueMember\",\"onChange\",\"value\",\"translate\"],[[24,[\"languageService\",\"all\"]],\"name\",\"code\",[28,\"action\",[[23,0,[]],\"setLanguage\"],null],[28,\"readonly\",[[24,[\"langCode\"]]],null],false]]],false],[7,\"span\",true],[10,\"class\",\"caret\"],[8],[9],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/locale-component.hbs"
    }
  });
});