define("bet-ember/templates/components/utils/option-list-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rpK4MMmx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"dropdown skin-clean\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"main-action combined \",[22,\"cssClass\"]]]],[10,\"id\",\"more-context\"],[10,\"data-toggle\",\"dropdown\"],[10,\"aria-expanded\",\"false\"],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[\"ficon \",[28,\"or\",[[24,[\"ficon\"]],\"ficon-more-vertical\"],null]]]],[8],[9],[0,\"\\n\\t\"],[4,\"unless\",[[24,[\"noLabel\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"action-text\"],[8],[1,[28,\"or\",[[24,[\"label\"]],[28,\"t\",[\"options\"],null]],null],false],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n   \"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu dropdown-menu-right\"],[10,\"aria-labelledby\",\"more-context\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n   \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/option-list-component.hbs"
    }
  });
});