define("bet-ember/templates/components/auth/login-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Qdnw1V0W",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"form\",true],[10,\"class\",\"form-signin login-form\"],[10,\"role\",\"form\"],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],\"login\"],null]],[8],[0,\"\\n\\t\"],[7,\"section\",true],[10,\"class\",\"login-main-content mt-50\"],[8],[0,\"\\n\\t\\t\"],[7,\"main\",true],[8],[0,\"\\n\\t\\t\\t\"],[2,\" {{form-group-component type='text' required=true autofocus=true value=email label=(t 'email')}} \"],[0,\"\\n\\n\\t\\t\\t\"],[1,[28,\"form-group-component\",null,[[\"type\",\"required\",\"value\",\"label\"],[\"password\",true,[24,[\"password\"]],[28,\"t\",[\"password\"],null]]]],false],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"mb-10 f-box\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"box-item\"],[8],[1,[28,\"async-btn-component\",null,[[\"action\",\"cssClass\",\"text\",\"translate\",\"iconClass\"],[[28,\"action\",[[23,0,[]],\"login\"],null],\"btn btn-primary mr-20\",\"loginbtn\",true,\"glyphicon glyphicon-log-in\"]]],false],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/auth/login-component.hbs"
    }
  });
});