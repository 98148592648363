define("bet-ember/components/sort-by-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      toggleSortBy() {
        if (this.get('sortBy') != this.get('sortByParam') || this.get('orderBy') == "ASC") {
          this.set('orderBy', "DESC");
        } else {
          this.set('orderBy', "ASC");
        }
        this.set('sortBy', this.get('sortByParam'));
        //
        // var sortBy = this.get('sortByParam');
        // var currSortBy = this.get('sortBy');
        // var newSortBy;
        // if(currSortBy && currSortBy.indexOf(sortBy)>=0){
        //   if(currSortBy.indexOf('ASC')>=0){
        //     newSortBy = sortBy + " DESC";
        //   }else{
        //     newSortBy = sortBy + " ASC";
        //   }
        // }else{
        //   newSortBy = sortBy + " ASC";
        // }
        //
        // this.set('sortBy',newSortBy);
      }
    }
  });
});