define("bet-ember/templates/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ie8Sncqw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"text-align:center;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alert alert-warning\"],[10,\"role\",\"alert\"],[8],[1,[28,\"t\",[\"notThePageLookingFor\"],null],false],[0,\" \"],[1,[28,\"t\",[\"go\"],null],false],[0,\" \"],[4,\"link-to\",null,[[\"route\"],[\"home\"]],{\"statements\":[[1,[28,\"t\",[\"HOME\"],null],false]],\"parameters\":[]},null],[0,\"!!!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/page-not-found.hbs"
    }
  });
});