define("bet-ember/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;
  function isEqual([lhs, rhs]) {
    return lhs == rhs;
  }
  var _default = _exports.default = Ember.Helper.helper(isEqual);
});