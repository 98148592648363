define("bet-ember/templates/components/utils/dot-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "31gP1JTW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"loader-dots text-center \",[22,\"cssClass\"]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/dot-loader.hbs"
    }
  });
});