define("bet-ember/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
      this.transitionTo('login');
    }
  });
});