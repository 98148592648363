define("bet-ember/helpers/find-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findKey = findKey;
  function findKey([object, key]) {
    for (let prop in object) {
      if (prop.indexOf(key) === 0) {
        return prop;
      }
    }
    return '';
  }
  var _default = _exports.default = Ember.Helper.helper(findKey);
});