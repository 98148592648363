define("bet-ember/mixins/filter-mixin", ["exports", "bet-ember/mixins/remember-scroll-possition"], function (_exports, _rememberScrollPossition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var filters = {};
  var _default = _exports.default = Ember.Mixin.create(_rememberScrollPossition.default, {
    storage: Ember.inject.service('storage'),
    //filterFields - list of filter fields
    //modelName
    //endPoint
    addToFilter: {},
    //add to filter
    disableFilterInit: false,
    extractFilterOnInit: true,
    makeRequestOnInit: true,
    saveFiltersOnSearch: true,
    autoSearch: true,
    infinityScroll: true,
    pageNumber: 1,
    pageSize: 10,
    resetItems: "preRequest",
    attachScrollToDoc: true,
    initReady: false,
    preventSearchOnFilterFieldsChange: [],
    setFilterOnFielsChange: [],
    // cacheItems:false,

    filterParamsToStore: Ember.computed('filterFields', function () {
      var filterFields = this.get('filterFields') || [];
      return filterFields.map(item => {
        if (typeof item == "string") {
          return item;
        } else {
          return item.field;
        }
      });
    }),
    filterComponentId: Ember.computed(function () {
      return "custom-filter-id-" + Ember.uuid();
    }),
    onCompInit: Ember.on('init', Ember.observer('filterFields.length', function () {
      this.set('initReady', false);
      if (this.get('filterFields') == null) {
        return;
      }
      if (this.get('disableFilterInit')) {
        return;
      }
      var filter = {};
      this.set('requestUUID', Ember.uuid());
      this.set('filterId', this.get('customFilterId') || location.pathname);
      this.set('scrollSessionStateId', this.get('filterId') + "-scroll-state");
      if (this.get('items')) {
        this.set('items', []);
      }
      if (this.get('initalSetup')) {
        this.get('initalSetup').call(this);
      }
      this.setupDefaultFilter();
      if (this.get('extractFilterOnInit')) {
        this.extractFilter();
      }
      filter = this.generateFilter();
      this.set('filterActive', JSON.stringify(this.generateFilter(true)) != JSON.stringify(filter));
      var stateIsExtracted = false;
      if (this.get('makeRequestOnInit')) {
        stateIsExtracted = this.extractState();
        if (!stateIsExtracted) {
          scrollToTop();
          this.set('requestLoading', true);
          this.fieldChanged();
        }
      }
      if (this.get('autoSearch')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.addObserver(this, 'filterChangedFlag', this, this.search);
        });
      }
      var filterFields = this.get('filterFields') || [];
      filterFields.forEach(item => {
        if (typeof item == "string") {
          if (this.get('preventSearchOnFilterFieldsChange').indexOf(item) < 0) {
            Ember.addObserver(this, item, this, () => {
              this.fieldChanged();
            });
          }
        } else {
          if (this.get('preventSearchOnFilterFieldsChange').indexOf(item.field) < 0) {
            Ember.addObserver(this, item.field, this, () => {
              this.fieldChanged();
            });
          }
        }
      });
      (this.get('setFilterOnFieldsChange') || []).toArray().forEach((item, i) => {
        Ember.addObserver(this, item, this, this.setFilterWithoutRequest);
      });
      this.initScroll();
      this.set('initReady', true);
      this.initRememberScrollPossition();
    })),
    setFilterWithoutRequest() {
      if (this.get('initReady')) {
        this.setFilter(this.generateFilter());
      }
    },
    willDestroy: function () {
      try {
        document.removeEventListener('scroll', this.get('onScroll'));
      } catch (err) {
        // logger(err);
      }
      try {
        Ember.removeObserver(this, 'filterChangedFlag', this, this.search);
      } catch (err) {
        // logger(err);
      }
      (this.get('filterFields') || []).forEach(item => {
        try {
          if (typeof item == "string") {
            Ember.removeObserver(this, item, this, this.fieldChanged);
          } else {
            Ember.removeObserver(this, item.field, this, this.fieldChanged);
          }
        } catch (err) {
          // logger(err);
        }
      });
      (this.get('setFilterOnFielsChange') || []).toArray().forEach((item, i) => {
        try {
          Ember.removeObserver(this, item, this, this.setFilterWithoutRequest);
        } catch (err) {
          // logger(err);
        }
      });
      this._super();
    },
    initScroll() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var lastScrollTop = 0;
        var onScroll = () => {
          if (this.get('infinityScroll')) {
            this.autoLoadNext();
          }
          var st = window.pageYOffset || document.documentElement.scrollTop;
          if (st == 0) {
            $('html').removeClass('scrolled-up');
            $('html').removeClass('scrolled-down');
            // $('html').removeClass('open-filter');
            $('.filter-btn-component').removeClass('pressed');
            this.get('utils').set('appTopHeaderHidden', false);
          } else if (st > lastScrollTop) {
            // downscroll code
            $('html').removeClass('scrolled-up');
            $('html').addClass('scrolled-down');
            this.get('utils').set('appTopHeaderHidden', true);
          } else {
            // upscroll code
            $('html').addClass('scrolled-up');
            $('html').removeClass('scrolled-down');
            this.get('utils').set('appTopHeaderHidden', false);
          }
          lastScrollTop = st <= 0 ? 0 : st;
        };
        onScroll = onScroll.bind(this);
        this.set('onScroll', onScroll);
        var scrollTarget = document;
        if (!this.get('attachScrollToDoc')) {
          scrollTarget = $('.filter-scroll-target.' + this.get('filterComponentId'))[0];
        }
        if (scrollTarget) {
          scrollTarget.addEventListener('scroll', onScroll);
          // logger('infinity scroll attached');
        } else {
          // logger('no element found to attach infinity scroll');
        }
      });
    },
    autoLoadNext() {
      if (this.get('stopAutoLoadNext')) {
        return;
      }
      if ($('.' + this.get('filterComponentId') + '.infinity-scroll-item-' + (this.get('items.length') - 1)).isOnScreen() && this.get('meta.itemCount') > this.get('items.length')) {
        this.set('requestLoading', true);
        this.loadNext(this.get('pageNumber') + 1, this.get('pageSize'));
      }
    },
    search() {
      this.set('requestLoading', true);
      var filter = this.generateFilter();
      if (this.get('saveFiltersOnSearch')) {
        this.setFilter(filter);
      }
      if (this.get('pageNumber') == 1 && this.get('resetItems') == "preRequest") {
        this.set('items', []);
      } else if (this.get('pageNumber') == 1 && this.get('resetItems') == "postRequest") {
        this.set('resetItemsBeforSet', true);
      }
      this.set('filterActive', JSON.stringify(this.generateFilter(true)) != JSON.stringify(filter));
      if (this.get('preRequest')) {
        this.get('preRequest').call(this, filter);
      }
      if (this.get('preSearchCheck')) {
        var val = this.get('preSearchCheck').call(this, filter);
        if (val == false) {
          return;
        }
      }
      this.set('requestUUID', Ember.uuid());
      var requestUUID = this.get('requestUUID');
      return this.makeRequest(filter).then(payload => {
        payload = payload || {};
        if (requestUUID == this.get('requestUUID')) {
          if (this.get('resetItemsBeforSet')) {
            this.set('resetItemsBeforSet', false);
            this.set('items', []);
          }
          this.set('requestLoading', false);
          if (this.get('customMakeRequest')) {
            if (this.get('postCustomRequest')) {
              this.get('postCustomRequest').call(this, payload);
            }
          } else {
            var data = payload.data || [];
            var meta = getProperty(payload, 'meta');
            if (this.get('postRequestPreSet')) {
              data = this.get('postRequestPreSet').call(this, data, filter);
            }
            this.set('items', this.get('items').toArray().concat(data.toArray()));
            this.set('meta', meta || null);
            this.set('stopAutoLoadNext', false);
            if (this.get('postRequestPostSet')) {
              this.get('postRequestPostSet').call(this, data, filter);
            }
            payload = data;
            this.storeState();

            //autoLoadNext only if infinityScroll == true
            if (this.get('infinityScroll')) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                // this.initScroll();
                this.autoLoadNext();
              });
            }
          }
          return payload;
        }
      }).catch(err => {
        this.set('requestLoading', false);
        logger(err);
      });
    },
    makeRequest(filter) {
      if (this.get('modelName')) {
        return this.store.query(this.get('modelName'), {
          queryParams: filter,
          endPoint: this.get('endPoint')
        }).then(payload => {
          return {
            data: payload,
            meta: payload.get('meta').data
          };
        });
      } else {
        return this.get('customAjax').call({
          queryParams: filter,
          endPoint: this.get('endPoint')
        }).then(data => {
          var payload = this.customAjax.unpack(data);
          if (payload.meta && payload.meta.data) {
            payload.meta = payload.meta.data;
          }
          if (payload && payload.length) {
            var temp = [];
            payload.forEach((item, i) => {
              temp.push(Ember.Object.create(item));
            });
            if (payload.meta) {
              temp.meta = payload.meta;
            }
            payload = temp;
          }
          return payload;
        });
      }
    },
    storeState() {
      if (!this.get('modelName')) {
        return;
      }
      var state = {
        id: this.get('sessionStateId'),
        itemIds: this.get('items').map(p => p.get('id')),
        meta: this.get('meta'),
        modelName: this.get('modelName'),
        pageSize: this.get('pageSize'),
        pageNumber: this.get('pageNumber')
      };
      this.get('storage').setSessionState(state);
    },
    extractState() {
      if (!this.get('modelName')) {
        return false;
      }
      var state = this.get('storage').getSessionState(this.get('sessionStateId'));
      if (state && state.itemIds && state.itemIds.length && state.meta && state.pageSize && state.pageNumber) {
        var items = [];
        state.itemIds.forEach((id, i) => {
          items.push(this.store.peekRecord(this.get('modelName'), id));
        });
        items = items.filter(p => p);
        if (items.length == 0) {
          return false;
        }
        this.set('items', items);
        this.set('meta', state.meta);
        this.set('pageSize', state.pageSize);
        this.set('pageNumber', state.pageNumber);
        return true;
      } else {
        return false;
      }
    },
    fieldChanged(keepPageNumber) {
      if (keepPageNumber !== true) {
        this.set('pageNumber', 1);
      }
      var filterHander = this.get('filterHander');
      clearTimeout(filterHander);
      this.set('stopAutoLoadNext', true);
      filterHander = setTimeout(() => {
        try {
          this.set('filterChangedFlag', new Date().getTime());
        } catch (err) {}
      }, 550);
      this.set('filterHander', filterHander);
    },
    // sortByChanged: Ember.observer('sortBy', function() {
    //   if (this.get('disableSearchOnSortByChange') != true) {
    //     this.fieldChanged();
    //   }
    // }),
    // orderByChanged: Ember.observer('orderBy', function() {
    //   if (this.get('disableSearchOnOrderByChange') != true) {
    //     this.fieldChanged();
    //   }
    // }),

    // set values to filter containter
    setFilter(params, filterId, directSet) {
      var currentFilter = {};
      var filterParamsToStore = this.get('filterParamsToStore') || [];
      filterId = filterId || this.get('filterId');
      for (var key in params) {
        if (key && params[key] !== undefined && (filterParamsToStore.indexOf(key) !== -1 || directSet)) {
          currentFilter[key] = params[key];
        }
      }
      var addToFilter = this.get('addToFilter') || {};
      for (var key in addToFilter) {
        currentFilter[key] = addToFilter[key];
      }
      filters[filterId] = currentFilter;
      this.get('storage').setItem("filter->" + filterId, JSON.stringify(currentFilter));
    },
    setupDefaultFilter() {
      var fields = this.get('filterFields') || [];
      fields.forEach(field => {
        if (field && field.field && field.default != null) {
          this.set(field.field, field.default);
        }
      });
    },
    // extract values from filter containter and set fields
    extractFilter(filterId) {
      filterId = filterId || this.get('filterId');
      var currentFilter = this.getFilter(filterId);
      if (this && currentFilter) {
        for (var key in currentFilter) {
          if (currentFilter[key] && this.get('def' + key) == null) {
            this.set(key, currentFilter[key]);
          }
        }
      }
    },
    getFilter(filterId) {
      filterId = filterId || this.get('filterId');
      return filters[filterId] || TryParse(this.get('storage').getItem("filter->" + filterId)) || {};
    },
    clearFilter(filterId) {
      filters[filterId] = null;
      this.get('storage').setItem("filter->" + filterId, '');
    },
    clearAll() {
      filters = [];
      for (var key in localStorage) {
        if (key.indexOf('filter->') == 0) {
          this.get('storage').setItem(key, '');
        }
      }
    },
    // generate filter containter /queryParams values
    generateFilter(defaultFilter) {
      let filter = {
        pageNumber: this.get('pageNumber') || 1,
        pageSize: this.get('pageSize') || this.storage.getItem('preferredPageSize')
      };
      var addToFilter = this.get('addToFilter') || {};
      for (let key in addToFilter) {
        if (addToFilter.hasOwnProperty(key)) {
          filter[key] = addToFilter[key];
        }
      }
      var filterFields = this.get('filterFields') || [];
      if (defaultFilter) {
        filterFields.forEach(field => {
          if (field && field.field && field.default != null) {
            if (field.transform) {
              filter[field.field] = field.transform.call(this, copyObject(field.default));
            } else {
              filter[field.field] = copyObject(field.default);
            }
          }
        });
      } else {
        filterFields.forEach(item => {
          if (typeof item == "string") {
            if (this.get(item) != null && this.get(item) !== '') {
              filter[item] = this.get(item);
            }
          } else {
            if (this.get(item.field) != null && this.get(item.field) !== '') {
              if (item.transform) {
                filter[item.field] = item.transform.call(this, this.get(item.field));
              } else {
                filter[item.field] = this.get(item.field);
              }
            } else if (item.required && item.default) {
              if (item.transform) {
                filter[item.field] = item.transform.call(this, copyObject(item.default));
              } else {
                filter[item.field] = copyObject(item.default);
              }
            }
          }
        });
        if (this.get('sortBy')) {
          filter.sortBy = this.get('sortBy');
        }
        if (this.get('orderBy')) {
          filter.orderBy = this.get('orderBy');
        }
        try {
          if (filter.sortBy) {
            filter.sortBy = filter.sortBy.split(' ')[0];
          }
        } catch (err) {
          logger(err);
        }
      }
      if (this.get('refs')) {
        filter.refs = this.get('refs');
      }
      return filter;
    },
    loadNext(pageNumber, pageSize) {
      this.set('pageNumber', pageNumber);
      this.set('pageSize', pageSize);
      this.fieldChanged(true);
    },
    actions: {
      clearFilters() {
        return (this.get('filterFields') || []).map(item => {
          if (typeof item == "string") {
            this.set(item, null);
          } else if (item.default) {
            this.set(item.field, copyObject(item.default));
          } else {
            this.set(item.field, null);
          }
        });
        this.set('pageNumber', 1);
        this.clearFilter();
        this.onCompInit();
        this.search();
      },
      changePage(pageNumber, pageSize) {
        this.loadNext(pageNumber, pageSize);
      },
      search() {
        this.set('pageNumber', 1);
        return this.search();
      }
    }
  });
});