define("bet-ember/templates/components/custom-time-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "soo0xtLx",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"value\",\"id\",\"class\",\"readonly\",\"disabled\",\"required\",\"change\",\"placeholder\",\"autofocus\",\"keyUp\",\"min\",\"max\"],[[24,[\"value\"]],[24,[\"componentId\"]],[28,\"concat\",[\"form-control\",[24,[\"cssClass\"]]],null],\"true\",[24,[\"disabled\"]],[24,[\"required\"]],[24,[\"change\"]],[24,[\"placeholder\"]],[24,[\"autofocus\"]],[24,[\"onKeyUp\"]],[24,[\"startTime\"]],[24,[\"endTime\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/custom-time-component.hbs"
    }
  });
});