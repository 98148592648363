define("bet-ember/templates/components/sort-by-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2hFNeUx5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",false],[12,\"class\",\"link\"],[3,\"action\",[[23,0,[]],\"toggleSortBy\"]],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"and\",[[28,\"is-equal\",[[24,[\"sortBy\"]],[24,[\"sortByParam\"]]],null],[28,\"is-equal\",[[24,[\"orderBy\"]],\"ASC\"],null]],null],\"ficon ficon-arrow-up\"],null],\" \",[28,\"if\",[[28,\"and\",[[28,\"is-equal\",[[24,[\"sortBy\"]],[24,[\"sortByParam\"]]],null],[28,\"is-equal\",[[24,[\"orderBy\"]],\"DESC\"],null]],null],\"ficon ficon-arrow-down\"],null]]]],[8],[9],[0,\"\\n    \"],[1,[22,\"text\"],false],[0,\" \"],[1,[22,\"addText\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/sort-by-component.hbs"
    }
  });
});