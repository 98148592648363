define("bet-ember/templates/components/view-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    on
  } = Ember;
  var _default = _exports.default = Ember.CustomComponent.extend({
    onCompInit: on('init', function () {
      if (this.get('model.licensedTo')) {
        this.set('licensedTo', getDateOnly(this.get('model.licensedTo')));
      }
    }),
    actions: {
      save() {
        this.alert.clearAll();
        var formIsValid = this.formIsValid({
          selector: ".save-form"
        });
        if (!formIsValid) {
          return;
        }
        this.set('model.licensedTo', getDateOnly(this.get('licensedTo')));
        return this.get('model').save().then(data => {
          this.alert.show({
            message: this.intl.t('saved')
          });
          this.router.transitionTo('home.acc.users.view', data);
          return data;
        }).catch(err => {
          logger(err);
          this.alert.displayServerErrors(err);
        });
      },
      cancel() {
        this.router.transitionTo("home.acc.users");
      },
      delete() {
        this.get('model').destroyRecord().then(() => {
          this.alert.show({
            message: this.intl.t('deleted')
          });
          this.router.transitionTo('home.acc.users');
        }).catch(err => {
          this.get('model').rollback();
          this.alert.displayServerErrors(err);
        });
      }
    }
  });
});