define("bet-ember/routes/home/matrices/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.MainRoute.extend({
    renderTemplate: function (controller, model) {
      this.render('home.matrices.view', {
        model: model,
        into: 'home'
      });
    },
    model(params) {
      let matrix = this.storage.getItem('matrixToCopy') || null;
      this.storage.setItem('matrixToCopy', null);
      return this.store.createRecord('matrix', matrix);
    }
  });
});