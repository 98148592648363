define("bet-ember/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    customAjax: Ember.inject.service('custom-ajax'),
    restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate(password) {
      return this.get("customAjax").call({
        endPoint: "auth/login",
        type: "POST",
        data: JSON.stringify({
          password: password
        })
      });
    },
    invalidate(data) {
      return this.get("customAjax").call({
        endPoint: "auth/logout",
        type: "POST"
      }).catch(err => {
        logger(err);
      }).then(() => {
        return Ember.RSVP.resolve();
      });
    }
  });
});