define("bet-ember/templates/components/utils/main-loading-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ousQmWyZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); \"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center valign\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/assets/images/app-logo.png\"],[8],[9],[0,\"\\n    \"],[1,[28,\"utils/dot-loader\",null,[[\"cssClass\"],[\"highlight\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/main-loading-component.hbs"
    }
  });
});