define("bet-ember/templates/components/tabs-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6cFhTpH7",
    "block": "{\"symbols\":[\"tabName\",\"index\",\"&default\"],\"statements\":[[7,\"ul\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"ulClass\"]],[24,[\"ulClass\"]],\"nav nav-tabs\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"tabNamesSplited\"]]],null,{\"statements\":[[0,\"     \"],[7,\"li\",false],[12,\"class\",[29,[[28,\"if\",[[24,[\"liClass\"]],[24,[\"liClass\"]],\"nav-tab-name\"],null],\" \",[23,1,[\"original\"]],\" \",[28,\"if\",[[28,\"is-equal\",[[24,[\"selectedTab\"]],[23,1,[\"original\"]]],null],\"active\"],null]]]],[3,\"action\",[[23,0,[]],\"toggleTab\",[23,1,[\"original\"]]]],[8],[7,\"a\",true],[8],[1,[22,\"apendToName\"],false],[1,[23,1,[\"translated\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[4,\"if\",[[24,[\"addTabLabel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",false],[12,\"class\",[29,[[28,\"if\",[[24,[\"liClass\"]],[24,[\"liClass\"]],\"nav-tab-name\"],null]]]],[3,\"action\",[[23,0,[]],[24,[\"addTabAction\"]]]],[8],[7,\"a\",true],[8],[1,[22,\"addTabLabel\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/tabs-component.hbs"
    }
  });
});