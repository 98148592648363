define("bet-ember/helpers/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.trim = trim;
  function trim(params) {
    var str = params[0];
    var strToTrim = params[1];
    var re = new RegExp(strToTrim, 'g');
    return str.replace(re, '');
  }
  var _default = _exports.default = Ember.Helper.helper(trim);
});