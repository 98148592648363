define("bet-ember/templates/components/matrices/search-item-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NzlX1qkF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel panel-light-2 m-10\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"panel-heading f-box vcbox link\"],[3,\"action\",[[23,0,[]],\"select\",[24,[\"model\"]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"box-item\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"panel-title txt-medium\"],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/matrices/search-item-component.hbs"
    }
  });
});