define("bet-ember/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GG8mXL7X",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"auth/login-component\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/login.hbs"
    }
  });
});