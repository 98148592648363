define("bet-ember/helpers/new-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.newDate = newDate;
  function newDate([date]) {
    return new Date(date);
  }
  var _default = _exports.default = Ember.Helper.helper(newDate);
});