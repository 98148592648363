define("bet-ember/initializers/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    var constants = Ember.Object.extend({
      //Numbers
      MAX_INT: Math.pow(2, 31) - 1,
      DAY_IN_SEC: 60 * 60 * 24,
      //Strings
      SUCCESS: "SUCCESS",
      FAILURE: "FAILURE",
      HTTP_METHOD_POST: "POST",
      HTTP_METHOD_PUT: "PUT",
      HTTP_METHOD_GET: "GET",
      HTTP_METHOD_DELETE: "DELETE",
      //View states
      MODE: "MODE",
      VIEW: "VIEW",
      CREATE: "CREATE",
      EDIT: "EDIT",
      DATE_TIME_FORMAT: "YYYY-MM-DD HH:MM:SS",
      START_OBJECT: "{",
      END_OBJECT: "}",
      COMMA: ",",
      COLON: ":",
      QUOTES: "\"",
      ZERO_STRING: "0",
      //DateTime format
      DAY_IN_MSEC: 24 * 60 * 60 * 1000,
      HOUR_IN_MSEC: 60 * 60 * 1000,
      MIN_IN_MSEC: 60 * 1000,
      DATE_FORMAT: "DD.MM.YYYY",
      FROM_DATE: "fromDate"
    });
    app.register('constants:main', constants);
    app.inject('controller', 'constants', 'constants:main');
    app.inject('component', 'constants', 'constants:main');
    app.inject('route', 'constants', 'constants:main');
    app.inject('template', 'constants', 'constants:main');
    app.inject('service', 'constants', 'constants:main');
    app.inject('mixin', 'constants', 'constants:main');
  }
  var _default = _exports.default = {
    name: 'constants',
    after: 'ember-data',
    initialize
  };
});