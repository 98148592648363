define("bet-ember/mixins/pagniantion-filter-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var filters = {};
  var _default = _exports.default = Ember.Mixin.create({
    storage: Ember.inject.service('storage'),
    extractFilterOnInit: true,
    makeRequestOnInit: true,
    saveFiltersOnSearch: true,
    autoSearch: true,
    filterParamsToStore: Ember.computed('filterFields', function () {
      var filterFields = this.get('filterFields') || [];
      return filterFields.map(item => {
        if (typeof item == "string") {
          return item;
        } else {
          return item.field;
        }
      });
    }),
    onCompInit: Ember.on('init', function () {
      var filter = {};
      this.set('filterUUID', location.pathname);
      if (this.get('extractFilterOnInit')) {
        this.extractFilter();
      }
      if (this.get('makeRequestOnInit') && this.makeRequest) {
        this.fieldChanged();
      }
      if (this.get('autoSearch')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          Ember.addObserver(this, 'filterChangedFlag', this, this.search);
        });
      }
    }),
    search() {
      var filter = this.generateFilter();
      if (this.get('saveFiltersOnSearch')) {
        this.setFilter(filter);
      }
      if (this.preRequest) {
        this.preRequest(filter);
      }
      if (this.makeRequest) {
        return this.makeRequest(filter);
      }
      return null;
    },
    onFilterMinixInit: Ember.on('init', function () {
      var filterFields = this.get('filterFields') || [];
      filterFields.forEach(item => {
        if (typeof item == "string") {
          Ember.addObserver(this, item, this, this.fieldChanged);
        } else {
          if (item.default) {
            if (typeof item.default === "function") {
              this.set(item.field, item.default());
            } else {
              this.set(item.field, item.default);
            }
          }
          Ember.addObserver(this, item.field, this, this.fieldChanged);
        }
      });
    }),
    willDestroy: function () {
      this._super();
      try {
        Ember.addObserver(this, 'filterChangedFlag', this, this.search);
      } catch (err) {
        logger(err);
      }
      (this.get('filterFields') || []).forEach(item => {
        try {
          if (typeof item == "string") {
            Ember.removeObserver(this, item, this, this.fieldChanged);
          } else {
            Ember.removeObserver(this, item.field, this, this.fieldChanged);
          }
        } catch (err) {
          logger(err);
        }
      });
    },
    fieldChanged(keepPageNumber) {
      if (keepPageNumber != true) {
        this.set('pageNumber', 1);
      }
      var filterHander = this.get('filterHander');
      clearTimeout(filterHander);
      filterHander = setTimeout(() => {
        this.set('filterChangedFlag', new Date().getTime());
      }, 550);
      this.set('filterHander', filterHander);
    },
    sortByChanged: Ember.observer('sortBy', function () {
      this.fieldChanged();
    }),
    getFilter: function (filterId) {
      var currentFilter = {};
      var filterId = filterId || this.get('filterId') || this.get('filterUUID');
      return filters[filterId];
    },
    setFilter: function (params, filterId) {
      var currentFilter = {};
      var filterParamsToStore = this.get('filterParamsToStore') || [];
      filterId = filterId || this.get('filterId') || this.get('filterUUID');
      for (var key in params) {
        if (key && params[key] !== undefined && filterParamsToStore.indexOf(key) !== -1) {
          currentFilter[key] = params[key];
        }
      }
      filters[filterId] = currentFilter;
    },
    extractFilter: function () {
      var filterId = this.get('filterId') || this.get('filterUUID');
      var currentFilter = filters[filterId];
      if (this && currentFilter) {
        for (var key in currentFilter) {
          if (currentFilter[key] && this.get('def' + key) == null) {
            if (key.toLowerCase().indexOf('date') >= 0) {
              this.set(key, new Date(currentFilter[key]));
            } else {
              this.set(key, currentFilter[key]);
            }
          }
        }
      }
    },
    generateFilter() {
      let filter = {
        pageNumber: this.get('pageNumber') || 1,
        pageSize: this.get('pageSize') || this.get('storage').getItem('preferredPageSize')
      };
      var filterFields = this.get('filterFields') || [];
      filterFields.forEach(item => {
        if (typeof item == "string") {
          if (this.get(item)) {
            filter[item] = this.get(item);
          }
        } else {
          if (this.get(item.field)) {
            if (item.transform) {
              filter[item.field] = item.transform(this.get(item.field));
            } else {
              filter[item.field] = this.get(item.field);
            }
          }
        }
      });
      if (this.get('sortBy')) {
        filter.sortBy = this.get('sortBy').split(' ')[0];
        filter.orderBy = this.get('sortBy').split(' ')[1];
      }
      if (this.get('refs')) {
        filter.refs = this.get('refs');
      }
      if (this.get('attrList')) {
        filter.attrList = this.get('attrList');
      }
      return filter;
    },
    clearAll() {
      filters = {};
    },
    actions: {
      clearFilters() {
        return (this.get('filterFields') || []).map(item => {
          if (typeof item == "string") {
            this.set(item, null);
          } else if (item.default) {
            if (typeof item.default === "function") {
              this.set(item.field, item.default());
            } else {
              this.set(item.field, item.default);
            }
          } else {
            this.set(item.field, null);
          }
        });
        this.fieldChanged();
      },
      changePage(pageNumber, pageSize) {
        this.set('pageNumber', pageNumber);
        this.set('pageSize', pageSize);
        this.fieldChanged(true);
      },
      search() {
        return this.search();
      }
    }
  });
});