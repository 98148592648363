define("bet-ember/services/setting", ["exports", "bet-ember/mixins/generic-service-mixin"], function (_exports, _genericServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(_genericServiceMixin.default, {
    store: Ember.inject.service('store'),
    alert: Ember.inject.service('alert'),
    modelName: "setting",
    endPoint: "settings",
    useChecksum: false,
    versionIsValid(version) {
      var version = version || '';
      version = version.split('.');
      return version.length == 4;
    },
    getSetting(name) {
      var setting = this.getAll().find(item => item.get('name') == name);
      if (setting != null) {
        return setting.get('value');
      }
      return null;
    },
    setSetting(name, value) {
      var setting = this.getAll().find(item => item.get('name') == name);
      if (setting == null) {
        setting = this.get('store').createRecord('setting', {
          name: name
        });
      }
      setting.set('value', value);
      return setting.save().catch(err => {
        this.get('alert').displayServerErrors(err);
      }).then(setting => {
        this.refreshComputed();
      });
    }
  });
});