define("bet-ember/components/matrices/search-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.FilterCustomComponent.extend({
    filterFields: [
      //   {
      //     field:"fromDate",
      //     default:getDateOnly(),
      //     transform:(val)=>{ return getDateOnly(val).getTime(); }
      //   },
      //   {
      //     field:"thruDate",
      //     transform:(val)=>{ return getDateOnly(val).getTime(); }
      //   },
      //   // "bookingStatusId","profileSearchText","profileTypeId"
    ],
    modelName: 'matrix',
    endPoint: 'matrices',
    actions: {
      create() {
        this.router.transitionTo('home.matrices.create');
      }
    }
  });
});