define("bet-ember/helpers/selected-ml-uom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.selectedMlUom = selectedMlUom;
  function selectedMlUom([aux]) {
    return this.get('mlToUomConverter').getSelected(aux);
  }
  var _default = _exports.default = Ember.Helper.extend({
    mlToUomConverter: Ember.inject.service('ml-to-uom-converter'),
    compute: selectedMlUom,
    onSelectedMlUomChange: Ember.observer('mlToUomConverter.selected', function () {
      this.recompute();
    })
  });
});