define("bet-ember/helpers/days-between", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.daysBetween = daysBetween;
  _exports.default = void 0;
  function daysBetween([val1, val2]) {
    return window.getDaysBetween(val1, val2);
  }
  var _default = _exports.default = Ember.Helper.helper(daysBetween);
});