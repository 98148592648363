define("bet-ember/components/simple-file-select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Component,
    computed,
    observer,
    run: {
      bind
    },
    String: {
      htmlSafe
    },
    assert,
    $
  } = Ember;
  var _default = _exports.default = Component.extend({
    classNames: ['file-picker single'],
    accept: '*',
    count: 0,
    errors: [],
    readFile: function (file) {
      var _this = this;
      const reader = new FileReader();
      this.set('showProgresBar', true);
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (file == null) {
          resolve(null);
          return;
        }
        reader.onload = function (processedFile) {
          _this.set('showProgresBar', false);
          resolve({
            filename: file.name,
            type: file.type,
            data: processedFile.target.result,
            size: file.size,
            original: file
          });
        };
        reader.onabort = function () {
          _this.set('showProgresBar', false);
          reject({
            event: 'onabort'
          });
        };
        reader.onerror = function (error) {
          _this.set('showProgresBar', false);
          reject({
            event: 'onerror',
            error: error
          });
        };
        reader.onprogress = event => {
          _this.set('progress', event.loaded / event.total * 100);
        };
        reader.readAsDataURL(file);
      });
    },
    didInsertElement: function () {
      $(this.element).find('.file-uploader-input').on('change', bind(this, 'filesSelected'));
    },
    willDestroyElement: function () {
      $(this.element).find('.file-uploader-input').off('change', bind(this, 'filesSelected'));
    },
    filesSelected: function (event) {
      var files = event.target.files;
      if (files && files[0]) {
        this.set('selectedFile', files[0]);
        this.readFile(files[0]).then(file => {
          if (this.get('fileSelected')) {
            this.get('fileSelected')(file);
          }
        }).catch(err => {
          logger(err);
        });
      } else {
        this.set('selectedFile', null);
      }
    },
    actions: {
      onClick: function () {
        $(this.element).find('.file-uploader-input').trigger('click');
      },
      remove: function () {
        this.set('selectedFile', null);
        if (this.get('fileSelected')) {
          this.get('fileSelected')(null);
        }
      }
    }
  });
});