define("bet-ember/templates/components/progress-bar-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YeSDpeyI",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"progress-bar \",[28,\"if\",[[28,\"more-than\",[[24,[\"progress\"]],[28,\"or\",[[24,[\"green\"]],60],null]],null],\"progress-bar-success\",\"progress-bar-warning\"],null],\"\\n      \",[28,\"if\",[[28,\"less-than\",[[24,[\"progress\"]],[28,\"or\",[[24,[\"red\"]],30],null]],null],\"progress-bar-danger\"],null]]]],[11,\"style\",[29,[\"width: \",[22,\"progress\"],\"%;\"]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"percent-indicator primary\"],[8],[1,[22,\"preText\"],false],[0,\" \"],[1,[22,\"progress\"],false],[0,\"% \"],[1,[22,\"postText\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"percent-indicator secondary\"],[8],[1,[22,\"preText\"],false],[0,\" \"],[1,[22,\"progress\"],false],[0,\"% \"],[1,[22,\"postText\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/progress-bar-component.hbs"
    }
  });
});