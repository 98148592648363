define("bet-ember/helpers/cmp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cmp = cmp;
  _exports.default = void 0;
  function cmp([val1, val2, op]) {
    if (op == "EQ" || op == null) {
      return val1 == val2;
    } else if (op == "GT") {
      return val1 > val2;
    } else if (op == "LT") {
      return val1 < val2;
    } else if (op == "GTE") {
      return val1 >= val2;
    } else if (op == "LTE") {
      return val1 <= val2;
    }
    return false;
  }
  var _default = _exports.default = Ember.Helper.helper(cmp);
});