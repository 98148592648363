define("bet-ember/components/bets/search-item-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    keys: ['betId', 'marketId', 'selectionId', 'runnerName', 'status', 'orderStatus', 'betSide', 'betPrice', 'betSize', 'averagePriceMatched', 'sizeMatched', 'runnersCount', 'runnersToBePlacedCount'],
    actions: {
      checkBet() {
        return this.customAjax.call({
          type: "POST",
          endPoint: "bet/checkBet?betId=" + this.get('model.betId')
        }).then(payload => {
          logger(payload);
        }).catch(err => {
          logger(err);
        });
      }
    }
  });
});