define("bet-ember/components/direct-file-select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['file-picker single'],
    accept: '*',
    count: 0,
    errors: [],
    onSelectFile: Ember.observer('doSelectFile', function () {
      $(this.element).find('.file-uploader-input').trigger('click');
    }),
    readFile(file) {
      const reader = new FileReader();
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (file == null) {
          resolve(null);
          return;
        }
        reader.onload = processedFile => {
          resolve({
            filename: file.name,
            type: file.type,
            data: processedFile.target.result,
            size: file.size,
            original: file
          });
        };
        reader.onabort = () => {
          reject({
            event: 'onabort'
          });
        };
        reader.onerror = error => {
          reject({
            event: 'onerror',
            error: error
          });
        };
        reader.readAsDataURL(file);
      });
    },
    didInsertElement: function () {
      $(this.element).find('.file-uploader-input').on('change', Ember.run.bind(this, 'filesSelected'));
    },
    willDestroyElement: function () {
      $(this.element).find('.file-uploader-input').off('change', Ember.run.bind(this, 'filesSelected'));
    },
    filesSelected: function (event) {
      var files = event.target.files;
      if (files && files[0]) {
        this.readFile(files[0]).then(file => {
          if (this.get('fileSelected')) {
            this.get('fileSelected')(file);
          }
        }).catch(err => {
          logger(err);
        });
      }
      this.set('file', null);
    }
  });
});