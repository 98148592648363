define("bet-ember/components/main-layout-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    initComp: Ember.on("init", function () {
      $(window).on('resize', () => {
        this.computeHeaderHeights();
      });
    }),
    onRerender: Ember.on('didRender', function () {
      this.computeHeaderHeights();
    }),
    computeHeaderHeights() {
      this.get('utils').set('appTopHeaderHeight', $('.main-layout-app-top-header').outerHeight());
      this.get('utils').set('appInfoHeaderHeight', $('.main-layout-app-info-header').outerHeight());
    },
    willDestroyElement() {
      this._super(...arguments);
      $(window).off("resize");
    },
    actions: {
      test(endPoint, method) {
        this.customAjax.call({
          endPoint: endPoint,
          type: method || "POST"
        });
      },
      goBack() {
        window.history.back();
      }
    }
  });
});