define("bet-ember/helpers/more-than", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.moreThan = moreThan;
  function moreThan([val1, val2]) {
    if (val1 == null || val2 == null) {
      return false;
    }
    val1 = Ember.isArray(val1) ? val1.get("length") : val1;
    return val1 > val2;
  }
  var _default = _exports.default = Ember.Helper.helper(moreThan);
});