define("bet-ember/routes/home/events/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.MainRoute.extend({
    renderTemplate: function (controller, model) {
      this.render('home.events.view', {
        model: model,
        into: 'home'
      });
    },
    model(params) {
      if (params && params._id) {
        return this.store.query("event", {
          endPoint: "events",
          queryParams: {
            eventIds: params._id
          }
        }).then(events => {
          return events.toArray()[0];
        });
      } else {
        this.transitionTo('home.events');
        return null;
      }
    }
  });
});