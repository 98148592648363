define("bet-ember/components/async-btn-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNameBindings: ['forceInline:force-inline'],
    forceInline: true,
    status: "READY",
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.get('tag') !== undefined) {
        this.set('tagName', this.get('tag'));
      } else if (this.get('type') === 'span') {
        this.set('tagName', 'span');
      }
    },
    loadingObserver: Ember.observer('status', function (type) {
      var status = this.get('status');
      var type = 'button';
      if (this.get('type')) {
        type = this.get('type');
      }
      if (status == 'LOADING') {
        this.loader.buttonEnable($(this.element).find(type)[0]);
      } else {
        this.loader.buttonDisable($(this.element).find(type)[0]);
      }
    }),
    actions: {
      click(param) {
        if (param && $(this.element).find('.targetBtn').attr('disabled') == null) {
          var type = 'button';
          if (this.get('type')) {
            type = this.get('type');
          }
          (function (type) {
            var _this = this;
            this.set('status', "LOADING");
            try {
              Promise.resolve(param()).then(function () {
                if ($(_this.element).find(type) && $(_this.element).find(type)[0]) {
                  _this.set('status', "READY");
                }
              });
            } catch (ex) {
              console.log(ex);
              if ($(_this.element).find(type)[0]) {
                _this.set('status', "READY");
              }
            }
          }).call(this, type);
        }
      }
    }
  });
});