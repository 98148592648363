define("bet-ember/components/events/search-item-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    actions: {
      selectMarket() {
        this.router.transitionTo('home.events.view', this.get('model.id'));
      },
      toggleMarket() {
        if (this.get("model.markets.length")) {
          this.set("model.markets", null);
        } else {
          this.set('marketLoading', true);
          this.store.query('market', {
            endPoint: "marketCatalogues",
            queryParams: {
              eventIds: this.get('model.id')
            }
          }).then(marketCatalogues => {
            marketCatalogues = (marketCatalogues || []).toArray();
            this.set("model.markets", marketCatalogues);
            this.set('marketLoading', false);
          }).catch(err => {
            this.set('marketLoading', false);
            logger(err);
          });
        }
      },
      selectMarketInfo(market) {
        if (market.get('selected')) {
          market.set('selected', false);
        } else {
          // this.get("model.markets").forEach((item, i) => {
          //   item.set('selected',false);
          // });
          market.set('selected', true);
          this.store.query('market', {
            endPoint: "marketBooks",
            queryParams: {
              marketIds: market.get('marketId')
            }
          }).catch(err => {
            logger(err);
          });
        }
      }
    }
  });
});