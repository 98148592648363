define("bet-ember/helpers/sec-to-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.secToTime = secToTime;
  function secToTime([sec, reset]) {
    var date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setMilliseconds(0);
    date.setSeconds(sec);
    return date.toString().substr(16, 8);
  }
  var _default = _exports.default = Ember.Helper.helper(secToTime);
});