define("bet-ember/helpers/display-op", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayOp = displayOp;
  function displayOp([op]) {
    switch (op) {
      case "GT":
        {
          return ">";
        }
      case "GTE":
        {
          return ">=";
        }
      case "LT":
        {
          return "<";
        }
      case "LTE":
        {
          return "<=";
        }
      case "EQ":
        {
          return "=";
        }
      case "NEQ":
        {
          return "!=";
        }
    }
    return op;
  }
  var _default = _exports.default = Ember.Helper.helper(displayOp);
});