define("bet-ember/components/matrices/edit-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    onCompInit: Ember.on('init', Ember.observer('model', function () {
      this.set('name', this.get('model.name'));
      this.set('rules', this.get('model.rules'));
      this.set('transitioning', false);
      this.set('time', new Date().getTime());
      this.set('editedRow', null);
    })),
    actions: {
      removeRule(rule) {
        this.get('rules').removeObject(rule);
      },
      addRule() {
        this.get('rules').pushObject(Ember.Object.create({
          targetA: Ember.Object.create({
            operation: "EQ"
          }),
          targetB: Ember.Object.create({
            operation: "EQ"
          })
        }));
      },
      copy() {
        this.set('transitioning', true);
        setTimeout(() => {
          let model = this.store.createRecord('matrix');
          model.set('content', copyObject(this.get('model.content') || []));
          this.storage.setItem('matrixToCopy', JSON.stringify(model.serialize()));
          this.router.transitionTo("home.matrices.create");
        }, 300);
      },
      save() {
        this.alert.clearAll();
        var formIsValid = this.formIsValid({
          selector: ".save-form"
        });
        if (!formIsValid) {
          return;
        }
        this.set('model.name', this.get('name'));
        this.set('model.content', JSON.stringify(this.get('rules')));
        return this.get('model').save().then(data => {
          this.store.removeAllNew();
          this.matrixService.refreshComputed();
          this.alert.show({
            message: this.intl.t('saved')
          });
          this.onCompInit();
          this.router.transitionTo("home.matrices.view", this.get('model'));
          return data;
        }).catch(err => {
          logger(err);
          this.alert.displayServerErrors(err);
          try {
            if (this.get('model.id')) {
              this.get('model').reload();
            }
          } catch (err) {
            logger(err);
          }
        });
      },
      showSetValueModal(runner, type) {
        this.set('value', true);
        this.set('showModal', true);
        this.set('updateValuesObj', {
          runner: runner,
          type: type
        });
      },
      closeModal() {
        this.set('showModal', false);
      },
      saveValue(runner, type) {
        (this.get('rules') || []).forEach((rule, i) => {
          rule.set('target' + runner + '.' + type, this.get('value'));
        });
        this.set('showModal', false);
      },
      cancel() {
        if (this.get('model.id')) {
          this.router.transitionTo("home.matrices.view");
        } else {
          this.router.transitionTo("home.matrices");
        }
      },
      delete() {
        this.get('model').destroyRecord().then(() => {
          this.matrixService.refreshComputed();
          this.alert.show({
            message: this.intl.t('deleted')
          });
          this.router.replaceWith('home.matrices');
        }).catch(err => {
          this.get('model').rollback();
          this.alert.displayServerErrors(err);
        });
      }
    }
  });
});