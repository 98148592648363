define("bet-ember/templates/components/utils/grid-item-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h01vGoXe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[11,\"class\",[29,[\"fluid-col \",[22,\"cssClass\"],\" \",[22,\"filterComponentId\"],\" \",[28,\"concat\",[\"infinity-scroll-item-\",[24,[\"index\"]]],null],\" \"]]],[8],[0,\"\\n\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/grid-item-component.hbs"
    }
  });
});