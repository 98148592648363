define("bet-ember/app", ["exports", "ember-data", "bet-ember/resolver", "ember-load-initializers", "bet-ember/config/environment", "bet-ember/mixins/filter-mixin", "bet-ember/mixins/pagniantion-filter-mixin", "bet-ember/mixins/form-validation", "bet-ember/mixins/keep-popup-on-top", "bet-ember/mixins/store-functions", "bet-ember/mixins/router-functions"], function (_exports, _emberData, _resolver, _emberLoadInitializers, _environment, _filterMixin, _pagniantionFilterMixin, _formValidation, _keepPopupOnTop, _storeFunctions, _routerFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  Ember.onerror = function (error) {
    console.log('Ember.onerror handler', error);
  };
  Ember.RSVP.on('error', function (error) {
    console.log('Ember.RSVP error handler', error);
  });
  Ember.Logger.error = function (message, cause, stack) {
    console.log('Ember.Logger.error handler', message, cause, stack);
  };
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Ember.webClientVersion = '1.0.0.0';
  Ember.host = 'http://85.187.102.89:8080';
  // Ember.host ='http://localhost:8080';
  Ember.host = 'https://bet.gara6.bg';
  Ember.namespace = 'bet-api';
  Ember.apiUrl = Ember.host + "/" + Ember.namespace + "/";
  Ember.headers = {
    "Accept": "application/json, text/javascript, */*; q=0.01",
    'Content-type': 'application/json'
  };
  Ember.MainRoute = Ember.Route.extend();
  Ember.CustomComponent = Ember.Component.extend(Ember.Evented, _formValidation.default, _keepPopupOnTop.default);
  Ember.FilterCustomComponent = Ember.CustomComponent.extend(_filterMixin.default);
  Ember.PaginationFilterCustomComponent = Ember.CustomComponent.extend(_pagniantionFilterMixin.default);
  Ember.CustomService = Ember.Service.extend(Ember.Evented);
  Ember.Router.reopen(_routerFunctions.default);
  _emberData.default.Store.reopen(_storeFunctions.default);
  _emberData.default.Model.reopen({
    rollback() {
      try {
        this.rollbackAttributes();
      } catch (err) {
        logger(err);
      }
    }
  });
  window.onkeydown = function (e) {
    if (e.keyCode == 8) {
      if (e.target.nodeName == 'INPUT' || e.target.nodeName == "TEXTAREA" || $(e.target).closest('.summernote-custom-component').length >= 1) {
        return;
      } else {
        window.history.back();
      }
    }
  };
  var _default = _exports.default = App;
});