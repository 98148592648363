define("bet-ember/helpers/visit-reasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.visitReasons = visitReasons;
  function visitReasons([profileTypeCode, specialityIdList]) {
    return this.get('visitReasonService').getByProfileTypeCodeAndSpecIds(profileTypeCode, specialityIdList);
  }
  var _default = _exports.default = Ember.Helper.extend({
    visitReasonService: Ember.inject.service('visit-reason'),
    compute: visitReasons,
    onRefreshChange: Ember.observer('visitReasonService.refresh', function () {
      this.recompute();
    })
  });
});