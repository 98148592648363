define("bet-ember/components/info-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: 'span',
    classNames: ['info-badge', 'ficon'],
    classNameBindings: ['iconClass', 'cssClass'],
    iconClass: "ficon-info",
    keepPopupOnTopSettings: {
      selectorId: "info_component_popup_internal",
      property: "isShowingModal"
    },
    click: function () {
      this.set('isShowingModal', true);
      return false;
    },
    actions: {
      closeModal() {
        this.set('isShowingModal', false);
      }
    }
  });
});