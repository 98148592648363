define("bet-ember/components/autosuggestion-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(Ember.Evented, {
    minChar: 2,
    pageNumber: 1,
    pageSize: 10,
    focusOut(e) {
      setTimeout(() => {
        this.closeSuggestions();
      }, 500);
    },
    keyUp(e) {
      var code = e.keyCode ? e.keyCode : e.which;
      switch (code) {
        case 40:
          break;
        case 38:
          break;
        case 13:
          if (this.get('hints') && this.get('hints').length) {
            this.enterPressed();
          } else {
            this.search();
          }
          break;
        default:
          if (this.get('lastSearchText') !== this.get('value')) {
            this.processKey();
          }
      }
    },
    keyDown(e) {
      var code = e.keyCode ? e.keyCode : e.which;
      switch (code) {
        case 40:
          this.move('down');
          break;
        case 38:
          this.move('up');
          break;
      }
    },
    move(direction) {
      direction = direction || "up";
      var current = this.$('li.on-focus');
      var next;
      if (current[0]) {
        next = direction == 'up' ? current.prev() : current.next();
      } else {
        next = this.$('li').first();
      }
      if (next.hasClass('loading') && this.$(next)[0] && !this.utils.isMobile()) {
        this.$(next)[0].scrollIntoView(false);
        return;
      }
      if (next[0]) {
        this.$('li.on-focus').removeClass('on-focus');
        this.$(next).addClass('on-focus');
        if (!this.utils.isMobile()) {
          this.$(next)[0].scrollIntoView(false);
        }
      } else if (current && direction == 'down' && this.get('allowNextPage')) {
        this.set('pageNumber', TryParse(this.get('pageNumber') || 1) + 1);
        this.search();
      } else if (current && direction == 'up') {
        current.removeClass('on-focus');
      }
    },
    enterPressed() {
      var current = this.$('li.on-focus');
      if (current && current[0]) {
        current.click();
      }
    },
    processKey() {
      this.set('lastSearchText', this.get('value'));
      this.set('pageNumber', 1);
      clearTimeout(this.get('searchTimeout'));
      if (!this.get('value')) {
        this.set('hints', []);
      } else if (this.get('value').length >= this.get('minChar')) {
        var searchTimeout = setTimeout(() => {
          this.search();
        }, 250);
        this.set('searchTimeout', searchTimeout);
      } else {
        this.set('hints', []);
      }
    },
    hintsObserverForScroll: Ember.observer('hints.[]', function () {
      // observe hints. if shown attach eventlistener for "scroll". When the end is hit search for more
      if (this.get('hints') && this.get('hints').length) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this = this;
          this.$('ul').on('scroll', function (e) {
            if ($(this) && $(this)[0] && $(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight && _this.get('allowNextPage')) {
              _this.set('pageNumber', TryParse(_this.get('pageNumber') || 1) + 1);
              _this.search();
            }
            return false;
          });
          this.$('li').hover(function (e) {
            _this.$('li').removeClass('on-focus');
            $(this).addClass('on-focus');
          }, function (e) {});
        });
      }
    }),
    search() {
      var queryParams = this.get('queryParams') || {};
      queryParams.pageNumber = this.get('pageNumber') || 1;
      var queryParamsFields = (this.get('queryBy') || 'searchText').split(',');
      queryParamsFields.forEach(field => {
        queryParams[field] = this.get('value');
      });
      if (this.get('addToFilter')) {
        var additionFilters = this.get('addToFilter');
        for (var key in additionFilters) {
          if (additionFilters[key] != null) {
            queryParams[key] = additionFilters[key];
          }
        }
      }
      queryParams.pageSize = this.get('pageSize');
      var promise = null;
      var uuid = Ember.uuid();
      this.set('promiseUUID', uuid);
      if (this.get('modelName')) {
        promise = this.store.query(this.get('modelName'), {
          queryParams: queryParams,
          endPoint: this.get('endPoint')
        });
      } else {
        promise = this.customAjax.call({
          queryParams: queryParams,
          endPoint: this.get('endPoint')
        }).then(payload => {
          try {
            return (payload.data.results || []).toArray();
          } catch (err) {
            throw err;
          }
        });
      }
      this.set('allowNextPage', false);
      this.set('loadingHints', true);
      this.set('noMatchesFound', false);
      // Ember.run.scheduleOnce('afterRender', this, function() {
      //   var loadingLi = this.$('li.loading')[0];
      //   if (loadingLi && !this.utils.isMobile()) {
      //     // loadingLi.scrollIntoView(false);
      //   }
      // });
      return promise.then(data => {
        if (uuid !== this.get('promiseUUID')) {
          return;
        }
        if (this.get('pageNumber') && this.get('pageNumber') > 1) {
          var hints = (this.get('hints') || []).toArray();
          this.set('hints', hints.concat(data.toArray()));
        } else {
          this.set('hints', data.toArray());
          if (data.toArray().length == 0) {
            this.set('noMatchesFound', true);
          }
        }
        this.set('allowNextPage', data.toArray().length == this.get('pageSize'));
        this.set('loadingHints', false);
      }).catch(err => {
        this.set('hints', []);
        this.set('loadingHints', false);
      });
    },
    closeSuggestions() {
      this.set('hints', []);
      this.set('loadingHints', null);
      this.set('noMatchesFound', null);
      this.set('papgeNumber', 1);
    },
    actions: {
      clear() {
        this.closeSuggestions();
        this.set('value', null);
        if (this.get('onSelect')) {
          this.get('onSelect')(null);
        }
      },
      select(hint) {
        if (this.get('valueProperty')) {
          hint = getProperty(hint, this.get('valueProperty'));
        }
        this.set('value', hint);
        if (this.get('onSelect')) {
          this.get('onSelect')(hint);
        }
        this.closeSuggestions();
      }
    }
  });
});