define("bet-ember/services/translate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    safeTranslateText: function (text) {
      if (text) {
        text = this.get('intl').t(text);
        if (text && typeof text === 'object') {
          text = text.toString();
        }
        text = text.replace('Missing translation: ', '');
        return text;
      }
      return "";
    }
  });
});