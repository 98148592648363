define("bet-ember/components/confirmation-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: 'span',
    keepPopupOnTopSettings: {
      selectorId: "confirmation_popup_internal",
      property: "isShowingModal"
    },
    actionTriggerObserver: Ember.observer('triggerAction', function () {
      if (this.get('triggerAction')) {
        this.send('showModal');
      }
    }),
    actions: {
      showModal(val) {
        this.set('isShowingModal', true);
      },
      closeModal() {
        this.set('isShowingModal', false);
      },
      confirm(val) {
        if (this.get('waitForPromise') === true) {
          var type = '#promiseTargetElement';
          (function (type) {
            var _this = this;
            this.loader.buttonEnable($(this.element).find(type)[0]);
            try {
              this.get('onConfirm')().then(function () {
                if ($(_this.element).find(type)[0]) {
                  _this.loader.buttonDisable($(_this.element).find(type)[0]);
                }
              });
            } catch (ex) {
              if ($(_this.element).find(type)[0]) {
                _this.loader.buttonDisable($(_this.element).find(type)[0]);
              }
            }
          }).call(this, type);
        } else {
          if (this.get('onConfirm')) {
            this.get('onConfirm')(val);
          }
        }
        this.set('isShowingModal', false);
      }
    }
  });
});