define("bet-ember/components/popup/header-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    actions: {
      closeModal() {
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      }
    }
  });
});