define("bet-ember/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const FALLBACK_LOCALE = 'bg';
  function _default(locale, key, data) {
    if (locale == undefined) {
      return "";
    }
    return "Missing " + locale;
  }
  //
  // export default function(locale, key, data) {
  //   if(!key){
  //     return '';
  //   }
  //   if (locale === FALLBACK_LOCALE || window.env === 'development') {
  //       return `Missing translation: ${key}`;
  //     } else {
  //       // NOTE This relies on internal APIs and is brittle.
  //       // Emulating the internals of ember-i18n's translate method.
  //       let i18n = this;
  //
  //       let count = Ember.get(data, 'count');
  //
  //       let defaults = Ember.makeArray(Ember.get(data, 'default'));
  //       defaults.unshift(key);
  //
  //       let localeObj = new Locale(FALLBACK_LOCALE, Ember.getOwner(i18n));
  //       let template = localeObj.getCompiledTemplate(defaults, count);
  //       return template(data); // english fallback
  //     }
  // }
});