define("bet-ember/templates/components/confirmation-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9UMf/c5g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"hidden\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"is-equal\",[[24,[\"btnType\"]],\"span\"],null]],null,{\"statements\":[[7,\"span\",false],[12,\"class\",\"force-link\"],[12,\"id\",\"promiseTargetElement\"],[3,\"action\",[[23,0,[]],\"showModal\"]],[8],[7,\"span\",true],[10,\"class\",\"ficon ficon-x\"],[8],[0,\"\\n\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"t\",[\"confirm\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"btn \",[28,\"if\",[[24,[\"type\"]],[24,[\"type\"]],\"btn-warning\"],null]]]],[12,\"id\",\"promiseTargetElement\"],[3,\"action\",[[23,0,[]],\"showModal\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"confirm\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"popup/popup-component\",null,[[\"showModal\",\"onClose\",\"kpot\"],[[28,\"mut\",[[24,[\"isShowingModal\"]]],null],[28,\"action\",[[23,0,[]],\"closeModal\"],null],true]],{\"statements\":[[4,\"popup/header-component\",null,[[\"title\",\"onClose\"],[[28,\"t\",[\"contact\"],null],[28,\"action\",[[23,0,[]],\"closeModal\"],null]]],{\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[3,\"action\",[[23,0,[]],\"confirm\",[24,[\"value\"]]]],[8],[1,[28,\"t\",[\"yes\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"popup/main-component\",null,null,{\"statements\":[[1,[28,\"if\",[[24,[\"message\"]],[24,[\"message\"]],[28,\"t\",[\"confirmText\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/confirmation-component.hbs"
    }
  });
});