define("bet-ember/components/events/save-multiple-popup-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    on
  } = Ember;
  var _default = _exports.default = Ember.CustomComponent.extend({
    onOpenModal: Ember.on('init', Ember.observer('showModal', function () {
      if (this.get('showModal')) {
        let runnerASide = this.storage.getItem('runnerASide');
        this.set('runnerASide', runnerASide);
        this.set('runnerBSide', this.storage.getItem('runnerBSide'));
        this.set('adjStartTimeInSec', this.storage.getItem('adjStartTimeInSec'));
        this.set('adjEndTimeInSec', this.storage.getItem('adjEndTimeInSec'));
        this.set('repeatCount', this.storage.getItem('repeatCount'));
        this.set('repeatDelay', this.storage.getItem('repeatDelay'));
        this.set('minTotalVolume', this.storage.getItem('minTotalVolume'));

        // let thresholds = this.storage.getItem(this.get('thresholdKey')) || this.get('defaultThresholds');
        // this.set('thresholds', thresholds);
      }
    })),
    runnerASideObserver: Ember.observer('runnerASide', function () {
      if (this.get('thresholds')) {
        this.storage.setItem(this.get('prevThresholdKey'), JSON.stringify(this.get('thresholds')));
      }
      let thresholds = this.storage.getItem(this.get('thresholdKey')) || this.get('defaultThresholds');
      this.set('thresholds', thresholds);
    }),
    thresholdKey: Ember.computed('runnerASide', function () {
      if (this.get('runnerASide') === 'LAY') {
        return 'layThresholds';
      } else {
        return 'backThresholds';
      }
    }),
    prevThresholdKey: Ember.computed('runnerASide', function () {
      if (this.get('runnerASide') === 'LAY') {
        return 'backThresholds';
      } else {
        return 'layThresholds';
      }
    }),
    defaultThresholds: Ember.computed('runnerASide', function () {
      if (this.get('runnerASide') === 'LAY') {
        return [{
          count: 1,
          from: 70,
          to: 100
        }, {
          count: 2,
          from: 170,
          to: 200
        }, {
          count: 3,
          from: 270,
          to: 300
        }, {
          count: 4,
          from: 370,
          to: 400
        }, {
          count: 5,
          from: 470,
          to: 500
        }, {
          count: 6,
          from: 570,
          to: 600
        }];
      } else {
        return [{
          count: 1,
          from: 100,
          to: 130
        }, {
          count: 2,
          from: 200,
          to: 230
        }, {
          count: 3,
          from: 300,
          to: 330
        }, {
          count: 4,
          from: 400,
          to: 430
        }, {
          count: 5,
          from: 500,
          to: 530
        }, {
          count: 6,
          from: 600,
          to: 630
        }];
      }
    }),
    actions: {
      save() {
        this.alert.clearAll();
        var formIsValid = this.formIsValid({
          selector: ".save-multiple-form"
        });
        if (!formIsValid) {
          return;
        }
        let adjStartTimeInSec = this.get('adjStartTimeInSec') ? parseInt(this.get('adjStartTimeInSec')) : null;
        let adjEndTimeInSec = this.get('adjEndTimeInSec') ? parseInt(this.get('adjEndTimeInSec')) : null;
        let thresholds = this.get('thresholds');
        let repeatCount = this.get('repeatCount') ? parseInt(this.get('repeatCount')) : null;
        let repeatDelay = this.get('repeatDelay') ? parseInt(this.get('repeatDelay')) : null;
        let minTotalVolume = this.get('minTotalVolume') ? parseInt(this.get('minTotalVolume')) : null;
        this.storage.setItem('runnerASide', this.get('runnerASide'));
        this.storage.setItem('runnerBSide', this.get('runnerBSide'));
        this.storage.setItem('adjStartTimeInSec', adjStartTimeInSec);
        this.storage.setItem('adjEndTimeInSec', adjEndTimeInSec);
        this.storage.setItem(this.get('thresholdKey'), JSON.stringify(thresholds));
        this.storage.setItem('repeatCount', repeatCount);
        this.storage.setItem('repeatDelay', repeatDelay);
        this.storage.setItem('minTotalVolume', minTotalVolume);
        if (this.get('onSave')) {
          return this.get('onSave')(this.get('runnerASide'), this.get('runnerBSide'), adjStartTimeInSec, adjEndTimeInSec, thresholds, repeatCount, repeatDelay, minTotalVolume);
        }
      },
      closeModal() {
        this.set('showModal', false);
      }
    }
  });
});