define("bet-ember/components/events/search-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.FilterCustomComponent.extend({
    filterFields: Ember.computed(function () {
      var all = this.eventTypeService.get('all').map(p => "event-type-" + p.get('id'));
      all.push({
        field: "from",
        transform: val => {
          return getBeginningOfDay(val).getTime();
        }
      });
      all.push({
        field: "to",
        transform: val => {
          return getEndOfDay(val).getTime();
        }
      });
      all.push("timeFrame");
      return all;
    }),
    endPoint: 'events',
    modelName: "event",
    preRequest(filter) {
      var arr = [];
      this.eventTypeService.get('all').forEach((eventType, i) => {
        if (filter && filter['event-type-' + eventType.get('id')]) {
          arr.push(eventType.get('id'));
          delete filter['event-type-' + eventType.get('id')];
        }
      });
      if (arr.length > 0) {
        filter.eventTypeIds = arr.join(',');
      } else {
        filter.eventTypeIds = 0;
      }
      if (filter.timeFrame == "today") {
        filter.from = getBeginningOfDay().getTime();
        filter.to = getEndOfDay().getTime();
      } else if (filter.timeFrame == "thisWeek") {
        filter.from = getBeginningOfWeek().getTime();
        filter.to = getEndOfWeek().getTime();
      }
      delete filter.timeFrame;
    },
    actions: {
      selectAll() {
        this.eventTypeService.get('all').forEach((eventType, i) => {
          this.set('event-type-' + eventType.id, true);
        });
      },
      deselectAll() {
        this.eventTypeService.get('all').forEach((eventType, i) => {
          this.set('event-type-' + eventType.id, false);
        });
      },
      cancelBet(betId, marketId) {
        this.customAjax.call({
          type: "POST",
          endPoint: "bet/cancelBet",
          queryParams: {
            betId: betId,
            marketId: marketId
          }
        }).then(payload => {
          logger(payload);
        }).catch(err => {
          logger(err);
        });
      }
    }
  });
});