define("bet-ember/helpers/utc-to-local", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.utcToLocal = utcToLocal;
  function utcToLocal([milliseconds]) {
    if (milliseconds) {
      var date = new Date(0);
      date.setUTCSeconds(milliseconds);
      return date;
    }
    return null;
  }
  var _default = _exports.default = Ember.Helper.helper(utcToLocal);
});