define("bet-ember/helpers/or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.or = or;
  function or([val1, val2, val3, val4, val5, val6]) {
    return val1 || val2 || val3 || val4 || val5 || val6;
  }
  var _default = _exports.default = Ember.Helper.helper(or);
});