define("bet-ember/mixins/form-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    formIsValid(opt) {
      if (opt == null || opt.selector == null) {
        return false;
      }
      var valid = true;
      if (opt.prepFunc) {
        var prepFuncValidity = opt.prepFunc.call(this);
        if (prepFuncValidity != null && (prepFuncValidity == true || prepFuncValidity == false)) {
          valid = valid && prepFuncValidity;
        }
      }
      $(opt.selector).validator('destroy');
      $(opt.selector).validator('validate');
      if (!$(opt.selector)[0].checkValidity()) {
        valid = false;
      }
      if (opt.postFunc) {
        var postFuncValidity = opt.postFunc.call(this);
        if (postFuncValidity != null && (postFuncValidity == true || postFuncValidity == false)) {
          valid = valid && postFuncValidity;
        }
      }
      return valid;
    }
  });
});