define("bet-ember/components/custom-time-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    initTimer: Ember.on('didInsertElement', function () {
      $('#' + this.get('componentId')).clockpicker({
        placement: 'bottom',
        align: 'left',
        autoclose: true,
        donetext: 'Done',
        afterShow: evt => {
          var zIndex = this.utils.currentMaxZIndex() + 1;
          $(".clockpicker-popover").css('z-index', zIndex);
        }
      });
    }),
    //
    //
    // recieveAttributes:Ember.on('didReceiveAttrs',function () {
    //   var value = this.get('value');
    //   if(value){
    //     this.set('lock',true);
    //     this.set('internalValue',this.getTimeForInput(value));
    //     this.set('lock',false);
    //   }
    // }),
    //
    // internalValueObserver:Ember.observer('internalValue',function () {
    //   if(this.get('lock')){
    //     return;
    //   }
    //   this.set('lock',true);
    //   this.set('value',this.get('internalValue'));
    //   this.set('lock',false);
    // }),
    //
    //
    // minTime:Ember.computed('startTime',function () {
    //   if(this.get('startTime')){
    //     return this.getTimeForInput(this.get('startTime'));
    //   }else{
    //     return null;
    //   }
    // }),
    //
    // maxTime:Ember.computed('endTime',function () {
    //   if(this.get('endTime')){
    //     return this.getTimeForInput(this.get('endTime'));
    //   }else{
    //     return null;
    //   }
    // }),
    //
    // componentId:Ember.computed(function () {
    //   return Ember.uuid();
    // }),
    //
    // getTimeForInput(time){
    //   return time;
    // },

    actions: {
      // onKeyDown(){
      //   return false;
      // },
      // onChange(val){
      //   var value = val.srcElement.value;
      //   this.set('value',value);
      // },
    }
  });
});