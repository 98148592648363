define("bet-ember/router", ["exports", "bet-ember/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('page-not-found', {
      path: '/*wildcard'
    });
    this.route('login', {
      path: '/login'
    });
    this.route('logout');
    this.route('home', {
      path: '/home'
    }, function () {
      this.route('events', {
        path: '/events'
      }, function () {
        this.route('view', {
          path: '/view/:_id'
        });
      });
      this.route('matrices', {
        path: '/matrices'
      }, function () {
        this.route('create', {
          path: '/create'
        });
        this.route('view', {
          path: '/view/:_id'
        });
      });
      this.route('auto-bets', {
        path: '/auto-bets'
      });
      this.route('bets', {
        path: '/bets'
      });
      this.route('settings', {
        path: '/settings'
      });
    });
  });
  var _default = _exports.default = Router;
});