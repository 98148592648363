define("bet-ember/templates/components/popup/popup-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cKJzIXne",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"showModal\"]]],null,{\"statements\":[[7,\"div\",true],[8],[4,\"tether-dialog\",null,[[\"hasOverlay\",\"onClose\",\"containerClassNames\",\"translucentOverlay\",\"containerClass\"],[true,\"closeModal\",[28,\"concat\",[\"popup \",[24,[\"containerClassNames\"]],\" \",[24,[\"cssClass\"]]],null],true,[24,[\"containerClass\"]]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"section\",true],[10,\"class\",\"inner-wrap\"],[11,\"id\",[22,\"componentId\"]],[8],[0,\"\\n\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/popup/popup-component.hbs"
    }
  });
});