define("bet-ember/components/autocomplete-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /// Attributes ->
  /// endPoint -
  /// modelName - (otional)
  /// displayProperty - property to get from results to display
  /// valueProperty - property to get from results to set as value
  /// queryBy - to query results by field (default is 'searchText'), can be more than one, "," - csv
  /// searchText - text to search by, filled in the input
  /// minChar = 2
  /// pageNumber = 1
  /// pageSize = 10
  /// onChange -> fire action "onChange" with value the selectedValue
  var _default = _exports.default = Ember.Component.extend(Ember.Evented, {
    minChar: 2,
    pageNumber: 1,
    pageSize: 10,
    fireEvents(value) {
      if (this.get('onChange')) {
        this.get('onChange')(value);
      }
    },
    validate(value) {
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.utils.validateCustomSelectComponent.call(this, value);
        if (value != null && this.$('input.validation-ignore')) {
          // this.$('input.validation-ignore').focus();
        }
        if (this.$('.dummy-input')) {
          this.$('.dummy-input').trigger('change-internal');
        }
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      this.setProperties({
        selectedObject: null,
        selectedValue: null,
        // _lastValidSelectedValue:null,
        searchText: null,
        hints: []
      });
      $(document).off('click', 'keydown', 'keyup');
    },
    didInsertElementHook: Ember.on('didInsertElement', function () {
      var _this = this;
      //detect click outside of the component to close hints (if open). Acount for if popup is open
      $(document).on('click', event => {
        if ($('#' + _this.get('componentId')) && (!$(event.target).closest('#' + _this.get('componentId')).length && !$('.popup').length || !$(event.target).closest('.popup')) && _this.hints) {
          if (this.get('_valueSelected')) {
            // if value is selected just clear hints, or else clear everything
            this.set('hints', []);
          } else {
            if (_this.get('stopAutocompleteClear') === true) {
              this.set('hints', []);
            } else {
              this.send('clear');
            }
          }
        }
      });
      $("#" + _this.get('componentId')).on('keyup', e => {
        // use keyup to process all keys (except "up" ,"down")
        var code = e.keyCode ? e.keyCode : e.which;
        switch (code) {
          case 40:
            break;
          case 38:
            break;
          case 13:
            if (_this.get('hints') && _this.get('hints').length) {
              _this.enterPressed();
            } else {
              _this.search();
            }
            break;
          default:
            if (this.get('_lastSearchText') !== this.get('searchText')) {
              _this.processKey();
            }
        }
      });
      $("#" + _this.get('componentId')).on('keydown', e => {
        // use keydown for "up" and "down" to acount for long press
        var code = e.keyCode ? e.keyCode : e.which;
        switch (code) {
          case 40:
            _this.move('down');
            break;
          case 38:
            _this.move('up');
            break;
        }
      });
    }),
    move(direction) {
      direction = direction || "up";
      var current = this.$('li.on-focus');
      var next;
      if (current[0]) {
        next = direction == 'up' ? current.prev() : current.next();
      } else {
        next = this.$('li').first();
      }
      if (next.hasClass('loading') && this.$(next)[0] && !this.utils.isMobile()) {
        this.$(next)[0].scrollIntoView(false);
        return;
      }
      if (next[0]) {
        this.$('li.on-focus').removeClass('on-focus');
        this.$(next).addClass('on-focus');
        if (!this.utils.isMobile()) {
          this.$(next)[0].scrollIntoView(false);
        }
      } else if (current && direction == 'down' && this.get('allowNextPage')) {
        this.set('pageNumber', TryParse(this.get('pageNumber') || 1) + 1);
        this.search();
      } else if (current && direction == 'up') {
        current.removeClass('on-focus');
      }
    },
    enterPressed() {
      var current = this.$('li.on-focus');
      if (current && current[0]) {
        current.click();
      } else if (this.get('showFind')) {
        console.log('gisda');
        this.get('showFind')();
      }
    },
    processKey(invalidateSelected) {
      this.set('_valueSelected', false);
      this.set('_lastSearchText', this.get('searchText'));
      this.set('pageNumber', 1);
      clearTimeout(this.get('searchTimeout'));
      if (!this.get('searchText')) {
        this.set('hints', []);
      } else if (this.get('searchText').length >= this.get('minChar')) {
        var searchTimeout = setTimeout(() => {
          this.search();
        }, 250);
        this.set('searchTimeout', searchTimeout);
      } else {
        this.set('hints', []);
      }
    },
    hintsObserverForScroll: Ember.observer('hints.[]', function () {
      // observe hints. if shown attach eventlistener for "scroll". When the end is hit search for more
      if (this.get('hints') && this.get('hints').length) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this = this;
          this.$('ul').on('scroll', function (e) {
            if ($(this) && $(this)[0] && $(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight && _this.get('allowNextPage')) {
              _this.set('pageNumber', TryParse(_this.get('pageNumber') || 1) + 1);
              _this.search();
            }
            return false;
          });
          this.$('li').hover(function (e) {
            _this.$('li').removeClass('on-focus');
            $(this).addClass('on-focus');
          }, function (e) {});
        });
      }
    }),
    selectedValueObserver: Ember.on('init', Ember.observer('selectedValue', function () {
      // used to accept the "selectedValue" from the outside and set it
      var selectedValue = this.get('selectedValue');
      var selectedObject = this.get('selectedObject');
      // if(this.get('_lastValidSelectedValue')!=selectedValue){
      if (!selectedObject && selectedValue || selectedObject && getProperty(selectedObject, this.get('valueProperty')) !== selectedValue) {
        if (selectedValue) {
          var promise = this.store.getRecord(this.get('modelName'), selectedValue);
          return promise.then(object => {
            this.updateProperties(object);
          }).catch(err => {
            this.updateProperties(null);
          });
        } else {
          this.updateProperties(null);
        }
      }
    })),
    search() {
      var queryParams = this.get('queryParams') || {};
      queryParams.pageNumber = this.get('pageNumber') || 1;
      var queryParamsFields = (this.get('queryBy') || 'searchText').split(',');
      queryParamsFields.forEach(field => {
        queryParams[field] = this.get('searchText');
      });
      if (this.get('addToFilter')) {
        var additionFilters = this.get('addToFilter');
        for (var key in additionFilters) {
          if (additionFilters[key] != null) {
            queryParams[key] = additionFilters[key];
          }
        }
      }
      var promise = null;
      var uuid = Ember.uuid();
      this.set('promiseUUID', uuid);
      if (this.get('modelName')) {
        promise = this.store.query(this.get('modelName'), {
          queryParams: queryParams,
          endPoint: this.get('endPoint')
        });
      } else {
        promise = this.customAjax.call({
          queryParams: queryParams,
          endPoint: this.get('endPoint')
        });
      }
      this.set('allowNextPage', false);
      this.set('loadingHints', true);
      this.set('noMatchesFound', false);
      Ember.run.scheduleOnce('afterRender', this, function () {
        var loadingLi = this.$('li.loading')[0];
        if (loadingLi && !this.utils.isMobile()) {
          // loadingLi.scrollIntoView(false);
        }
      });
      return promise.then(data => {
        if (uuid !== this.get('promiseUUID')) {
          return;
        }
        if (this.get('pageNumber') && this.get('pageNumber') > 1) {
          var hints = (this.get('hints') || []).toArray();
          this.set('hints', hints.concat(data.toArray()));
        } else {
          this.set('hints', data.toArray());
          if (data.toArray().length == 0) {
            this.set('noMatchesFound', true);
          }
        }
        this.set('allowNextPage', data.toArray().length == this.get('pageSize'));
        this.set('loadingHints', false);
      }).catch(err => {
        this.set('hints', []);
        this.set('loadingHints', false);
      });
    },
    updateProperties(selectedObject) {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var selectedValue = null;
        var searchText = null;
        if (selectedObject) {
          selectedValue = getProperty(selectedObject, this.get('valueProperty'));
          searchText = this.get('persistSelected') != false ? getProperty(selectedObject, this.get('displayProperty')) : null;
        }
        this.setProperties({
          selectedObject: selectedObject,
          selectedValue: selectedValue,
          // _lastValidSelectedValue:selectedValue,
          searchText: searchText,
          hints: []
        });
        if (this.attrs && this.attrs.selectedObject) {
          this.attrs.selectedObject.update(selectedObject);
        }
        if (this.attrs && this.attrs.selectedValue) {
          this.attrs.selectedValue.update(selectedValue);
        }
        this.set('_valueSelected', true);
        this.validate(selectedValue);
        this.fireEvents(selectedValue);
      });
    },
    actions: {
      clear() {
        this.setProperties({
          selectedObject: null,
          selectedValue: null,
          noMatchesFound: null,
          hints: [],
          typing: false,
          searchText: null,
          pageNumber: 1
        });
        this.$('.dummy-input').val("");
      },
      select(selectedObject) {
        this.set('hints', []);
        this.set('papgeNumber', 1);
        this.set('typing', false);
        this.updateProperties(selectedObject);
      }
    }
  });
});