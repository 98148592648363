define("bet-ember/templates/components/file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "91WhY9jF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label line-fix\"],[8],[9],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-default mr-5\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"onClick\"]],[8],[1,[22,\"buttonText\"],false],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"accept\",\"class\"],[\"file\",[24,[\"file\"]],[24,[\"accept\"]],\"file-uploader-input hidden\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"uploadedFile\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"txt-label bg-dark-cyan mr-5\"],[8],[0,\"\\n        \"],[1,[24,[\"uploadedFile\",\"name\"]],false],[7,\"span\",false],[12,\"class\",\"ficon ficon-x v-align-middle ml-5\"],[3,\"action\",[[23,0,[]],\"onFileRemove\"]],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"txt-gray txt-small\"],[8],[1,[22,\"fileMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/file-uploader.hbs"
    }
  });
});