define("bet-ember/components/form-group-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    componentId: Ember.computed(function () {
      return Ember.uuid();
    }),
    onInitComp: Ember.on('didInsertElement', Ember.observer('value', function () {
      if (this.get('componentType') == "textarea") {
        this.resizeTextArea();
      }
    })),
    willDestroyElement() {
      try {
        $('.comp-id-' + this.get('componentId')).off("focusin", () => {
          this.onFocusInComp();
        });
      } catch (err) {}
      this._super(...arguments);
    },
    onFocusInComponent: Ember.on('didInsertElement', function () {
      if (this.get('utils.isMobileComputed')) {
        try {
          $('.comp-id-' + this.get('componentId')).off("focusin", () => {
            this.onFocusInComp();
          });
        } catch (err) {}
        try {
          $('.comp-id-' + this.get('componentId')).on("focusin", () => {
            this.onFocusInComp();
          });
        } catch (err) {}
      }
    }),
    resizeTextArea() {
      var textarea = $('#' + this.get('componentId'))[0];
      var str = this.get('value') || "";
      var cols = 1;
      try {
        cols = textarea.cols;
      } catch (err) {}
      var linecount = 0;
      str.split("\n").forEach(function (l) {
        linecount += Math.ceil(l.length / cols); // Take into account long lines
      });
      try {
        textarea.rows = linecount + 1;
      } catch (err) {}
    },
    onFocusInComp() {
      $('.comp-id-' + this.get('componentId')).focus();
      logger(this.get('componentId'));
    },
    regNumValueObserver: Ember.on('didInsertElement', Ember.observer('value', function () {
      if (this.get('componentType') == "reg-num") {
        Ember.run.scheduleOnce('afterRender', this, function () {
          var val = this.get('value');
          if (val) {
            this.set('value', val.toUpperCase());
          }
        });
      }
    })),
    actions: {
      upCaseRegNum(val, e) {
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        // Allow: Ctrl+A, Command+A
        e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true) ||
        // Allow: home, end, left, right, down, up
        e.keyCode >= 35 && e.keyCode <= 40 || e.keyCode >= 65 && e.keyCode <= 90) {
          // let it happen, don't do anything
          return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
        }
      }
    }
  });
});