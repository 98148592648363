define("bet-ember/helpers/replace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.replace = replace;
  function replace([string, stringToReplace, stringToReplaceWith, modifiers]) {
    if (string == null) {
      return string;
    }
    var rx = new RegExp(stringToReplace, modifiers);
    if (rx) {
      return string.replace(rx, stringToReplaceWith);
    } else {
      return string;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(replace);
});