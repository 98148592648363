define("bet-ember/helpers/get-by-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getByGroup = getByGroup;
  function getByGroup([array, group, key, val, prop]) {
    let res = null,
      temp = null;
    if (array == null) {
      return null;
    }
    try {
      val = JSON.parse(val);
      group = JSON.parse(group);
    } catch (ex) {}
    if (array && Array.isArray(array)) {
      temp = array[group];
      if (temp && typeof temp.findBy === 'function') {
        res = temp.findBy(key, val);
      } else {
        for (let i = 0; i < temp.length; i++) {
          if (temp[i][key] != null && temp[i][key] == val) {
            res = temp[i];
          }
        }
      }
    }
    if (res != null) {
      if (prop) {
        if (res.get) {
          res = res.get(prop);
        } else {
          res = res[prop];
        }
      }
    }
    return res;
  }
  var _default = _exports.default = Ember.Helper.helper(getByGroup);
});