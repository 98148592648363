define("bet-ember/components/events/auto-bet-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    on
  } = Ember;
  var _default = _exports.default = Ember.CustomComponent.extend({
    openedMarketStartTimes: [],
    onCompInit: on('init', function () {
      this.loadMarketCatalogues();
      clearInterval(this.get('loadDataHandler'));
      this.set('loadDataHandler', setInterval(() => {
        this.loadMarketCatalogues();
      }, 5 * 1000));
      this.set('toggleHeaderMatrixSelectorHandler', this.toggleHeaderMatrixSelector.bind(this));
      document.addEventListener('scroll', this.get('toggleHeaderMatrixSelectorHandler'));
    }),
    willDestroyElement() {
      this._super(...arguments);
      clearInterval(this.get('loadDataHandler'));
      document.removeEventListener('scroll', this.get('toggleHeaderMatrixSelectorHandler'));
    },
    toggleHeaderMatrixSelector() {
      let header = $('#header-title-place')[0];
      let topOffSet = 150;
      if (header && header.getBoundingClientRect) {
        let headerDimentions = header.getBoundingClientRect();
        topOffSet = headerDimentions.top + headerDimentions.height + 20;
      }
      if ($('.matrix-selector').isOnScreen(null, {
        top: topOffSet
      })) {
        this.set('showHeaderMatrixSelector', false);
      } else {
        this.set('showHeaderMatrixSelector', true);
      }
    },
    loadMarketCatalogues() {
      this.set('requestLoading', true);
      return this.store.query('market', {
        endPoint: "marketCatalogues",
        queryParams: {
          eventIds: this.get('model.id')
        }
      }).then(marketCatalogues => {
        marketCatalogues = (marketCatalogues || []).toArray();
        this.set("model.markets", marketCatalogues);
        this.set('requestLoading', false);
        return marketCatalogues;
      }).catch(err => {
        logger(err);
        this.set('requestLoading', false);
        return [];
      });
    },
    marketStartTimes: Ember.computed('model.markets.@each.marketStartTime', function () {
      let times = this.get('openedMarketStartTimes');
      return (this.get('model.markets') || []).toArray().map(p => {
        return Ember.Object.create({
          time: p.get('marketStartTime'),
          hide: times.indexOf(p.get('marketStartTime')) == -1,
          runnersCount: (p.runners || []).toArray().length
        });
      }).uniqBy('time');
    }),
    matrix: Ember.computed('matrixId', function () {
      if (this.get('matrixId') == null) {
        return null;
      } else {
        return this.matrixService.getById(this.get('matrixId'));
      }
    }),
    actions: {
      cancel() {},
      saveMultiple(runnerASide, runnerBSide, adjStartTimeInSec, adjEndTimeInSec, thresholds, repeatCount, repeatDelay, minTotalVolume) {
        if (this.get('matrix') == null) {
          this.alert.show({
            message: this.intl.t('matrixIsNotSelected'),
            type: "warning"
          });
          return;
        }
        if (this.get('targetA.marketId') == null || this.get('targetB.marketId') == null) {
          this.alert.show({
            message: this.intl.t('marketsAreNotSelected'),
            type: "warning"
          });
          return;
        }
        var matrix = this.get('matrix');
        var targetA = copyObject(this.get('targetA'));
        var targetB = copyObject(this.get('targetB'));
        if (targetA.runners == null || targetA.runners.length == 0 || targetA.runners == null || targetA.runners.length == 0) {
          this.alert.show({
            message: this.intl.t('noPriceYet'),
            type: "warning"
          });
          return;
        }
        var runnersA = targetA.runners.map(p => {
          return {
            id: p.selectionId,
            runnerName: p.runnerName
          };
        });
        delete targetA.market;
        delete targetA.runners;
        var runnersB = targetB.runners.map(p => {
          return {
            id: p.selectionId,
            runnerName: p.runnerName
          };
        });
        delete targetB.market;
        delete targetB.runners;
        var event = this.get('model').serialize();
        var promises = [];
        let length = runnersA.length == runnersB.length ? runnersA.length : runnersA.length > runnersB.length ? runnersB.length : runnersA.length;
        for (var i = 0; i < length; i++) {
          targetA.runnerId = runnersA[i].id;
          targetA.runnerName = runnersA[i].runnerName;
          targetA.side = runnerASide;
          targetB.runnerId = runnersB[i].id;
          targetB.runnerName = runnersB[i].runnerName;
          targetB.side = runnerBSide;
          let startTime = null;
          if (adjStartTimeInSec) {
            startTime = targetA.marketStartTime <= targetB.marketStartTime ? targetA.marketStartTime : targetB.marketStartTime;
            startTime = startTime - adjStartTimeInSec * 1000;
          }
          let endTime = null;
          if (adjEndTimeInSec) {
            endTime = targetA.marketStartTime <= targetB.marketStartTime ? targetA.marketStartTime : targetB.marketStartTime;
            endTime = endTime - adjEndTimeInSec * 1000;
          }
          thresholds = thresholds || [];
          let autoBet = this.store.createRecord('auto-bet', {
            startTime: startTime,
            endTime: endTime,
            matrix: matrix,
            content: JSON.stringify({
              event: event,
              targetA: targetA,
              targetB: targetB,
              thresholds: thresholds,
              repeatDelay: repeatDelay,
              repeatCount: repeatCount,
              minTotalVolume: minTotalVolume
            })
          });
          promises.push(autoBet.save());
        }
        return Ember.RSVP.allSettled(promises).then(data => {
          let saved = "";
          let type = null;
          try {
            let fulfilled = data.filter(p => p.state == "fulfilled").length;
            let all = data.length;
            saved = fulfilled + "/" + all;
            if (fulfilled != all) {
              type = "warning";
            }
          } catch (err) {
            logger(err);
          }
          this.store.removeAllNew();
          this.alert.show({
            message: this.intl.t('saved') + " " + saved,
            type: type
          });
          this.set('showSaveMultipleModal', false);
          this.onCompInit();
          return data;
        });
      },
      save() {
        this.alert.clearAll();
        var formIsValid = this.formIsValid({
          selector: ".save-form"
        });
        if (!formIsValid) {
          return;
        }
        if (this.get('targetA.runnerId') == null || this.get('targetB.runnerId') == null) {
          this.alert.show({
            message: this.intl.t('2runnersMustBeSelected'),
            type: "warning"
          });
          return;
        }
        var matrix = this.get('matrix');
        var targetA = copyObject(this.get('targetA'));
        var targetB = copyObject(this.get('targetB'));
        delete targetA.market;
        delete targetA.runners;
        delete targetB.market;
        delete targetB.runners;
        var event = this.get('model').serialize();
        var autoBet = this.store.createRecord('auto-bet', {
          matrix: matrix,
          content: JSON.stringify({
            event: event,
            targetA: targetA,
            targetB: targetB,
            thresholds: []
          })
        });
        return autoBet.save().then(data => {
          this.store.removeAllNew();
          this.alert.show({
            message: this.intl.t('saved')
          });
          this.onCompInit();
          return data;
        }).catch(err => {
          logger(err);
          this.alert.displayServerErrors(err);
        });
      },
      loadMarket(market, marketPosition) {
        var targetData = null;
        if (market) {
          targetData = Ember.Object.create({
            market: market,
            // runners: (market.runners || []).toArray(),
            marketId: market.marketId,
            marketName: market.marketName,
            marketStartTime: market.marketStartTime,
            marketPosition: marketPosition
          });
          this.customAjax.call({
            endPoint: "marketBooks",
            queryParams: {
              marketIds: market.get('marketId')
            }
          }).then(payload => {
            let reloadedMarket = payload.data.results[0] || {};
            market.set('numberOfWinners' + marketPosition, reloadedMarket.numberOfWinners);
            var runners = reloadedMarket.runners || [];
            runners.forEach((runner, i) => {
              try {
                var tempRunner = targetData.market.runners.toArray().find(p => p.selectionId == runner.selectionId);
                if (tempRunner) {
                  runner.runnerName = tempRunner.runnerName;
                }
              } catch (err) {
                logger(err);
              }
              let availableToBack = runner.ex.availableToBack.sortByNumberKey('price');
              availableToBack = availableToBack.slice(availableToBack.length - 3);
              let availableToLay = runner.ex.availableToLay.sortByNumberKey('price');
              availableToLay = availableToLay.slice(0, 3);
              runner.ex.availableToBack = availableToBack;
              runner.ex.availableToLay = availableToLay;
            });
            targetData.set('runners', runners);
          }).catch(err => {
            logger(err);
          });
        }
        this.set("target" + marketPosition, targetData);
      },
      selectRunner(marketData, runner, side) {
        marketData.set('runnerId', runner.id);
        marketData.set('runnerName', runner.runnerName);
        marketData.set('side', side);
      },
      toggleMarketStartTime(marketStartTime) {
        if (marketStartTime.get('hide')) {
          marketStartTime.set('hide', false);
          this.get('openedMarketStartTimes').pushObject(marketStartTime.get('time'));
        } else {
          marketStartTime.set('hide', true);
          this.get('openedMarketStartTimes').removeObject(marketStartTime.get('time'));
        }
      }
    }
  });
});