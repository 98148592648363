define("bet-ember/templates/components/info-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ERcu5EW8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[22,\"label\"],false],[0,\"\\n\"],[4,\"popup/popup-component\",null,[[\"showModal\",\"onClose\",\"kpot\"],[[28,\"mut\",[[24,[\"isShowingModal\"]]],null],[28,\"action\",[[23,0,[]],\"closeModal\"],null],true]],{\"statements\":[[4,\"popup/header-component\",null,[[\"title\",\"onClose\"],[[28,\"if\",[[24,[\"title\"]],[24,[\"title\"]],[28,\"t\",[\"info\"],null]],null],[28,\"action\",[[23,0,[]],\"closeModal\"],null]]],{\"statements\":[],\"parameters\":[]},null],[4,\"popup/main-component\",null,null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/info-component.hbs"
    }
  });
});