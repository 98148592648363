define("bet-ember/helpers/json-parse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JsonParse = JsonParse;
  _exports.default = void 0;
  function JsonParse(data) {
    return TryParse(data);
  }
  var _default = _exports.default = Ember.Helper.helper(JsonParse);
});