define("bet-ember/helpers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.index = index;
  function index([arr, key]) {
    var key = key || 0;
    return arr[key];
  }
  var _default = _exports.default = Ember.Helper.helper(index);
});