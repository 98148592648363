define("bet-ember/helpers/user-can-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.userCanAccess = userCanAccess;
  function userCanAccess(classes) {
    return this.get('currentUser').checkPermission(classes);
  }
  var _default = _exports.default = Ember.Helper.extend({
    currentUser: Ember.inject.service('current-user'),
    compute: userCanAccess
  });
});