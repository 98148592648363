define("bet-ember/components/select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    currentValue: null,
    setRequiredAttr: Ember.on('didInsertElement', Ember.observer('required', function () {
      if (this.get('required')) {
        $(this.element).find('select')[0].required = true;
      } else {
        $(this.element).find('select')[0].required = false;
      }
    })),
    onCompInit: Ember.on('didInsertElement', 'didUpdate', Ember.observer('value', function () {
      var value = this.get('value');
      var options = this.getOptions();
      var option = options.find(p => p.value == value);
      var internalValue = this.get('internalValue');
      var prompt = this.get('prompt');
      if (option) {
        this.set('internalValue', option.value);
        $(this.element).find('select').val(option.value);
      } else if (value == null && prompt) {
        this.set('internalValue', '');
        $(this.element).find('select').val('');
      } else {
        this.selectValue(options[0] ? options[0].value : null);
      }
    })),
    getOptions() {
      return $(this.element).find('select option').toArray().map(p => {
        return {
          element: p,
          value: p.value,
          label: p.label
        };
      });
    },
    selectValue(value) {
      this.set('internalValue', value);
      $(this.element).find('select').val(value);
      if (this.get('onChange')) {
        this.get('onChange')(value);
      }
      if (this.get('_onChange')) {
        this.get('_onChange')(value);
      }
      this.utils.validateCustomSelectComponent.call(this, value);
    },
    actions: {
      changed(value) {
        this.selectValue(value);
      }
    }
  });
});