define("bet-ember/helpers/local-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.secToTime = secToTime;
  function secToTime([obj]) {
    if (obj) {
      var hour = pad(obj.hour || "00", 2);
      var minute = pad(obj.minute || "00", 2);
      var second = pad(obj.second || "00", 2);
      return hour + ":" + minute; // + ":" + second;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(secToTime);
});