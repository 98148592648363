define("bet-ember/components/obj-autocomplete-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    on,
    run,
    observer,
    computed,
    uuid
  } = Ember;
  /// Attributes ->
  /// endPoint -
  /// modelName - (otional)
  /// displayProperty - property to get from results to display
  /// valueProperty - property to get from results to set as value
  /// queryBy - to query results by field (default is 'searchText'), can be more than one, "," - csv
  /// searchText - text to search by, filled in the input
  /// minChar = 2
  /// pageNumber = 1
  /// pageSize = 10
  /// onChange -> fire action "onChange" with value the selectedValue
  var _default = _exports.default = Ember.Component.extend(Ember.Evented, {
    minChar: 2,
    pageNumber: 1,
    pageSize: 10,
    fireEvents(obj) {
      if (this.get('onChange')) {
        this.get('onChange')(obj);
      }
    },
    validate(value) {
      run.scheduleOnce('afterRender', this, function () {
        this.utils.validateCustomSelectComponent.call(this, value);
        if (value != null && $(this.element).find('input.validation-ignore')) {
          $(this.element).find('input.validation-ignore').focus();
        }
        if ($(this.element).find('.dummy-input')) {
          $(this.element).find('.dummy-input').trigger('change-internal');
        }
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      $(document).off('click', 'keydown', 'keyup');
      this.setProperties({
        hints: [],
        stop: true
      });
    },
    didInsertElementHook: on('didInsertElement', function () {
      var _this = this;
      //detect click outside of the component to close hints (if open). Acount for if popup is open
      $(document).on('click', event => {
        if ($(_this.element) && (!$(event.target).closest('#' + $(_this.element).attr('id')).length && !$('.popup').length || !$(event.target).closest('.popup')) && _this.hints) {
          Ember.run.debounce(this, function () {
            if (this.get('stop')) {
              return;
            }
            var selectedObject = getObject(this.get('selectedObject'));
            if (this.get('selectedObject.id')) {
              this.set('searchText', getProperty(selectedObject, this.get('displayProperty')));
            } else if (selectedObject) {
              try {
                selectedObject.set(this.get('displayProperty'), this.get('searchText'));
                this.set('selectedObject', selectedObject);
              } catch (err) {
                logger(err);
              }
            } else {
              this.set('searchText', null);
            }
          }, 250);
          if (this.get('persistText')) {
            this.set('hints', []);
            this.set('noMatchesFound', null);
            return;
          }
          if (this.get('_valueSelected')) {
            // if value is selected just clear hints, or else clear everything
            this.set('hints', []);
          } else {
            _this.send('clear');
          }
        }
      });
      $("#" + $(_this.element).attr('id')).on('keyup', e => {
        // use keyup to process all keys (except "up" ,"down")
        var code = e.keyCode ? e.keyCode : e.which;
        switch (code) {
          case 40:
            break;
          case 38:
            break;
          case 13:
            if (_this.get('hints') && _this.get('hints').length) {
              _this.enterPressed();
            } else {
              _this.search();
            }
            break;
          default:
            if (this.get('_lastSearchText') !== this.get('searchText')) {
              _this.processKey();
            }
        }
      });
      $("#" + $(_this.element).attr('id')).on('keydown', e => {
        // use keydown for "up" and "down" to acount for long press
        var code = e.keyCode ? e.keyCode : e.which;
        switch (code) {
          case 40:
            _this.move('down');
            break;
          case 38:
            _this.move('up');
            break;
        }
      });
    }),
    move(direction) {
      direction = direction || "up";
      var current = $(this.element).find('li.on-focus');
      var next;
      if (current[0]) {
        next = direction == 'up' ? current.prev() : current.next();
      } else {
        next = $(this.element).find('li').first();
      }
      if (next.hasClass('loading') && $(this.element).find(next)[0] && !this.utils.isMobile()) {
        //$(this.element).find(next)[0].scrollIntoView(false);
        return;
      }
      if (next[0]) {
        $(this.element).find('li.on-focus').removeClass('on-focus');
        $(this.element).find(next).addClass('on-focus');
        if (!this.utils.isMobile()) {
          //  $(this.element).find(next)[0].scrollIntoView(false);
        }
      } else if (current && direction == 'down' && this.get('allowNextPage')) {
        this.set('pageNumber', TryParse(this.get('pageNumber') || 1) + 1);
        this.search();
      } else if (current && direction == 'up') {
        current.removeClass('on-focus');
      }
    },
    enterPressed() {
      var current = $(this.element).find('li.on-focus');
      if (current && current[0]) {
        current.click();
      } else if (this.get('showFind')) {
        this.get('showFind')();
      }
    },
    processKey(invalidateSelected) {
      this.set('_valueSelected', false);
      this.set('_lastSearchText', this.get('searchText'));
      this.set('pageNumber', 1);
      clearTimeout(this.get('searchTimeout'));
      if (!this.get('searchText')) {
        this.set('hints', []);
      } else if (this.get('searchText').length >= this.get('minChar')) {
        var searchTimeout = setTimeout(() => {
          this.search();
        }, 250);
        this.set('searchTimeout', searchTimeout);
      } else {
        this.set('hints', []);
      }
    },
    hintsObserverForScroll: observer('hints.[]', function () {
      // observe hints. if shown attach eventlistener for "scroll". When the end is hit search for more
      if (this.get('hints') && this.get('hints').length) {
        run.scheduleOnce('afterRender', this, function () {
          var _this = this;
          $(this.element).find('ul').on('scroll', function (e) {
            if ($(this) && $(this)[0] && $(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight && _this.get('allowNextPage')) {
              _this.set('pageNumber', TryParse(_this.get('pageNumber') || 1) + 1);
              _this.search();
            }
            return false;
          });
          $(this.element).find('li').hover(function (e) {
            $(_this.element).find('li').removeClass('on-focus');
            $(this).addClass('on-focus');
          }, function (e) {});
        });
      }
    }),
    selectedValueObserver: on('init', observer('selectedObject', function () {
      // used to accept the "selectedValue" from the outside and set it
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('stop')) {
          return;
        }
        var selectedObject = getObject(this.get('selectedObject'));
        // if(selectedObject){
        this.set('searchText', this.get('persistSelected') != false ? getProperty(selectedObject, this.get('displayProperty')) : null);
        // }
        $(this.element).find('.dummy-input').val(getProperty(selectedObject, this.get('valueProperty')));
      });
    })),
    search() {
      var queryParams = this.get('queryParams') || {};
      queryParams.pageNumber = this.get('pageNumber') || 1;
      var queryParamsFields = (this.get('queryBy') || 'searchText').split(',');
      queryParamsFields.forEach(field => {
        queryParams[field] = this.get('searchText');
      });
      if (this.get('addToFilter')) {
        var additionFilters = this.get('addToFilter');
        for (var key in additionFilters) {
          if (additionFilters[key] != null) {
            queryParams[key] = additionFilters[key];
          }
        }
      }
      var promise = this.store.query(this.get('modelName'), {
        queryParams: queryParams,
        endPoint: this.get('endPoint')
      });
      this.set('allowNextPage', false);
      this.set('loadingHints', true);
      this.set('noMatchesFound', false);
      run.scheduleOnce('afterRender', this, function () {
        var loadingLi = $(this.element).find('li.loading')[0];
        if (loadingLi && !this.utils.isMobile()) {
          //loadingLi.scrollIntoView(false);
        }
      });
      var uuid = Ember.uuid();
      this.set('promiseUUID', uuid);
      return promise.then(data => {
        if (uuid !== this.get('promiseUUID')) {
          return;
        }
        if (this.get('pageNumber') && this.get('pageNumber') > 1) {
          var hints = (this.get('hints') || []).toArray();
          this.set('hints', hints.concat(data.toArray()));
        } else {
          this.set('hints', data.toArray());
          // if(data.toArray().length==0){
          //   this.set('noMatchesFound',true);
          // }
        }
        this.set('allowNextPage', data.toArray().length == this.get('pageSize'));
        this.set('loadingHints', false);
      }).catch(err => {
        this.set('hints', []);
        this.set('loadingHints', false);
      });
    },
    updateProperties(selectedObject) {
      selectedObject = getObject(selectedObject);
      var searchText = null;
      if (selectedObject) {
        searchText = this.get('persistSelected') != false ? getProperty(selectedObject, this.get('displayProperty')) : null;
      }
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.setProperties({
          selectedObject: selectedObject,
          searchText: searchText,
          hints: []
        });
        if (this.attrs && this.attrs.selectedObject) {
          this.attrs.selectedObject.update(selectedObject);
        }
        this.set('_valueSelected', true);
        this.validate(getProperty(selectedObject, this.get('valueProperty')));
        this.fireEvents(selectedObject);
      });
    },
    actions: {
      clear() {
        var selectedObject = null;
        if (this.get('createNewOnClear') === true) {
          selectedObject = this.store.createRecord(this.get('modelName'));
        }
        this.setProperties({
          selectedObject: selectedObject,
          selectedValue: null,
          noMatchesFound: null,
          hints: [],
          typing: false,
          searchText: null,
          pageNumber: 1
        });
        $(this.element).find('.dummy-input').val("");
      },
      select(selectedObject) {
        this.set('hints', []);
        this.set('papgeNumber', 1);
        this.set('typing', false);
        this.updateProperties(selectedObject);
      }
    }
  });
});