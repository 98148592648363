define("bet-ember/initializers/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'router',
    initialize: function () {
      let app = arguments[1] || arguments[0];
      app.inject('component', 'router', 'router:main');
      app.inject('mixin', 'router', 'router:main');
    }
  };
});