define("bet-ember/components/tabs-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    computed,
    on,
    run
  } = Ember;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tab-panel'],
    tabSelector: '.nav.nav-tabs .nav-tab-name',
    tabNamesSplited: computed('tabNames', 'intl.locale', function () {
      var tabNames = this.get('tabNames') || [],
        _this = this;
      if (this.get('translate') !== false) {
        tabNames = tabNames.map(function (item) {
          return {
            translated: _this.intl.t(item),
            original: item
          };
        });
      } else {
        tabNames = tabNames.map(function (item) {
          return {
            translated: item,
            original: item
          };
        });
      }
      var tab = this.get('selectedTab') || (tabNames[0] ? tabNames[0].original : null);
      run.scheduleOnce('afterRender', this, function () {
        this.set('selectedTab', tab);
      });
      // this.toggleTabs(tab);
      return tabNames;
    }),
    //
    // onComponentInit: on('init',function () {
    //   run.scheduleOnce('afterRender', this, function () {
    //     $($(this.element).find(this.get('tabSelector'))[0]).addClass('active');
    //     var tabNames = this.get('tabNamesSplited');
    //     this.toggleTabs(tabNames[0].original);
    //   });
    // }),
    //
    // toggleTabs(tabName){
    //   run.scheduleOnce('afterRender', this, function () {
    //     $(this.element).find(this.get('tabSelector')).removeClass('active');
    //     $($(this.element).find(this.get('tabSelector')+"."+tabName)).addClass('active');
    //     var tabsCount = $(this.element).find(this.get('tabSelector')).length;
    //     this.set('selectedTab',tabName);
    //   });
    // },

    actions: {
      toggleTab(tabName) {
        this.set('selectedTab', tabName);
        if (this.get('onTabChanged')) {
          this.get('onTabChanged')();
        }
      }
    }
  });
});