define("bet-ember/helpers/sec-to-time-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.secToTime = secToTime;
  function secToTime([sec, reset]) {
    var date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setMilliseconds(0);
    var days = parseInt(sec / (60 * 60 * 24));
    date.setSeconds(sec);
    var time = date.toString().substr(16, 8);
    var h = parseInt(time.substr(0, 2));
    var m = parseInt(time.substr(3, 2));
    var s = parseInt(time.substr(6, 2));
    var str = "";
    if (days) {
      str += days;
      str += "d ";
    }
    if (h) {
      str += pad(h, 2) + ":";
    } else {
      str += pad(0, 2) + ":";
    }
    if (m) {
      str += pad(m, 2) + ":";
    } else {
      str += pad(0, 2) + ":";
    }
    if (s) {
      str += pad(s, 2);
    } else {
      str += pad(0, 2);
    }
    return str;
  }
  var _default = _exports.default = Ember.Helper.extend({
    intl: Ember.inject.service('intl'),
    compute: secToTime,
    intlLocaleChange: Ember.observer('intl.selected', function () {
      this.recompute();
    })
  });
});