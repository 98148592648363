define("bet-ember/helpers/get-elem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getElem = getElem;
  function getElem([arr, i, j]) {
    if (j != null && arr != null && arr[i] != null) {
      return arr[i][j];
    } else if (arr != null) {
      return arr[i];
    }
    return null;
  }
  var _default = _exports.default = Ember.Helper.helper(getElem);
});