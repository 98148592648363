define("bet-ember/routes/home/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.MainRoute.extend({
    model() {
      return this.store.findAll('setting').then(p => {
        return (p || []).toArray();
      });
    }
  });
});