define("bet-ember/templates/components/actions-grid-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3Uqa/qFY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"dropdown skin-context dropdown-target \",[28,\"if\",[[24,[\"fixedPosition\"]],\"context-fixed\"],null],\" \"]]],[8],[7,\"span\",true],[10,\"class\",\"dropdown-toggle\"],[10,\"data-toggle\",\"dropdown\"],[8],[7,\"span\",true],[10,\"class\",\"ficon ficon-cog2\"],[8],[9],[9],[0,\"\\n    \"],[7,\"ul\",true],[10,\"class\",\"dropdown-menu dropdown-menu-right\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/actions-grid-component.hbs"
    }
  });
});