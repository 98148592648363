define("bet-ember/helpers/order-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.orderBy = orderBy;
  function orderBy([arr, key, refresh, desc]) {
    arr = (arr || []).toArray();
    if (!key) {
      return arr;
    }
    return arr.sortByNumberKey(key, desc);
  }
  var _default = _exports.default = Ember.Helper.helper(orderBy);
});