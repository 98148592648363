define("bet-ember/helpers/start-bd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.now = now;
  function now() {
    var date = new Date();
    date.setFullYear(1900);
    return date;
  }
  var _default = _exports.default = Ember.Helper.helper(now);
});