define("bet-ember/helpers/xor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.xor = xor;
  function xor([val1, val2]) {
    return val1 ^ val2;
  }
  var _default = _exports.default = Ember.Helper.helper(xor);
});