define("bet-ember/helpers/null-coalescing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.nullCoalescing = nullCoalescing;
  function nullCoalescing([val1, val2]) {
    return val1 != null ? val1 : val2;
  }
  var _default = _exports.default = Ember.Helper.helper(nullCoalescing);
});