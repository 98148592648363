define("bet-ember/services/custom-ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    intl: Ember.inject.service('intl'),
    alert: Ember.inject.service('alert'),
    loader: Ember.inject.service('loader'),
    router: Ember.inject.service('router'),
    getQueryString: function (queryParams) {
      if (queryParams == null) {
        return '';
      }
      let queryString = '?';
      for (var property in queryParams) {
        if (queryParams.hasOwnProperty(property) && queryParams[property] != null) {
          queryString += property + "=" + queryParams[property] + "&";
        }
      }
      return queryString.slice(0, -1);
    },
    toSimpleObject: function (data) {
      if (data && data.toArray) {
        var tempData = [];
        data = data.toArray();
        if (data[0] && data[0].serialize) {
          for (var i = 0; i < data.length; i++) {
            tempData.push(data[i].serialize({
              includeId: true
            }));
          }
          data = tempData;
        }
      } else if (data && data.serialize) {
        data = data.serialize({
          includeId: true
        });
      }
      return data;
    },
    unpack: function (payload) {
      var meta, data;
      if (payload.data.results && Array.isArray(payload.data.results)) {
        meta = payload;
        data = payload.data.results;
        delete meta.data.results;
      } else {
        meta = payload;
        data = payload.data;
        delete meta.data;
      }
      return {
        data,
        meta
      };
    },
    removeNulls(obj) {
      var isArray = obj instanceof Array;
      for (var k in obj) {
        if (obj[k] === null) {
          if (isArray) {
            obj.splice(k, 1);
          } else {
            delete obj[k];
          }
        } else if (typeof obj[k] == "object") {
          if (obj[k] instanceof Array && obj[k].length === 0) {
            delete obj[k];
            continue;
          }
          this.removeNulls(obj[k]);
        }
      }
    },
    call: function (opt) {
      opt = opt || {};
      opt.endPoint = opt.endPoint || '';
      var url = opt.url || Ember.apiUrl;
      var _this = this;
      var data = opt.data;
      var headers = JSON.parse(JSON.stringify(Ember.headers));
      var processData = opt.processData != null ? opt.processData : true;
      if (opt.headers) {
        for (var prop in opt.headers) {
          headers[prop] = opt.headers[prop];
        }
      }
      if (processData == true) {
        if (data && data.toArray) {
          var tempData = [];
          data = data.toArray();
          if (data[0] && data[0].serialize) {
            for (var i = 0; i < data.length; i++) {
              tempData.push(data[i].serialize({
                includeId: true
              }));
            }
            data = tempData;
          }
        } else if (data && data.serialize) {
          data = data.serialize({
            includeId: true
          });
        }
        if (data && typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }
      try {
        delete opt.queryParams.isSet;
      } catch (ex) {}
      ;
      var ajaxOpt = {
        url: url + opt.endPoint + _this.getQueryString(opt.queryParams),
        type: opt.type || "GET",
        processData: processData,
        data: data,
        headers: headers
      };
      if (opt.beforeSend) {
        ajaxOpt.beforeSend = opt.beforeSend;
      }
      if (opt.dataType) {
        ajaxOpt.dataType = opt.dataType;
      }
      if (opt.endPoint !== "webClient/version") {
        $(document).trigger('custom-ajax-request');
      }
      var ignore401 = opt.ignore401 || false;
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        $.ajax(ajaxOpt).then(function (data) {
          if (data.statusCode === "SUCCESS") {
            Ember.run(null, resolve, data);
          } else if (data.statusCode == "FAILURE" && data.errorCode == "ERROR_INSUFFICIENT_PERMISSIONS") {
            _this.get('alert').show({
              message: _this.get('intl').t('ERROR_INSUFFICIENT_PERMISSIONS'),
              type: 'danger'
            });
            Ember.run(null, reject, data);
          } else {
            Ember.run(null, reject, data);
          }
        }, function (jqXHR) {
          var status = null;
          if (jqXHR && jqXHR.status) {
            status = jqXHR.status;
          } else if (jqXHR && jqXHR.errors && jqXHR.errors[0] && jqXHR.errors[0].status) {
            status = jqXHR.errors[0].status;
          }
          if (!ignore401 && status == 401) {
            // try{
            //   _this.get('security').logout(true);
            // }catch(err){
            //   logger(err);
            // }
            try {
              setTimeout(() => {
                _this.get('router').transitionTo('logout');
              }, 1000);
            } catch (err) {
              logger(err);
            }
          } else {
            if (status == 403) {
              _this.get('alert').show({
                message: _this.get('intl').t('ERROR_INSUFFICIENT_PERMISSIONS'),
                type: 'danger'
              });
            }
          }
          jqXHR.then = null; // tame jQuery's ill mannered promises
          Ember.run(null, reject, jqXHR);
        });
      });
      if (Ember.disableLoader !== true) {
        this.get('loader').processPromise(promise);
      }
      return promise;
    }
  });
});