define("bet-ember/components/summernote-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['summernote-custom-component'],
    componentId: Ember.computed(function () {
      return Ember.uuid();
    }),
    onInitComp: Ember.on('didInsertElement', function () {
      // $('#' + this.get('componentId')).on('summernote.init', (we, contents, $editable)=>{
      //   contents.editable.html(this.get('value'));
      // });
      $('#' + this.get('componentId')).summernote({
        toolbar: false,
        airMode: this.get('airMode') == true
      });
      $('#' + this.get('componentId')).summernote('code', this.get('value'));
      $('#' + this.get('componentId')).on('summernote.change', (we, contents, $editable) => {
        this.set('internalChange', true);
        this.set('value', contents);
        this.set('internalChange', false);
      });
      $('#' + this.get('componentId')).on('summernote.keydown', (we, e) => {
        var t = e.currentTarget.innerText;
        var key = e.which || e.keyCode || e.charCode;
        if (key != 8 && this.get('maxLength') != null && t.length >= this.get('maxLength')) {
          e.preventDefault();
          return false;
        }
        if (e && e.keyCode == 13 && e.shiftKey != true && this.get('onEnter')) {
          this.get('onEnter')(this.get('value'));
        }
      });
      $('#' + this.get('componentId')).on('summernote.paste', (we, e) => {
        try {
          var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
          e.preventDefault();
          var div = $('<div />');
          div.append(bufferText);
          div.find('*').removeAttr('style');
          setTimeout(function () {
            document.execCommand('insertHtml', false, div.html());
          }, 10);
        } catch (err) {
          logger('summernoteError:');
          logger(err);
        }
      });
    }),
    valueObserver: Ember.observer('value', function () {
      if (this.get('internalChange') != true) {
        if (this.get('value')) {
          $('#' + this.get('componentId')).summernote('code', this.get('value'));
        } else {
          $('#' + this.get('componentId')).summernote('reset');
          Ember.run.scheduleOnce('afterRender', this, function () {
            setTimeout(function () {
              $('.note-editable').html('');
            }, 250);
          });
        }
      }
    }),
    disabledObserver: Ember.on('init', Ember.observer('disabled', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('disabled')) {
          $('#' + this.get('componentId')).summernote('disable');
        } else {
          $('#' + this.get('componentId')).summernote('enable');
        }
      });
    })),
    willDestroyElement() {
      this._super(...arguments);
      $('#' + this.get('componentId')).summernote('destroy');
    }
  });
});