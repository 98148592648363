define("bet-ember/helpers/highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fixed = fixed;
  function fixed([text, match]) {
    text = text || "";
    match = match || "";
    match = match.trim();
    var matches = [match];
    if (match.indexOf(' ') >= 0) {
      matches = match.split(' ');
    }
    var finalMatch;
    matches.forEach(match => {
      var regex = new RegExp(match, 'ig');
      if (finalMatch) {
        finalMatch = new RegExp(finalMatch.source + "|" + regex.source);
      } else {
        finalMatch = regex;
      }
    });
    if (finalMatch) {
      //&& text.toLowerCase().indexOf(finalMatch.toLowerCase())>=0
      text = text.replace(finalMatch, '<span class="text-match">$&</span>');
    }
    return text;
  }
  var _default = _exports.default = Ember.Helper.helper(fixed);
});