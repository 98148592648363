define("bet-ember/helpers/add-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.andDay = andDay;
  _exports.default = void 0;
  function andDay([date, days]) {
    date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
  }
  var _default = _exports.default = Ember.Helper.helper(andDay);
});