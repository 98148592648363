define("bet-ember/models/bet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    marketId: _emberData.default.attr('string'),
    marketName: _emberData.default.attr('string'),
    eventName: _emberData.default.attr('string'),
    marketStartTime: _emberData.default.attr('number'),
    runnerName: _emberData.default.attr('string'),
    betId: _emberData.default.attr('string'),
    selectionId: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    orderStatus: _emberData.default.attr('string'),
    betSide: _emberData.default.attr('string'),
    betPrice: _emberData.default.attr('number'),
    betSize: _emberData.default.attr('number'),
    averagePriceMatched: _emberData.default.attr('number'),
    sizeMatched: _emberData.default.attr('number'),
    placedDate: _emberData.default.attr('number'),
    runnersCount: _emberData.default.attr('number'),
    runnersToBePlacedCount: _emberData.default.attr('number'),
    fromDate: _emberData.default.attr('number'),
    winSize: _emberData.default.attr('number'),
    matrix: _emberData.default.belongsTo('matrix'),
    autoBet: _emberData.default.belongsTo('autoBet')
  });
});