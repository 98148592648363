define("bet-ember/templates/components/main-layout-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iFSKbi/d",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"menus/top-menu-component\"],false],[0,\"\\n\"],[7,\"main\",true],[10,\"class\",\"app-middle\"],[8],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"main-content layout-wrap\"],[8],[0,\"\\n    \"],[7,\"header\",true],[10,\"class\",\"page-index main-layout-app-info-header\"],[8],[0,\"\\n      \"],[7,\"link\",true],[10,\"rel\",\"dns-prefetch\"],[10,\"href\",\"//load.cm2w.net\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"f-box box-pack-center\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"box-item screen-title-wrap\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"screen-title\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"f-box vcbox\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"id\",\"back-btn-placeholder\"],[8],[0,\"\\n              \"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"action\"],[\"back-btn\",[28,\"action\",[[23,0,[]],\"goBack\"],null]]]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"box-item\"],[10,\"id\",\"header-title-place\"],[8],[0,\"\\n\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"page-actions\"],[10,\"id\",\"header-buttons-place\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"storage\",\"inTransition\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"utils/dot-loader\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"section\",true],[10,\"class\",\"page-content\"],[8],[1,[22,\"outlet\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/main-layout-component.hbs"
    }
  });
});