define("bet-ember/helpers/not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.or = or;
  function or([val]) {
    return !val;
  }
  var _default = _exports.default = Ember.Helper.helper(or);
});