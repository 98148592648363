define("bet-ember/helpers/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pagination = pagination;
  function pagination([items, pageSize, pageNumber]) {
    var arr = [];
    pageSize = pageSize || 10;
    pageNumber = (pageNumber || 1) - 1;
    var offset = pageSize * pageNumber;
    if (items) {
      for (var i = offset; i < offset + pageSize && i < items.length; i++) {
        arr.push(items[i]);
      }
    }
    return arr;
  }
  var _default = _exports.default = Ember.Helper.helper(pagination);
});