define("bet-ember/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function removeNulls(obj) {
    var isArray = obj instanceof Array;
    for (var k in obj) {
      if (obj[k] === null) {
        if (isArray) {
          obj.splice(k, 1);
        } else {
          // delete obj[k];
        }
      } else if (typeof obj[k] == "object") {
        if (obj[k] instanceof Array && obj[k].length === 0) {
          delete obj[k];
          continue;
        }
        removeNulls(obj[k]);
      }
    }
  }
  function removeMainObj(obj, propName, mainId) {
    var isArray = obj instanceof Array;
    for (var k in obj) {
      if (typeof obj[k] == "object") {
        if (k == propName && obj[k] && obj[k].id == mainId) {
          delete obj[k];
          continue;
        }
        removeMainObj(obj[k], propName, mainId);
      }
    }
  }
  let AppSerializer = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeDeleteRecordResponse: function (store, primaryModelClass, payload, id, requestType) {
      arguments[2] = null;
      return this._super(...arguments);
    },
    serializeId(snapshot, json, primaryKey) {
      var id = snapshot.id;
      json[primaryKey] = TryParse(id);
    },
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.statusCode === "SUCCESS" && payload.data) {
        var meta;
        if (payload.data.results && Array.isArray(payload.data.results)) {
          meta = payload;
          payload = payload.data.results;
          delete meta.data.results;
          payload.meta = meta;
        } else {
          meta = payload;
          payload = payload.data;
          delete meta.data;
          payload.meta = meta;
        }
        removeNulls(payload);
        if (requestType == "createRecord") {
          removeMainObj(payload, Ember.String.camelize(primaryModelClass.modelName), payload.id);
        }
        arguments[2] = payload;
      }
      return this._super(...arguments);
    },
    serialize(snapshot, options) {
      var json = this._super(...arguments);
      json.id = TryParse(snapshot.id);
      return json;
    },
    serializeBelongsTo: function (snapshot, json, relationship) {
      var key = relationship.key;
      var record = snapshot.record.get(key);
      var serializer = this.store.serializerFor(snapshot.modelName);
      if (serializer && serializer.attrs && serializer.attrs[key]) {
        if (serializer.attrs[key].serialize == "ids") {
          var id = TryParse(getProperty(record, "id"));
          if (id) {
            var obj = {};
            obj["id"] = id;
            json[key] = obj;
          }
        } else if (serializer.attrs[key].serialize == "simple" && serializer.attrs[key].modelName != null) {
          var modelName = serializer.attrs[key].modelName;
          var model = this.store.modelFor(modelName);
          if (model && model.fields) {
            var obj = {};
            var id = TryParse(getProperty(record, "id"));
            obj["id"] = id;
            (Array.from(model.fields) || []).forEach(field => {
              if (field[1] == "attribute") {
                obj[field[0]] = getProperty(getObject(record), field[0]);
              }
            });
            json[key] = obj;
          }
        } else {
          this._super(snapshot, json, relationship);
        }
      }
    },
    serializeHasMany: function (snapshot, json, relationship) {
      var key = relationship.key;
      var serializer = this.store.serializerFor(snapshot.modelName);
      if (serializer && serializer.attrs && serializer.attrs[key]) {
        if (serializer.attrs[key].serialize == "ids") {
          json[key] = [];
          var hasManyRecords = snapshot.record.get(key) || [];
          hasManyRecords.forEach(item => {
            var id = TryParse(getProperty(item, "id"));
            if (id) {
              var obj = {};
              obj["id"] = id;
              json[key].push(obj);
            }
          });
        } else if (serializer.attrs[key].serialize == "onlyNew") {
          json[key] = [];
          var hasManyRecords = snapshot.record.get(key) || [];
          hasManyRecords.forEach(item => {
            var id = TryParse(getProperty(item, "id"));
            if (id == null && item.serialize) {
              json[key].push(item.serialize());
            }
          });
        } else if (serializer.attrs[key].serialize == "simple" && serializer.attrs[key].modelName != null) {
          json[key] = [];
          var hasManyRecords = snapshot.record.get(key) || [];
          var modelName = serializer.attrs[key].modelName;
          var model = this.store.modelFor(modelName);
          if (model && model.fields) {
            hasManyRecords.forEach(item => {
              var obj = {};
              var id = TryParse(getProperty(item, "id"));
              obj["id"] = id;
              (Array.from(model.fields) || []).forEach(field => {
                if (field[1] == "attribute") {
                  obj[field[0]] = getProperty(getObject(item), field[0]);
                }
              });
              json[key].push(obj);
            });
          }
        } else {
          this._super(snapshot, json, relationship);
        }
      }
    }
  });
  var _default = _exports.default = AppSerializer;
});