define("bet-ember/components/delete-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    keepPopupOnTopSettings: {
      selectorId: "delete_popup_internal",
      property: "isShowingModal"
    },
    tagName: 'span',
    actions: {
      showModal(val) {
        this.set('isShowingModal', true);
      },
      closeModal() {
        this.set('isShowingModal', false);
      },
      delete(val) {
        this.get('deleteAction')(val);
        this.set('isShowingModal', false);
      }
    }
  });
});