define("bet-ember/components/custom-date-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    recieveAttributes: Ember.on('didReceiveAttrs', function () {
      var value = this.get('value');
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      };
      this.set('lock', true);
      if (value != null) {
        this.set('internalValue', this.getDateForInput(value));
      } else {
        this.set('internalValue', null);
      }
      this.set('lock', false);
    }),
    internalValueObserver: Ember.observer('internalValue', function () {
      if (this.get('lock')) {
        return;
      }
      this.set('lock', true);
      this.set('value', this.get('internalValue') ? getDateOnly(this.get('internalValue')) : null);
      this.set('lock', false);
    }),
    minDate: Ember.computed('startDate', function () {
      if (this.get('startDate')) {
        return this.getDateForInput(this.get('startDate'));
      } else {
        return null;
      }
    }),
    maxDate: Ember.computed('endDate', function () {
      if (this.get('endDate')) {
        return this.getDateForInput(this.get('endDate'));
      } else {
        return null;
      }
    }),
    componentId: Ember.computed(function () {
      return Ember.uuid();
    }),
    getDateForInput(date) {
      date = getDateOnly(date);
      return date.getFullYear() + "-" + pad(date.getMonth() + 1, 2) + "-" + pad(date.getDate(), 2);
    },
    actions: {
      onKeyDown() {
        return false;
      },
      onChange(val) {
        var value = val.srcElement.value;
        this.set('value', value ? getDateOnly(value) : null);
      }
    }
  });
});