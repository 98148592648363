define("bet-ember/helpers/get-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getDate = getDate;
  function getDate([date]) {
    if (date) {
      return new Date(date);
    } else {
      return new Date();
    }
  }
  var _default = _exports.default = Ember.Helper.helper(getDate);
});