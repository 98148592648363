define("bet-ember/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel: function (transition) {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('home.events');
        return;
      }
    }
  });
});