define("bet-ember/components/settings/main-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    onCompInit: Ember.on('init', function () {
      this.set('autoBettingDisabled', TryParse(this.getSetting('autoBettingDisabled')));
    }),
    getSetting(name) {
      var setting = this.get('settings').find(p => p.get('name') == name);
      if (setting) {
        return setting.get('value');
      }
      return null;
    },
    setSetting(name, value) {
      var setting = this.get('settings').find(p => p.get('name') == name);
      if (setting == null) {
        setting = this.store.createRecord('setting', {
          name: name
        });
        this.get('settings').pushObject(setting);
      }
      setting.set('value', value);
      return setting;
    },
    actions: {
      save() {
        this.alert.clearAll();
        var formIsValid = this.formIsValid({
          selector: ".save-form"
        });
        if (!formIsValid) {
          return;
        }
        this.setSetting('autoBettingDisabled', this.get('autoBettingDisabled'));
        var primises = [];
        this.get('settings').forEach((setting, i) => {
          primises.push(setting.save());
        });
        return Ember.RSVP.allSettled(primises).then(data => {
          this.store.removeAllNew();
          this.alert.show({
            message: this.intl.t('saved')
          });
          this.onCompInit();
          return data;
        }).catch(err => {
          logger(err);
          this.alert.displayServerErrors(err);
        });
      },
      reload() {
        this.onCompInit();
      }
    }
  });
});