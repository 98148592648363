define("bet-ember/adapters/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector;
  inflector.irregular('tech', 'techs');
  let ApplicationAdapter = _emberData.default.JSONAPIAdapter.extend(Ember.Evented, {
    customAjax: Ember.inject.service('custom-ajax'),
    storeService: Ember.inject.service('store'),
    host: Ember.host,
    namespace: Ember.namespace,
    headers: Ember.headers,
    prepare(snapshot, type, includeId) {
      var endPoint = '';
      var queryParams = null;
      var ignore401 = false;
      type = type || "GET";
      if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('endPoint')) {
        endPoint = snapshot.adapterOptions.endPoint;
      } else {
        endPoint = inflector.pluralize(snapshot.modelName).camelize();
      }
      if (snapshot.adapterOptions && snapshot.adapterOptions.includeId != null) {
        if (snapshot.adapterOptions.includeId) {
          endPoint += "/" + Ember.get(snapshot, 'id');
        }
      } else if (includeId) {
        endPoint += "/" + Ember.get(snapshot, 'id');
      }
      if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('type')) {
        type = snapshot.adapterOptions.type;
      }
      if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('queryParams')) {
        queryParams = snapshot.adapterOptions.queryParams;
      }
      if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('ignore401')) {
        ignore401 = snapshot.adapterOptions.ignore401;
      }
      return {
        endPoint: endPoint,
        queryParams: queryParams,
        ignore401: ignore401 || false,
        type: type
      };
    },
    query: function (store, type, query) {
      query = query || {};
      return this.get('customAjax').call({
        url: this.get('url'),
        type: query.type || "GET",
        endPoint: query.endPoint,
        ignore401: query.ignore401 || false,
        queryParams: query.queryParams || query.filter,
        data: query.data || null
      });
    },
    queryRecord: function (store, type, query) {
      return this.get('query').call(this, store, type, query);
    },
    createRecord: function (store, type, snapshot) {
      var data = this.serialize(snapshot, {
        includeId: true
      });
      var {
        endPoint,
        queryParams,
        type,
        ignore401
      } = this.prepare(snapshot, "POST");
      return this.get('customAjax').call({
        type: type,
        endPoint: endPoint,
        ignore401: ignore401,
        data: JSON.stringify(data),
        queryParams: queryParams
      });
    },
    updateRecord: function (store, type, snapshot) {
      var data = this.serialize(snapshot, {
        includeId: true
      });
      var {
        endPoint,
        queryParams,
        type,
        ignore401
      } = this.prepare(snapshot, "PUT", true);
      return this.get('customAjax').call({
        type: type,
        endPoint: endPoint,
        ignore401: ignore401,
        data: JSON.stringify(data),
        queryParams: queryParams
      });
    },
    findRecord: function (store, type, id, snapshot) {
      var {
        endPoint,
        queryParams,
        type,
        ignore401
      } = this.prepare(snapshot, "GET", true);
      return this.get('customAjax').call({
        type: type,
        endPoint: endPoint,
        ignore401: ignore401,
        queryParams: queryParams
      });
    },
    deleteRecord: function (store, type, snapshot) {
      var {
        endPoint,
        queryParams,
        type,
        ignore401
      } = this.prepare(snapshot, "DELETE", true);
      return this.get('customAjax').call({
        type: type,
        endPoint: endPoint,
        ignore401: ignore401,
        queryParams: queryParams
      });
    },
    ajaxOptions: function (url, type, hash) {
      if (type === 'PATCH') {
        type = 'PUT';
      }
      return this._super(url, type, hash);
    }
  });
  var _default = _exports.default = ApplicationAdapter;
});