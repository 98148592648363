define("bet-ember/components/auto-bets/search-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.FilterCustomComponent.extend({
    filterFields: [
    //   {
    //     field:"fromDate",
    //     default:getDateOnly(),
    //     transform:(val)=>{ return getDateOnly(val).getTime(); }
    //   },
    //   {
    //     field:"thruDate",
    //     transform:(val)=>{ return getDateOnly(val).getTime(); }
    //   },
    //   // "bookingStatusId","profileSearchText","profileTypeId"
    'orderBy', 'sortBy'],
    modelName: 'auto-bet',
    endPoint: 'autoBets',
    sortBy: 'startTime',
    orderBy: 'ASC',
    actions: {
      deleteAll() {
        this.customAjax.call({
          type: "DELETE",
          endPoint: "autoBets/all"
        }).then(payload => {
          this.search();
        }).catch(err => {
          logger(err);
          this.search();
        });
      },
      delete(model) {
        return model.destroyRecord().then(() => {
          this.alert.show({
            message: this.intl.t('deleted')
          });
          this.get('items').removeObject(model);
        }).catch(err => {
          this.alert.displayServerErrors(err);
          model.rollback();
        });
      }
    }
  });
});