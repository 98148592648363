define("bet-ember/mixins/generic-service-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    storage: Ember.inject.service('storage'),
    store: Ember.inject.service('store'),
    customAjax: Ember.inject.service('custom-ajax'),
    useChecksum: true,
    doUnloadBeforeSetup: false,
    setup: function () {
      if (this.get('doUnloadBeforeSetup')) {
        this.unloadBeforeSetup();
      }
      if (this.get('beforeSetup')) {
        this.get('beforeSetup')();
      }
      if (this.get('useChecksum') && !this.get('doUnloadBeforeSetup')) {
        return this.loadChecksum();
      } else {
        return this.loadRequest();
      }
    },
    unloadBeforeSetup() {
      try {
        this.get('store').unloadAll(this.get('modelName'));
      } catch (err) {
        logger(err);
      }
      try {
        var records = this.store.peekAll(this.get('modelName'));
        records.forEach(rec => {
          rec.unloadRecord();
        });
      } catch (err) {
        logger(err);
      }
    },
    queryParams: Ember.computed('refs', 'sortBy', 'orderBy', function () {
      var queryParams = {
        pageNumber: 1,
        pageSize: this.constants.MAX_INT
      };
      if (this.get('refs')) {
        queryParams.refs = this.get('refs');
      }
      if (this.get('sortBy')) {
        queryParams.sortBy = this.get('sortBy');
      }
      if (this.get('orderBy')) {
        queryParams.orderBy = this.get('orderBy');
      }
      return queryParams;
    }),
    loadChecksum() {
      var checksum = this.get('storage').getItem(this.get('modelName') + '-checksum');
      var chashedData = TryParse(this.get('storage').getItem(this.get('modelName') + '-data')) || [];
      return this.get('customAjax').call({
        endPoint: this.get('endPoint') + '/checksum'
      }).catch(err => {
        logger(err);
        return null;
      }).then(payload => {
        payload = payload || {};
        var currentChecksum = payload.data;
        if (currentChecksum == null || checksum != currentChecksum || chashedData == null || chashedData.length == 0) {
          this.get('storage').setItem(this.get('modelName') + '-checksum', currentChecksum);
          return this.loadRequest();
        } else {
          try {
            chashedData.forEach(item => {
              var data = this.store.serializerFor(this.get('modelName')).normalize(this.store.modelFor(this.get('modelName')), item);
              this.get('store').push(data);
            });
          } catch (err) {
            logger(err);
          }
          return Ember.RSVP.Promise.resolve(chashedData);
        }
      });
    },
    loadRequest() {
      return this.get('store').query(this.get('modelName'), {
        endPoint: this.get('endPoint'),
        queryParams: this.get('queryParams')
      }).then(data => {
        data = (data || []).toArray();
        this.get('storage').setItem(this.get('modelName') + '-data', JSON.stringify(data.map(item => item.serialize())));
        this.set('refresh', new Date().getTime());
        return data;
      }).catch(err => {
        logger('generic-service-mixin for: ' + this.get('modelName'));
        logger(err);
        this.set('refresh', new Date().getTime());
        return [];
      });
    },
    reload() {
      return this.loadRequest();
    },
    refreshComputed() {
      this.set('refresh', new Date().getTime());
    },
    getAll() {
      var finalArray = [];
      try {
        finalArray = (this.get('store').peekAll(this.get('modelName')) || []).toArray();
      } catch (err) {
        try {
          var arr = this.get('store').peekAll(this.get('modelName'));
          if (arr && arr.get && arr.get('length') || arr.length) {
            finalArray = arr;
          } else {
            finalArray = [];
          }
        } catch (err) {
          logger('general-service-minix: ' + err);
          logger(err);
          finalArray = [];
        }
      }
      if (this.get('sortBy')) {
        return finalArray.sortBy(this.get('sortBy'));
      } else {
        return finalArray;
      }
    },
    all: Ember.computed('refresh', function () {
      try {
        return this.getAll();
      } catch (err) {
        logger('general-service-minix: ' + err);
        logger(err);
        return [];
      }
    }),
    getById: function (id) {
      try {
        return this.getAll().find(item => {
          return TryParse(getProperty(item, "id")) == TryParse(id);
        }) || null;
      } catch (err) {
        logger('general-service-minix: ' + err);
        logger(err);
        return null;
      }
    },
    getByCode: function (code) {
      try {
        return this.getAll().findBy('code', code) || null;
        ;
      } catch (err) {
        logger('general-service-minix: ' + err);
        logger(err);
        return null;
      }
    },
    getBySysCode: function (sysCode) {
      try {
        return this.getAll().findBy('sysCode', sysCode) || null;
        ;
      } catch (err) {
        logger('general-service-minix: ' + err);
        logger(err);
        return [];
      }
    },
    getByName: function (name) {
      try {
        return this.getAll().findBy('name', name) || null;
        ;
      } catch (err) {
        logger('general-service-minix: ' + err);
        logger(err);
        return null;
      }
    },
    getByBarcode: function (barcode) {
      try {
        return this.getAll().findBy('barcode', barcode) || null;
        ;
      } catch (err) {
        logger('general-service-minix: ' + err);
        logger(err);
        return null;
      }
    }
  });
});