define("bet-ember/helpers/get-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getBy = getBy;
  function getBy([array, key, val, prop]) {
    let res = null;
    if (array == null) {
      return null;
    }
    try {
      val = JSON.parse(val);
    } catch (ex) {}
    if (array.findBy && typeof array.findBy === 'function') {
      res = array.findBy(key, val);
    } else {
      for (let i = 0; i < array.length; i++) {
        if (array[i][key] != null && array[i][key] == val) {
          res = array[i];
        }
      }
    }
    if (res != null) {
      if (prop) {
        if (res.get) {
          res = res.get(prop);
        } else {
          res = res[prop];
        }
      }
    }
    return res;
  }
  var _default = _exports.default = Ember.Helper.helper(getBy);
});