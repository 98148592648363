define("bet-ember/helpers/go-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.goTo = goTo;
  function goTo([link, data]) {
    if (data) {
      this.get('router').transitionTo(link, data);
    } else {
      this.get('router').transitionTo(link);
    }
  }
  var _default = _exports.default = Ember.Helper.extend({
    router: Ember.inject.service('router'),
    compute: goTo
  });
});