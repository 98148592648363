define("bet-ember/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomService.extend({
    store: Ember.inject.service('store'),
    router: Ember.inject.service('router'),
    customAjax: Ember.inject.service('custom-ajax'),
    storage: Ember.inject.service('storage'),
    alert: Ember.inject.service('alert'),
    intl: Ember.inject.service('intl'),
    _data: null,
    setup(ignore401) {
      return this.get('store').queryRecord('user', {
        endPoint: "users/me",
        ignore401: ignore401 || false,
        queryParams: {
          refs: 'user-me'
        }
      }).then(result => {
        this.set('_data', result);
        return result;
      }).catch(err => {
        logger(err);
      });
    },
    reload() {
      return this.setup();
    },
    reloadWithUserDataServices() {
      return this.setup().then(() => {
        var promises = {};
        Ember.customAuthServices.forEach(item => {
          if (this.get(item[0]) && this.get(item[0]).setup) {
            promises[item[0]] = this.get(item[0]).setup();
          }
        });
        return Ember.RSVP.hashSettled(promises);
      });
    },
    remove() {
      this.set('_data', null);
    },
    user: Ember.computed('_data', function () {
      return this.get('_data');
    }),
    selectedModule: Ember.computed('user.selectedModule.id', function () {
      return getObject(this.get('user.selectedModule'));
    }),
    isModuleOwner: Ember.computed('selectedModule.id', 'user.id', function () {
      if (this.get('selectedModule.user.id') == this.get('user.id')) {
        return true;
      }
      return false;
    }),
    id: Ember.computed('_data', function () {
      if (this.get('_data') && this.get('_data').get) {
        return this.get('_data').get('userId');
      } else {
        return null;
      }
    }),
    username: Ember.computed('_data', function () {
      if (this.get('_data') && this.get('_data').get) {
        return this.get('_data').get('username');
      } else {
        return null;
      }
    }),
    getByKey: function (key) {
      if (key === "user") {
        return this.get('_data');
      } else if (this.get('_data') && this.get('_data').get) {
        return this.get('_data').get(key);
      } else {
        return null;
      }
    },
    isAdmin: Ember.computed('user.userRole.code', function () {
      return this.get('user.userRole.code') == "ADMIN";
    }),
    isSuperAdmin: Ember.computed('user.userRole.code', function () {
      return this.get('user.userRole.code') == "SUPER_ADMIN";
    }),
    langObserver: Ember.on('init', Ember.observer('user.language.id', function () {
      if (this.get('user.language.id')) {
        // this.get('intl').setLocale([this.get('user.language.code')+"-"+this.get('user.language.code')])
        this.set('intl.locale', this.get('user.language.code') + "-" + this.get('user.language.code'));
        Ember._langCode = this.get('user.language.code');
      }
    })),
    currency: Ember.computed('user.currency.id', function () {
      return this.get('user.currency');
    }),
    selectedUserModule: Ember.computed('selectedModule.id', 'user.moduleUsers.@each.{id}', function () {
      return this.get('user.moduleUsers').find(p => p.get('module.id') == this.get('selectedModule.id'));
    }),
    hasUserMenuAccess: Ember.computed('selectedModule.id', 'selectedUserModule.userMenuAccess', function () {
      if (this.get('isModuleOwner') || this.get('selectedUserModule.userMenuAccess')) {
        return true;
      }
      return false;
    }),
    hasGroupMenuAccess: Ember.computed('selectedModule.id', 'selectedUserModule.groupMenuAccess', function () {
      if (this.get('isModuleOwner') || this.get('selectedUserModule.groupMenuAccess')) {
        return true;
      }
      return false;
    }),
    hasSettingsMenuAccess: Ember.computed('selectedModule.id', 'selectedUserModule.settingsMenuAccess', function () {
      if (this.get('isModuleOwner') || this.get('selectedUserModule.settingsMenuAccess')) {
        return true;
      }
      return false;
    }),
    permissionsVehicles: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_vehicles') || "";
    }),
    permissionsDocuments: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_documents') || "";
    }),
    permissionsFuelLogs: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_fuelLogs') || "";
    }),
    permissionsReminders: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_reminders') || "";
    }),
    permissionsWaybills: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_waybills') || "";
    }),
    permissionsWorkCards: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_workCards') || "";
    }),
    permissionsEvents: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_events') || "";
    }),
    permissionsExpenses: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_expenses') || "";
    }),
    permissionsTires: Ember.computed('isModuleOwner', 'selectedUserModule.moduleUserAttributes.@each.{id,name,value}', function () {
      if (this.get('isModuleOwner')) {
        return "CRUD";
      }
      return this.getAttributeValue(this.get('selectedUserModule.moduleUserAttributes'), 'permissions_tires') || "";
    }),
    selectModule(module) {
      return this.get('customAjax').call({
        endPoint: "users/selectModule/" + module.get('id'),
        type: "POST"
      }).then(() => {
        return this.reloadWithUserDataServices();
      });
    },
    redirectToHome(transition) {
      if (['home', 'home.index', 'index'].indexOf(transition.targetName) >= 0) {
        this.get('router').transitionTo('home.vehicles');
        // if(this.get('selectedModule.vehicleCount')>0){
        //   this.get('router').transitionTo('home.pending-report');
        // }else{
        //   this.get('router').transitionTo('home.vehicles');
        // }
      }
    },
    viberNotifications() {
      if (this.get('user.viberUUID')) {
        location.href = "viber://pa?chatURI=gara6&context=" + this.get('user.viberUUID'); // + "&text=start";
      } else {
        this.get('customAjax').call({
          endPoint: "users/me/viberUUID"
        }).then(payload => {
          var viberUUID = payload.data;
          location.href = "viber://pa?chatURI=gara6&context=" + viberUUID; //+ "&text=start";
        }).catch(err => {
          this.get('alert').displayServerErrors(err);
        });
      }
    }
  });
});