define("bet-ember/helpers/and", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.and = and;
  _exports.default = void 0;
  function and(params) {
    var res = params[0];
    for (var i = 1; i < params.length; i++) {
      res = res && params[i];
    }
    return res;
  }
  var _default = _exports.default = Ember.Helper.helper(and);
});