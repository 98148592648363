define("bet-ember/helpers/week-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.weekDay = weekDay;
  function weekDay([date]) {
    if (date) {
      return getDayOfWeekName(date);
    }
    return null;
  }
  var _default = _exports.default = Ember.Helper.helper(weekDay);
});