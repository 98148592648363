define("bet-ember/helpers/reminder-class-by-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.remindByDate = remindByDate;
  function remindByDate([remindOnDate, remindDaysBefore]) {
    var curDate = getDateOnly().getTime();
    remindOnDate = getDateOnly(remindOnDate).getTime();
    if (curDate >= remindOnDate) {
      return 'txt-red';
    }
    if (remindDaysBefore) {
      var date = new Date(remindOnDate);
      date.setDate(date.getDate() - remindDaysBefore);
      date = date.getTime();
      if (curDate >= date) {
        return 'txt-yewoll';
      }
    }
    return 'txt-green';
  }
  var _default = _exports.default = Ember.Helper.helper(remindByDate);
});