define("bet-ember/helpers/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains([arr, val]) {
    if (arr) {
      if (Array.isArray(arr)) {
        return arr.contains(val);
      } else if (arr.indexOf(val) >= 0) {
        return true;
      }
    }
    return false;
  }
  var _default = _exports.default = Ember.Helper.helper(contains);
});