define("bet-ember/mixins/keep-popup-on-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    setZIndexOnDidRender: Ember.on('didRender', function () {
      if (this.get('keepPopupOnTopSettings') == null || this.get('keepPopupOnTopSettings').selectorId == null) {
        return;
      }
      var selectorId = this.get('keepPopupOnTopSettings').selectorId;
      if (Ember.maxZIndexPoputSelectorId) {
        return;
      }
      if (this.get('keepPopupOnTopSettings').property == null) {
        Ember.maxZIndexPoputSelectorId = selectorId;
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.setZIndex(selectorId);
        });
      } else {
        var property = this.get('keepPopupOnTopSettings').property;
        if (this.get(property) == true) {
          Ember.maxZIndexPoputSelectorId = selectorId;
          Ember.run.scheduleOnce('afterRender', this, function () {
            this.setZIndex(selectorId);
          });
        }
      }
    }),
    setZIndex(selectorId) {
      if (Ember.maxZIndexPoputSelectorId == selectorId) {
        var maxZIndex = this.utils.currentMaxZIndex();
        if (document.getElementById('modal-overlays') && document.getElementById('modal-overlays').lastElementChild) {
          $(document.getElementById('modal-overlays').lastElementChild).css('z-index', maxZIndex + 1);
        }
        if (document.getElementById(selectorId) && $(document.getElementById(selectorId))[0].parentNode) {
          var popup = $('#' + selectorId)[0].parentNode;
          $(popup).css('z-index', maxZIndex + 2);
        }
        if ($(this.element) && $(this.element)[0] && $(this.element)[0].lastElementChild) {
          $($(this.element)[0].lastElementChild).addClass('display-inline');
        }
        $(".loader-page").css('z-index', maxZIndex + 20);
        setTimeout(function () {
          Ember.maxZIndexPoputSelectorId = null;
        }, 250);
      }
    }
  });
});