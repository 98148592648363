define("bet-ember/helpers/reminder-class-by-km", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.remindByKm = remindByKm;
  function remindByKm([currentKm, remindOnKm, remindKmBefore]) {
    if (currentKm >= remindOnKm) {
      return 'txt-red';
    } else if (remindKmBefore && currentKm >= remindOnKm - remindKmBefore) {
      return 'txt-yewoll';
    }
    return 'txt-green';
  }
  var _default = _exports.default = Ember.Helper.helper(remindByKm);
});