define("bet-ember/routes/home/matrices/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.MainRoute.extend({
    renderTemplate: function (controller, model) {
      this.render('home.matrices.view', {
        model: model,
        into: 'home'
      });
    },
    model(params) {
      if (params && params._id) {
        return this.store.findRecord('matrix', params._id).catch(err => {
          logger(err);
        });
      } else {
        this.transitionTo('home.matrices');
        return null;
      }
    }
  });
});