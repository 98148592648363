define("bet-ember/components/auto-bets/search-item-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    event: Ember.computed('model', function () {
      return this.get('model.event');
    }),
    matrix: Ember.computed('model', function () {
      return this.get('model.matrix');
    }),
    targetA: Ember.computed('model', function () {
      return this.get('model.targetA');
    }),
    targetB: Ember.computed('model', function () {
      return this.get('model.targetB');
    }),
    onInit: Ember.on('init', function () {
      this.setRemainingTime();
      clearInterval(this.get('handler'));
      this.set('handler', setInterval(() => {
        this.setRemainingTime();
      }, 1 * 1000));
    }),
    willDestroyElement() {
      this._super(...arguments);
      clearInterval(this.get('handler'));
    },
    setRemainingTime() {
      let t1 = this.get("targetA.marketStartTime") < this.get('targetB.marketStartTime') ? this.get('targetA.marketStartTime') : this.get('targetB.marketStartTime');
      t1 = t1 || null;
      let time = null;
      if (this.get('model.startTime')) {
        time = this.get('model.startTime') - new Date().getTime();
      } else if (t1) {
        time = t1 - new Date().getTime();
      }
      if (time) {
        this.set('remainingTime', time / 1000);
      } else {
        this.set('remainingTime', null);
      }
    },
    actions: {
      run(item) {
        this.customAjax.call({
          endPoint: "autoBets/" + this.get('model.id') + "/run"
        });
      },
      remove(item) {
        this.router.transitionTo('home.matrices.view', item.get('id'));
      }
    }
  });
});