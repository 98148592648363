define("bet-ember/helpers/abs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abs = abs;
  _exports.default = void 0;
  function abs([val]) {
    return val != null ? Math.abs(val) : null;
  }
  var _default = _exports.default = Ember.Helper.helper(abs);
});