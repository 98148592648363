define("bet-ember/helpers/length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.length = length;
  function length(arr) {
    if (arr) {
      return arr.length;
    }
    return 0;
  }
  var _default = _exports.default = Ember.Helper.helper(length);
});