define("bet-ember/components/file-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Component,
    computed,
    observer,
    run: {
      bind
    },
    String: {
      htmlSafe
    },
    assert,
    $
  } = Ember;
  var _default = _exports.default = Component.extend({
    classNames: ['file-picker'],
    classNameBindings: ['multiple:multiple:single'],
    accept: '*',
    count: 0,
    errors: [],
    didInsertElement: function () {
      $(this.element).find('.file-uploader-input').on('change', bind(this, 'filesSelected'));
    },
    willDestroyElement: function () {
      $(this.element).find('.file-uploader-input').off('change', bind(this, 'filesSelected'));
    },
    /**
     * When the file input changed (a file got selected)
     * @param  {Event} event The file change event
     */
    filesSelected: function (event) {
      var files = event.target.files;
      if (files.length) {
        this.handleFiles(files);
      } else {
        //this.clearPreview();
      }
    },
    fileObserver: Ember.observer("uploadedFile", function () {
      if (!this.get("uploadedFile")) {
        this.removeFile();
      }
    }),
    handleFiles: function (files) {
      //if (this.get('multiple')) {
      //  this.sendAction('filesLoaded', files);
      //} else {
      this.set('uploadedFile', files[0]);
      this.sendAction('fileLoaded', files[0]);
      //}
    },
    removeFile() {
      if ($('.file-uploader-input')[0] != null) {
        var element = $('.file-uploader-input')[0];
        $(element).wrap('<form>').closest('form').get(0).reset();
        $(element).unwrap();

        // Prevent form submission
        //element.stopPropagation();
        //element.preventDefault();
      }
    },
    actions: {
      // Trigger a input click to open file dialog
      onClick: function () {
        //if (!$(event.target).hasClass('file-uploader-input')) {
        $(this.element).find('.file-uploader-input').trigger('click');
        //}
      },
      onFileRemove() {
        this.set('uploadedFile', null);
        this.sendAction('fileRemoved');
      }
    }
  });
});