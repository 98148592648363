define("bet-ember/components/events/runner-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    actions: {
      selectRunner(side) {
        if (this.get('selectEnabled')) {
          let runner = this.get('market.runners').find(p => p.get('selectionId') == this.get('runner.selectionId'));
          runner.addSide(side);
          if (this.get('onRunnerSelected')) {
            this.get('onRunnerSelected')(runner, side);
          }
        }
      }

      // makeBet(priceWrap, runner, side, model) {
      //   var instructions = {
      //     "orderType": "LIMIT",
      //     "selectionId": runner.selectionId,
      //     "side": side,
      //     "limitOrder": {
      //       "size": "2",//priceWrap.size,
      //       "price": "1000",//priceWrap.price,
      //       "persistenceType": "LAPSE"
      //     }
      //   }
      //
      //   this.customAjax.call({
      //     type:"POST",
      //     endPoint: "bet/makeBet",
      //     queryParams: {
      //       marketId: model.marketCatalogue.marketId
      //     },
      //     data:JSON.stringify(instructions)
      //   }).then((payload) => {
      //     logger(payload);
      //   }).catch((err) => {
      //     logger(err);
      //   })
      //
      // },
    }
  });
});