define("bet-ember/helpers/get-prop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getProp = getProp;
  function getProp([obj, key]) {
    return getProperty(obj, key);
  }
  var _default = _exports.default = Ember.Helper.helper(getProp);
});