define("bet-ember/helpers/not-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notEqual = notEqual;
  function notEqual([lhs, rhs]) {
    return lhs != rhs;
  }
  var _default = _exports.default = Ember.Helper.helper(notEqual);
});