define("bet-ember/serializers/market", ["exports", "ember-data", "bet-ember/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    primaryKey: 'marketId',
    attrs: {
      event: {
        embedded: 'always'
      },
      competition: {
        embedded: 'always'
      },
      runners: {
        embedded: 'always'
      }
    }
  });
});