define("bet-ember/templates/components/custom-native-time-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DUY1ZvEn",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[7,\"div\",true],[10,\"class\",\"input-date-wrap\"],[8],[0,\"\\n   \"],[7,\"input\",true],[11,\"min\",[22,\"minTime\"]],[11,\"max\",[22,\"maxTime\"]],[11,\"value\",[28,\"mut\",[[24,[\"internalValue\"]]],null]],[11,\"class\",[28,\"concat\",[\"form-control\",[24,[\"cssClass\"]]],null]],[11,\"required\",[22,\"required\"]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"onkeydown\",[28,\"action\",[[23,0,[]],\"onKeyDown\"],null]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"onChange\"],null]],[11,\"id\",[22,\"componentId\"]],[10,\"type\",\"time\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"internalValue\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",false],[12,\"class\",\"action ficon ficon-x \"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"internalValue\"]]],null],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\" \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/custom-native-time-component.hbs"
    }
  });
});