define("bet-ember/helpers/arr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arr = arr;
  _exports.default = void 0;
  function arr(elements) {
    elements = elements || [];
    return elements.filter(function (item) {
      return item !== undefined;
    });
  }
  var _default = _exports.default = Ember.Helper.helper(arr);
});