define("bet-ember/components/auth/login-component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    session: Ember.inject.service('session'),
    willDestroyElement() {
      this._super(...arguments);
      document.removeEventListener("keyup", this.OnEnterFunc);
    },
    actions: {
      login: function (event) {
        var formIsValid = this.formIsValid({
          selector: ".login-form"
        });
        if (!formIsValid) {
          return;
        }
        if (event && event.preventDefault) {
          event.preventDefault();
        }
        return this.get('session').authenticate('authenticator:custom', this.get('password')).then(() => {
          this.router.transitionTo('index');
        }).catch(err => {
          if (err && err.name == 'TransitionAborted') {
            return;
          }
          if (err && err.errorCode) {
            this.alert.show({
              message: this.get('intl').t(err.errorCode),
              type: 'danger'
            });
          }
        });
      }
    }
  });
});