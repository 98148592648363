define("bet-ember/helpers/get-owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    store: Ember.inject.service(),
    compute([object, type]) {
      var party,
        name = "";
      type = type || 'party';
      try {
        if (type === "device") {
          party = this.store.peekRecord('device', object.get('deviceId')).get('owner');
        } else if (type === "facility") {
          var facilityParty = this.store.peekRecord('facility', object.get('facilityId')).get('facilityParties').find(function (item, index) {
            return item.get('partyRoleType').get('roleTypeCode') === "FACILITY_PARTY_ROLE_OWNER";
          });
          if (facilityParty) {
            party = facilityParty.get('party');
          }
        } else if (type === "party") {
          party = this.store.peekRecord('party', object.get('partyId'));
        }
        if (party) {
          if (party.get('organization') && party.get('organization').get('organizationName')) {
            name = party.get('organization').get('organizationName');
          } else {
            name = (party.get('person').get('firstName') || "") + " " + (party.get('person').get('lastName') || "");
          }
        }
      } catch (ex) {
        return name;
      }
      return name;
    }
  });
});