define("bet-ember/services/alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var notifiactionEl = document.createElement('div');
  notifiactionEl.setAttribute('class', "alert");
  var closeSpan = document.createElement('span');
  closeSpan.setAttribute('class', "trigger ficon ficon-x");
  notifiactionEl.appendChild(closeSpan);
  var _default = _exports.default = Ember.Service.extend({
    intl: Ember.inject.service('intl'),
    utils: Ember.inject.service('utils'),
    loggerService: Ember.inject.service('logger'),
    handlers: [],
    displayServerErrors(rawErrors, translate) {
      if (rawErrors == null) {
        return;
      }
      var error = rawErrors.errorCode || rawErrors.decline_code || rawErrors.code || "unexpectedProblemOccured";
      logger(rawErrors);
      this.clearAll();
      this.show({
        message: this.get('intl').t(error),
        type: 'danger',
        delay: 6000
      });
      this.get('loggerService').log(rawErrors);

      //
      // if (rawErrors.errorCode) {
      //   errors.push(rawErrors.errorCode);
      // }
      // else if (rawErrors.message) {
      //   errors.push(rawErrors.message);
      // }
      // else if (Array.isArray(rawErrors)) {
      //   rawErrors.forEach(function (err) {
      //     errors.push(err);
      //   });
      // } else if (typeof rawErrors != 'object') {
      //   errors.push(rawErrors);
      // } else {
      //   console.log(rawErrors);
      //   return;
      // }

      // (function (_this) {
      //   errors.forEach(function (err) {
      //     _this.show({
      //       message:  _this.get('intl').t(err),
      //       type: 'danger',
      //       delay: 40000
      //     });
      //   });
      // })(this);
    },
    show({
      message,
      type,
      delay
    }) {
      let parentElement = document.getElementById('_notificationHolder'),
        tempEl = notifiactionEl.cloneNode(true);
      $(_notificationHolder).css('z-index', this.get('utils').currentMaxZIndex() + 2);
      switch (type) {
        case 'info':
          $(tempEl).addClass('alert-info');
          break;
        case 'warning':
          $(tempEl).addClass('alert-warning');
          break;
        case 'danger':
          $(tempEl).addClass('alert-danger');
          break;
        default:
          $(tempEl).addClass('alert-success');
      }
      delay = delay || 4000;
      tempEl.innerHTML += message;

      //parentElement.appendChild(tempEl);
      $(parentElement).removeClass('hidden');
      //$(tempEl).slideDown('slow');

      $(parentElement).prepend(tempEl);
      $(tempEl).hide().slideDown('slow');
      (function (el, delay) {
        let closeSpan = el.querySelector('span');
        var parentElement = el.parentNode;
        $(closeSpan).bind('click', removeEl);
        var handler;
        if (delay >= 0) {
          handler = setTimeout(removeEl, delay);
          this.handlers.push(handler);
        }
        function removeEl() {
          $(el).slideUp('slow', function () {
            clearTimeout(handler);
            if (parentElement.hasChildNodes(el)) {
              try {
                parentElement.removeChild(el);
              } catch (err) {
                console.log('alert removeChild error');
              }
              ;
            }
            if (parentElement.innerHTML === "") {
              $(parentElement).addClass('hidden');
            }
          });
        }
      }).call(this, tempEl, delay);
    },
    clearAll() {
      $('#_notificationHolder').empty();
      if (this.handlers && this.handlers.length > 0) {
        this.handlers.forEach(function (handler) {
          clearTimeout(handler);
        });
        this.handlers = [];
      }
    }
  });
});