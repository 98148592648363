define("bet-ember/components/pie-chart-draw-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    componentId: "pieChartContainer",
    draw: Ember.on('didRender', Ember.observer('model', function () {
      var chart,
        model = this.get('model') || [],
        opts = {
          animationEnabled: true,
          data: [{
            type: "pie",
            startAngle: 0,
            showInLegend: true,
            indexLabel: this.get('indexLabel') || "",
            dataPoints: model
          }],
          theme: "theme2",
          legend: {
            verticalAlign: "center",
            horizontalAlign: "left",
            itemclick: function (e) {
              if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
              } else {
                e.dataSeries.visible = true;
              }
              chart.render();
            }
          }
        };
      $(this.element).find('#' + this.get('componentId')).empty();
      chart = new CanvasJS.Chart(this.get('componentId'), opts);
      chart.render();
    }))
  });
});