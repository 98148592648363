define("bet-ember/services/logger", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    customAjax: Ember.inject.service('custom-ajax'),
    currentUser: Ember.inject.service('current-user'),
    log(error) {
      if (typeof error != 'string') {
        error = JSON.stringify(error);
      }
      var userLog = {
        error: error,
        userId: this.get('currentUser.user.id'),
        profileId: this.get('currentUser.selectedProfile.id'),
        fromDate: new Date().getTime(),
        email: this.get('currentUser.user.email') || 'anonymous'
      };
      try {
        this.get('customAjax').call({
          endPoint: 'logs',
          type: "POST",
          data: JSON.stringify(userLog)
        });
      } catch (err) {}
    }
  });
});