define("bet-ember/components/draw-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    componentId: "chartContainer",
    onInitComponent: Ember.on('init', function () {
      this.set('componentId', "draw-component-" + Ember.uuid());
    }),
    draw: Ember.on('init', Ember.observer('opts', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        $('#' + this.get('componentId')).empty();
        if (this.get('opts')) {
          var chart = new CanvasJS.Chart(this.get('componentId'), this.get('opts'));
          try {
            chart.render();
          } catch (err) {
            logger(err);
          }
          this.set('chart', chart);
          if (this.get('onChartCreated')) {
            this.get('onChartCreated')(chart);
          }
        }
      });
    }))
  });
});