define("bet-ember/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    countryCode: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    openDate: _emberData.default.attr('number'),
    venue: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    marketCount: _emberData.default.attr('number'),
    activeBetCount: _emberData.default.attr('number')
  });
});