define("bet-ember/helpers/t", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.t = t;
  function t([key]) {
    return this.get('intl').t(key);
  }
  var _default = _exports.default = Ember.Helper.extend({
    intl: Ember.inject.service('intl'),
    compute: t
  });
});