define("bet-ember/helpers/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.show = show;
  function show([arr, startIndex, count]) {
    var array = [];
    startIndex = startIndex || 0;
    count = count || 10;
    for (var i = startIndex; i < array.length && i < startIndex + count; i++) {
      array.push(arr[i]);
    }
    return array;
  }
  var _default = _exports.default = Ember.Helper.helper(show);
});