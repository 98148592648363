define("bet-ember/helpers/cmp-datetimes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cmpDateTimes = cmpDateTimes;
  _exports.default = void 0;
  function cmpDateTimes([val1, val2, op]) {
    val1 = new Date(val1).getTime();
    val2 = new Date(val2).getTime();
    if (op == "EQ" || op == null) {
      return val1 == val2;
    } else if (op == "GT") {
      return val1 > val2;
    } else if (op == "LT") {
      return val1 < val2;
    } else if (op == "GTE") {
      return val1 >= val2;
    } else if (op == "LTE") {
      return val1 <= val2;
    }
    return false;
  }
  var _default = _exports.default = Ember.Helper.helper(cmpDateTimes);
});