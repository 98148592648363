define("bet-ember/helpers/filter-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterBy = filterBy;
  function filterBy([arr, key, value, operator]) {
    arr = arr ? arr.toArray() : [];
    if (operator == "ISNULL" && key != null) {
      return arr.filter(function (item) {
        return getProperty(item, key) == null;
      });
    } else if (key != null && value != null) {
      if (operator) {
        switch (operator) {
          case "GT":
            {
              return arr.filter(function (item) {
                return getProperty(item, key) > value;
              });
            }
          case "GTE":
            {
              return arr.filter(function (item) {
                return getProperty(item, key) >= value;
              });
            }
          case "LT":
            {
              return arr.filter(function (item) {
                return getProperty(item, key) < value;
              });
            }
          case "LTE":
            {
              return arr.filter(function (item) {
                return getProperty(item, key) >= value;
              });
            }
          case "EQ":
            {
              return arr.filter(function (item) {
                return getProperty(item, key) == value;
              });
            }
          case "NEQ":
            {
              return arr.filter(function (item) {
                return getProperty(item, key) != value;
              });
            }
        }
      } else {
        return arr.filterBy(key, value);
      }
    } else {
      return null;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(filterBy);
});