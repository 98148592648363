define("bet-ember/helpers/divide-percent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.divide = divide;
  function divide([val1, val2]) {
    if (_getNumber(val1) == null || _getNumber(val2) == null) {
      return null;
    }
    return _getNumber(val1) % _getNumber(val2);
  }
  var _default = _exports.default = Ember.Helper.helper(divide);
});