define("bet-ember/components/qr-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var interval;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: "span",
    keepPopupOnTopSettings: {
      selectorId: "qr_popup_internal",
      property: "showModal"
    },
    supported: Ember.computed(function () {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) && this.utils.isMobile();
    }),
    onShow: Ember.observer('showModal', function () {
      if (this.get('showModal')) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          navigator.mediaDevices.getUserMedia({
            video: {
              width: 300,
              height: 300,
              facingMode: {
                exact: "environment"
              }
            }
          }).then(stream => {
            var video = document.querySelector('video');
            video.srcObject = stream;
            QCodeDecoder().decodeFromVideo(video, (er, res) => {
              if (this.get('codeDetected') && res) {
                this.get('codeDetected')(res);
                this.set('showModal', false);
              }
            });
          }).catch(err => {
            logger('err');
            this.set('error', "Error: " + err);
          });
        });
      }
    }),
    actions: {
      update(stream) {
        document.querySelector('video').src = stream.url;
      },
      closeModal() {
        this.set('showModal', false);
      }
    }
  });
});