define("bet-ember/helpers/less-than", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lt = lt;
  function lt([val1, val2]) {
    val1 = Ember.isArray(val1) ? val1.get("length") : val1;
    return val1 < val2;
  }
  var _default = _exports.default = Ember.Helper.helper(lt);
});