define("bet-ember/templates/components/utils/filter-btn-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XbbFeOUj",
    "block": "{\"symbols\":[],\"statements\":[[2,\" <span class=\\\"main-action combined hint-action highlight filter-btn-component\\\" id=\\\"{{componentId}}\\\" {{action 'click'}}>\\n\\t<span class=\\\"ficon ficon-filter\\\"></span>\\n</span> \"],[0,\"\\n\\n\"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"id\",\"action\",\"ficon\",\"label\",\"cssClass\"],[\"main\",[24,[\"componentId\"]],[28,\"action\",[[23,0,[]],\"click\"],null],\"ficon-filter\",[28,\"t\",[\"filter\"],null],[28,\"if\",[[24,[\"filterActive\"]],\"filter-active\"],null]]]],false],[0,\"\\n\"],[2,\" {{utils/btn-component type=\\\"main\\\" id=componentId action=(action 'click') ficon='ficon-filter' label=(t 'filter') cssClass='hint-action highlight filter-btn-component'}} \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/filter-btn-component.hbs"
    }
  });
});