define("bet-ember/components/utils/btn-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    translate: true,
    customId: Ember.computed('id', function () {
      return this.get('id') || Ember.uuid();
    }),
    compElement: Ember.computed('customId', function () {
      return $('#' + this.get('customId') + " .async-target")[0] || $('#' + this.get('customId'))[0];
    }),
    actionTriggerObserver: Ember.observer('triggerAction', function () {
      if (this.get('triggerAction')) {
        this.send('showModal');
      }
    }),
    loadingObserver: Ember.observer('status', function (type) {
      if (this.get('status') == 'LOADING') {
        this.loader.buttonEnable(this.get('compElement'));
      } else {
        this.loader.buttonDisable(this.get('compElement'));
      }
    }),
    willDestroyElement: Ember.on('willDestroyElement', function () {
      this._super(...arguments);
      clearTimeout(this.get('handler'));
    }),
    goto(path, id, event) {
      if (event && event.button == 1 || event.button == 2) {
        let url = this.router.generate("home." + path, id);
        window.open(url);
      } else {
        this.router.transitionTo("home." + path, id);
      }
    },
    doConfirm(btnCliked) {
      if (this.get('status') == "LOADING") {
        return;
      }
      this.set('status', "LOADING");
      if (this.get('action')) {
        try {
          var result = this.get('action')(btnCliked);
          if (result != null && result.then) {
            result.then(() => {
              try {
                this.set('handler', setTimeout(() => {
                  this.set('status', "READY");
                }, 250));
              } catch (err) {}
            });
          } else {
            this.set('status', "READY");
          }
        } catch (err) {
          if (this && this.set) {
            this.set('status', "READY");
          }
        }
      }
      this.set('isShowingModal', false);
    },
    actions: {
      onComponentClick(event) {
        var btnCliked = 0;
        if (event && event.button == 1 || event.button == 2) {
          btnCliked = event.button;
        }
        if (this.get('confirmationRequired') || this.get('delete')) {
          this.set('isShowingModal', true);
        } else {
          this.doConfirm(btnCliked);
        }
      },
      showModal(val) {
        this.set('isShowingModal', true);
      },
      closeModal() {
        this.set('isShowingModal', false);
      },
      confirm(event) {
        var btnCliked = 0;
        if (event && (event.button == 1 || event.button == 2)) {
          btnCliked = event.button;
        }
        this.doConfirm(btnCliked);
      }
    }
  });
});