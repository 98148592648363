define("bet-ember/models/market", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    marketId: _emberData.default.attr('string'),
    marketName: _emberData.default.attr('string'),
    marketStartTime: _emberData.default.attr('number'),
    description: _emberData.default.attr(),
    totalMatched: _emberData.default.attr('number'),
    betDelay: _emberData.default.attr('number'),
    bspReconciled: _emberData.default.attr('boolean'),
    complete: _emberData.default.attr('boolean'),
    crossMatching: _emberData.default.attr('boolean'),
    inplay: _emberData.default.attr('boolean'),
    isMarketDataDelayed: _emberData.default.attr('boolean'),
    keyLineDescription: _emberData.default.attr('string'),
    lastMatchTime: _emberData.default.attr('number'),
    numberOfActiveRunners: _emberData.default.attr('number'),
    numberOfRunners: _emberData.default.attr('number'),
    numberOfWinners: _emberData.default.attr('number'),
    runnersVoidable: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    totalAvailable: _emberData.default.attr('number'),
    totalMatched: _emberData.default.attr('number'),
    version: _emberData.default.attr('number'),
    event: _emberData.default.belongsTo('event'),
    competition: _emberData.default.belongsTo('competition'),
    runners: _emberData.default.hasMany('runner')
  });
});