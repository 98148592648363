define("bet-ember/services/new-bet-notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomService.extend({
    store: Ember.inject.service('store'),
    setup() {
      this.set('newBets', []);
      this.getNewBets();
      clearInterval(this.get('newBetLoaderHandler'));
      this.set('newBetLoaderHandler', setInterval(() => {
        this.getNewBets();
      }, 5 * 1000));
    },
    getNewBets() {
      return this.store.query("bet", {
        endPoint: "bets/new"
      }).then(data => {
        var bets = this.get('newBets').toArray();
        bets = bets.concat(data.toArray());
        this.set('newBets', bets);
      });
    }
  });
});