define("bet-ember/helpers/bit-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getBy = getBy;
  function getBy([val, bit]) {
    return bit_test(val, bit);
  }
  var _default = _exports.default = Ember.Helper.helper(getBy);
});