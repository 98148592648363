define("bet-ember/models/auto-bet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    content: _emberData.default.attr('string'),
    startTime: _emberData.default.attr('number'),
    endTime: _emberData.default.attr('number'),
    fromDate: _emberData.default.attr('number'),
    matrix: _emberData.default.belongsTo('matrix'),
    jsonContent: Ember.computed('content', function () {
      var jsonContent = this.get('content') != null ? JSON.parse(this.get('content')) : {};
      return jsonContent;
    }),
    event: Ember.computed('jsonContent', function () {
      var jsonContent = this.get('jsonContent') != null ? this.get('jsonContent') : {};
      return jsonContent.event || null;
    }),
    targetA: Ember.computed('jsonContent', function () {
      var jsonContent = this.get('jsonContent') != null ? this.get('jsonContent') : {};
      return jsonContent.targetA || null;
    }),
    targetB: Ember.computed('jsonContent', function () {
      var jsonContent = this.get('jsonContent') != null ? this.get('jsonContent') : {};
      return jsonContent.targetB || null;
    })
  });
});