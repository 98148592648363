define("bet-ember/templates/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+/JhPFeB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"main-layout-component\",null,[[\"outlet\"],[[24,[\"outlet\"]]]]],false],[0,\"\\n\"],[1,[22,\"new-bet-notification-component\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/home.hbs"
    }
  });
});