define("bet-ember/components/utils/filter-btn-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: "",
    componentId: Ember.computed(function () {
      return Ember.uuid();
    }),
    actions: {
      click() {
        if ($('html').hasClass('open-filter')) {
          //close filter
          $('html').removeClass('open-filter');
          $('#' + this.get('componentId')).removeClass('pressed');
          $('html').addClass('close-filter');
          // $('.filter-container').addClass('hidden');
          setTimeout(function () {
            $('html').removeClass('close-filter');
          }, 300);
        } else {
          //opan filter
          $('html').addClass('open-filter');
          // $('.filter-container').removeClass('hidden');
          $('#' + this.get('componentId')).addClass('pressed');
        }
      }
    }
  });
});