define("bet-ember/services/filter-service", ["exports", "bet-ember/mixins/filter-mixin"], function (_exports, _filterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(_filterMixin.default, {
    storage: Ember.inject.service('storage'),
    utils: Ember.inject.service('utils'),
    customAjax: Ember.inject.service('custom-ajax')
  });
});