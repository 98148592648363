define("bet-ember/initializers/web-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'web-client',
    after: ['ember-data', 'ember-simple-auth'],
    initialize: function () {
      $.ajaxSetup({
        host: Ember.host,
        namespace: Ember.namespace,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        headers: Ember.headers
      });
      let app = arguments[1] || arguments[0];
      Ember.noSetupServices = [['store', 'store'], ['customAjax', 'custom-ajax'], ['currentUser', 'current-user'], ['alert', 'alert'], ['filterStorage', 'filter-storage'], ['loader', 'loader'], ['storage', 'storage'], ['translate', 'translate'], ['utils', 'utils'], ['intl', 'intl']], Ember.customServices = [['eventTypeService', 'event-type'], ['matrixService', 'matrix']];
      Ember.noSetupServices.forEach(sv => this.injectFunc(app, sv));
      Ember.customServices.forEach(sv => this.injectFunc(app, sv));
    },
    injectFunc(app, sv) {
      app.inject('controller', sv[0], 'service:' + sv[1]);
      app.inject('component', sv[0], 'service:' + sv[1]);
      app.inject('route', sv[0], 'service:' + sv[1]);
      app.inject('template', sv[0], 'service:' + sv[1]);
      app.inject('mixin', sv[0], 'service:' + sv[1]);
      app.inject('model', sv[0], 'service:' + sv[1]);
    }
  };
});