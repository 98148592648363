define("bet-ember/templates/components/utils/giri-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "O2nEOOPj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[11,\"class\",[29,[\"label-row \",[22,\"cssClass\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col col-label\"],[8],[1,[22,\"label\"],false],[0,\":\"],[9],[7,\"div\",true],[10,\"class\",\"col col-txt\"],[8],[14,1],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/giri-component.hbs"
    }
  });
});