define("bet-ember/models/matrix", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    rules: Ember.computed('content', function () {
      var rules = this.get('content') != null ? JSON.parse(this.get('content')) : [];
      var finalRules = [];
      rules.forEach((rule, i) => {
        finalRules.pushObject(Ember.Object.create({
          targetA: Ember.Object.create(rule.targetA),
          targetB: Ember.Object.create(rule.targetB)
        }));
      });
      return finalRules;
    })
  });
});