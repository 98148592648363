define("bet-ember/components/popup/popup-component", ["exports", "bet-ember/mixins/keep-popup-on-top"], function (_exports, _keepPopupOnTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend(_keepPopupOnTop.default, {
    tagName: 'span',
    keepPopupOnTopSettings: Ember.computed('kpot', function () {
      if (this.get('kpot')) {
        return {
          selectorId: this.get('componentId'),
          property: "showModal"
        };
      } else {
        return null;
      }
    }),
    onCompInit: Ember.on('init', function () {
      document.addEventListener("close-popup", this.get('onClose'));
    }),
    willDestroyElement() {
      this._super(...arguments);
      document.removeEventListener("close-popup", this.get('onClose'));
    },
    componentId: Ember.computed(function () {
      return Ember.uuid();
    }),
    didInsertElement() {
      this._super(...arguments);
      if (this.get('afterRender')) {
        this.get('afterRender')();
      }
    },
    pupupObserver: Ember.observer('showModal', function () {
      if (this.get('showModal')) {
        $('body').addClass('modal-open');
      } else {
        $('body').removeClass('modal-open');
      }
    }),
    willDestroyElement() {
      this._super(...arguments);
      $('body').removeClass('modal-open');
    },
    actions: {
      closeModal() {
        if (this.get('onClose')) {
          this.get('onClose')();
        }
      }
    }
  });
});