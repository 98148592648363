define("bet-ember/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sum = sum;
  function sum(params) {
    var arr = params[0] || [];
    var prop = params[1];
    arr = arr.toArray();
    if (arr.length == 0) {
      return 0;
    }
    var sum = 0;
    if (prop) {
      arr.forEach(function (item) {
        sum += getProperty(item, prop, 0);
      });
    } else {
      arr.forEach(function (item) {
        sum += item;
      });
    }
    return sum;
  }
  var _default = _exports.default = Ember.Helper.helper(sum);
});