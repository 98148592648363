define("bet-ember/helpers/refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.refresh = refresh;
  function refresh([val, refresh]) {
    return val;
  }
  var _default = _exports.default = Ember.Helper.helper(refresh);
});