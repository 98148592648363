define("bet-ember/helpers/select-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.selectDate = selectDate;
  function selectDate([type]) {
    var date = new Date();
    switch (type) {
      case "weekStart":
        date = new Date(date.setDate(date.getDate() - date.getDay()));
        break;
      case "weekEnd":
        date = new Date(date.setDate(date.getDate() - date.getDay() + 6));
        break;
      case "monthStart":
        date = new Date(date.getFullYear(), date.getMonth(), 1);
        break;
      case "monthEnd":
        date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
    }
    return date;
  }
  var _default = _exports.default = Ember.Helper.helper(selectDate);
});