define("bet-ember/services/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var templateEl = document.createElement('section'),
    loaderItem = document.createElement('span'),
    loaderSpin = document.createElement('span');
  loaderItem.appendChild(loaderSpin);
  templateEl.appendChild(loaderItem);
  templateEl.setAttribute('class', "loader-wrap");
  loaderItem.setAttribute('class', "loader-item");
  loaderSpin.setAttribute('class', "loader-spin");
  var _default = _exports.default = Ember.Service.extend({
    componentEnable(targetEl) {
      if (targetEl) {
        let loaderEl = templateEl.cloneNode(true),
          parentEl = targetEl.parentNode;
        parentEl.insertBefore(loaderEl, targetEl);
        loaderEl.insertBefore(targetEl, loaderEl.firstChild);
      }
    },
    componentDisable(targetEl) {
      if (targetEl) {
        let loaderEl = targetEl.parentNode,
          parentEl = loaderEl.parentNode;
        parentEl.insertBefore(targetEl, loaderEl);
        $(loaderEl).remove();
      }
    },
    buttonEnable(btn) {
      if ($(btn).prop('nodeName') == "SPAN") {
        $(btn).addClass('ficon-loading');
      } else {
        $(btn).addClass('btn-loading');
      }
      $(btn).attr("disabled", true);
    },
    buttonDisable(btn) {
      $(btn).removeClass('btn-loading');
      $(btn).removeClass('ficon-loading');
      $(btn).removeAttr("disabled");
    },
    showGlobal(priority) {
      priority = priority || 0;
      this.set('_priority', priority);
      if ($('.page-content').hasClass('loader-wrap')) {
        return;
      }
      let loaderEl = loaderItem.cloneNode(true);
      $('.page-content').addClass('loader-wrap').append(loaderEl);
    },
    hideGlobal(priority) {
      priority = priority || 0;
      if (this.get('_priority') == null || this.get('_priority') <= priority) {
        $('.page-content').removeClass('loader-wrap').remove('.loader-item');
      }
    },
    beginTranstaction() {
      this.set('transaction', true);
    },
    closeTranstaction() {
      this.set('transaction', false);
    },
    clearPromiseStask() {
      this.setProperties({
        promises: [],
        lastCount: 0
      });
      $('.loader-page').remove();
    },
    processPromise(promise) {
      var promises = this.get('promises') || [];
      var promiseUUID = Ember.uuid();
      promises.pushObject(promiseUUID);
      this.set('promises', promises);
      (function (promiseUUID, _this) {
        promise.finally(() => {
          var promises = _this.get('promises') || [];
          promises.removeObject(promiseUUID);
          _this.set('promises', promises);
        });
      })(promiseUUID, this);
    },
    promsesObserver: Ember.observer('promises.[]', function () {
      var promisesCount = (this.get('promises') || []).length;
      var lastCount = this.get('lastCount') || 0;
      if (promisesCount == 1 && lastCount == 0) {
        this.show();
      } else if (promisesCount == 0 && lastCount == 1) {
        this.hide();
      }
      this.set('lastCount', promisesCount);
    }),
    show(promise) {
      var _this = this;
      $('.loader-page').remove();
      cancelAnimationFrame(this.get('rafhId'));
      var loader = createNewElement('div', null, 'loader-page');
      $('body').append(loader);
      $('.loader-page').width("1%");
      var width = 0;
      var progresRate = 5;
      var rafhId;
      function renderLoader() {
        width += progresRate;
        $('.loader-page').width(width + "%");
        if (width > 100) {
          $('.loader-page').remove();
          cancelAnimationFrame(_this.get('rafhId'));
        } else if (width >= 20 && width < 40) {
          progresRate = 4;
        } else if (width >= 40 && width < 60) {
          progresRate = 2;
        } else if (width >= 60 && width < 70) {
          progresRate = 1;
        } else if (width >= 70 && width < 80) {
          progresRate = 0.5;
        } else if (width >= 80 && width < 90) {
          progresRate = 0.01;
        } else if (width >= 90 && width < 95) {
          progresRate = 0.001;
        } else if (width >= 95 && width <= 100) {
          progresRate = 0.0001;
        }
        if (width <= 100) {
          rafhId = requestAnimationFrame(renderLoader);
          _this.set('rafhId', rafhId);
        }
      }
      rafhId = requestAnimationFrame(renderLoader);
      this.set('rafhId', rafhId);
      if (promise) {
        promise.finally(pr => {
          this.hide();
        });
      }
    },
    hide() {
      clearTimeout(this.get('hideLoaderHandlerId'));
      cancelAnimationFrame(this.get('rafhId'));
      var _this = this;
      requestAnimationFrame(function () {
        $('.loader-page').width("100%");
        var hideLoaderHandlerId = setTimeout(() => {
          $('.loader-page').remove();
        }, 700);
        _this.set('hideLoaderHandlerId', hideLoaderHandlerId);
      });
    }
  });
});