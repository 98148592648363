define("bet-ember/helpers/cond-oper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.condOper = condOper;
  _exports.default = void 0;
  function condOper([val1, val2, val3]) {
    return !(typeof val1 === 'undefined' || val1 === null || val1 === false) ? val2 : val3;
  }
  var _default = _exports.default = Ember.Helper.helper(condOper);
});