define("bet-ember/services/event-type", ["exports", "bet-ember/mixins/generic-service-mixin"], function (_exports, _genericServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(_genericServiceMixin.default, {
    modelName: "event-type",
    endPoint: "eventTypes",
    useChecksum: false
  });
});