define("bet-ember/templates/components/utils/filter-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PHVjJkTT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"filter-container content-flter-wrap\"],[11,\"style\",[28,\"safe-str\",[[28,\"concat\",[\"top:\",[28,\"add\",[[24,[\"utils\",\"appTopHeaderHeight\"]],[24,[\"utils\",\"appInfoHeaderHeight\"]]],null],\"px;\"],null]],null]],[8],[0,\"\\n\"],[2,\" style={{safe-str (concat \\\"top:\\\" (add (if utils.appTopHeaderHidden 0 utils.appTopHeaderHeight) utils.appInfoHeaderHeight) \\\"px;\\\")}}> \"],[0,\"\\n\"],[4,\"unless\",[[24,[\"hideFilterTitle\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"f-box cascade-phone cascade-medium mb-20\"],[8],[0,\"\\n\\t\"],[7,\"h4\",true],[10,\"class\",\"mt-0 mb-10 txt-medium txt-light-gray\"],[8],[1,[28,\"t\",[\"filterBy\"],null],false],[0,\":\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"filter-actions text-right box-item\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"clearFilters\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"action\",\"label\",\"cssClass\"],[\"btn\",[28,\"action\",[[23,0,[]],[24,[\"clearFilters\"]]],null],[28,\"t\",[\"clearFilters\"],null],\"btn-sm\"]]],false],[0,\"\\n\\t\\t\\t\"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"action\",\"label\",\"cssClass\"],[\"btn\",[28,\"action\",[[23,0,[]],\"click\"],null],[28,\"t\",[\"close\"],null],\"btn-sm\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/utils/filter-component.hbs"
    }
  });
});