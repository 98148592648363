define("bet-ember/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel: function () {
      var langCode = "en";
      this.set('intl.locale', langCode + '-' + langCode);
    }
  });
});