define("bet-ember/helpers/not-null", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notNull = notNull;
  function notNull([val]) {
    return !(val == null || val === "");
  }
  var _default = _exports.default = Ember.Helper.helper(notNull);
});