define("bet-ember/helpers/safe-str", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.safeStr = safeStr;
  function safeStr(str) {
    return Ember.String.htmlSafe(str || "");
  }
  var _default = _exports.default = Ember.Helper.helper(safeStr);
});