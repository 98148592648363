define("bet-ember/templates/components/async-btn-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TwBSRp3n",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"is-equal\",[[24,[\"type\"]],\"span\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",[28,\"concat\",[[24,[\"cssClass\"]],\" targetBtn link\"],null]],[3,\"action\",[[23,0,[]],\"click\",[24,[\"action\"]]]],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[22,\"iconClass\"]],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"text\"]]],null,{\"statements\":[[4,\"if\",[[28,\"not-equal\",[[24,[\"translate\"]],false],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[[24,[\"text\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\",false],[12,\"class\",[28,\"concat\",[[24,[\"cssClass\"]],\" targetBtn \"],null]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"click\",[24,[\"action\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"iconClass\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[11,\"class\",[22,\"iconClass\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"not-equal\",[[24,[\"translate\"]],false],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"t\",[[24,[\"text\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/async-btn-component.hbs"
    }
  });
});