define("bet-ember/mixins/router-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    on,
    inject
  } = Ember;
  var _default = _exports.default = Ember.Mixin.create({
    transitionHandler: null,
    loader: inject.service('loader'),
    currentUser: inject.service('current-user'),
    storage: inject.service('storage'),
    addClassesToHTML: on('didTransition', function () {
      var classes = location.pathname;
      if (classes.indexOf('app/') === 0) {
        classes = classes.replace('app/', '');
      }
      if (classes.indexOf('/') === 0) {
        classes = classes.replace('/', '');
      }
      if (classes[classes.length - 1] == '/') {
        classes = classes.substr(0, classes.length - 1);
      }
      var classesArr = classes.split('/');
      classes = classesArr.join(' ');
      $("html").removeClass().addClass(classes);
      if (this.utils.isMobile()) {
        $("html").addClass("mobile-detected");
      }
      $("#menu-content .force-link.active").removeClass('active');
      $("#menu-content .force-link.active-parent").removeClass('active-parent');
      var cssClass = classesArr[1];
      cssClass = "#menu-content #" + cssClass;
      $(cssClass).addClass('active');
      if ($(cssClass)[0] && $(cssClass)[0].getAttribute('data-parent')) {
        $("#menu-content #" + $(cssClass)[0].getAttribute('data-parent')).addClass('active-parent');
      }
      setTimeout(function () {
        $(cssClass).addClass('active');
        if ($(cssClass)[0] && $(cssClass)[0].getAttribute('data-parent')) {
          $("#menu-content #" + $(cssClass)[0].getAttribute('data-parent')).addClass('active-parent');
        }
      }, 200);
    }),
    closeLoaderTransaction: on('didTransition', function (arg) {
      this.get('loader').closeTranstaction();
    }),
    beginTransisionAnimation: on('willTransition', function (transaction) {
      if (this.get('storage.prevetTransitionAnimation')) {
        this.set('storage.prevetTransitionAnimation', false);
      } else {
        this.set('storage.inTransition', true);
      }
    }),
    endTransitionAnimation: on('didTransition', function (transaction) {
      this.set('storage.inTransition', false);
    })
  });
});