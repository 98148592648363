define("bet-ember/models/event-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    marketCount: _emberData.default.attr('number'),
    selectInfo: Ember.computed("name", "marketCount", function () {
      return this.get('name') + (this.get('marketCount') != null ? " (" + this.get('marketCount') + ")" : "");
    })
  });
});