define("bet-ember/templates/components/popup/main-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TX54MYUT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"main\",true],[11,\"class\",[29,[\"content \",[22,\"cssClass\"]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"inner-content\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/popup/main-component.hbs"
    }
  });
});