define("bet-ember/templates/components/settings/main-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M2KB1W21",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"header-title-place\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"settings\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"header-buttons-place\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"action\",\"ficon\",\"label\"],[\"main\",[28,\"action\",[[23,0,[]],\"save\"],null],\"ficon-save\",[28,\"t\",[\"save\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"action\",\"ficon\",\"label\"],[\"main\",[28,\"action\",[[23,0,[]],\"reload\"],null],\"ficon-refresh-ccw\",[28,\"t\",[\"reload\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"matrices/rules-popup-component\",null,[[\"showModal\",\"rules\"],[[24,[\"openRulePopup\"]],[24,[\"rules\"]]]]],false],[0,\"\\n\\n\"],[7,\"form\",true],[10,\"class\",\"save-form\"],[10,\"data-toggle\",\"validator\"],[10,\"role\",\"form\"],[8],[0,\"\\n\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"h4 bg-gray p-5\"],[8],[1,[28,\"t\",[\"general\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"form-group-component\",null,[[\"col\",\"label\",\"value\",\"componentType\",\"required\",\"disabled\"],[4,[28,\"t\",[\"disableAutoBetting\"],null],[24,[\"autoBettingDisabled\"]],\"checkbox\",true,[24,[\"disabled\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/settings/main-component.hbs"
    }
  });
});