define("bet-ember/models/runner", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    selectionId: _emberData.default.attr('number'),
    runnerName: _emberData.default.attr('string'),
    sortPriority: _emberData.default.attr('number'),
    handicap: _emberData.default.attr('number'),
    adjustmentFactor: _emberData.default.attr('number'),
    lastPriceTraded: _emberData.default.attr('number'),
    removalDate: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    totalMatched: _emberData.default.attr('number'),
    ex: _emberData.default.attr(),
    selectedSides: _emberData.default.attr('string'),
    addSide(side) {
      var sides = this.get('selectedSides') != null ? this.get('selectedSides').split(',') : [];
      sides.pushObject(side);
      this.set('selectedSides', sides.length > 0 ? sides.join(',') : null);
    },
    removeSide(side) {
      var sides = this.get('selectedSides') != null ? this.get('selectedSides').split(',') : [];
      sides.removeObject(side);
      this.set('selectedSides', sides.length > 0 ? sides.join(',') : null);
    },
    exchangePrices: Ember.computed('ex', function () {
      return this.get('ex');
    })
  });
});