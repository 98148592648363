define("bet-ember/services/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    onInitService: Ember.on('init', function () {
      var localStorage = Ember.Object.create({});
      var sessionStorage = Ember.Object.create({});
      for (let key in window.localStorage) {
        localStorage.set(key, window.localStorage[key]);
      }
      this.set('localStorage', localStorage);
      for (let key in window.sessionStorage) {
        sessionStorage.set(key, window.sessionStorage[key]);
      }
      this.set('sessionStorage', sessionStorage);
      this.clearSessionStates();
    }),
    defaultValues: {
      preferredPageSize: 10
    },
    checkForDefaultValue(key) {
      var defaultValues = this.get('defaultValues');
      if (defaultValues && defaultValues[key] != null) {
        return defaultValues[key];
      }
      return null;
    },
    getItem(key, isFromSessionStorage) {
      var value;
      var _key = key;
      if (key == 'preferredPageSize') {
        _key += extractKeyFromPath();
      }
      if (isFromSessionStorage === true) {
        value = window.sessionStorage.getItem(_key);
      } else {
        value = window.localStorage.getItem(_key);
      }
      if (value == null) {
        value = this.checkForDefaultValue(key);
      }
      try {
        value = JSON.parse(value);
        return value;
      } catch (err) {
        return value;
      }
    },
    setItem(key, value, isFromSessionStorage) {
      if (key == null || value === undefined) {
        return;
      }
      if (key == 'preferredPageSize') {
        key += extractKeyFromPath();
      }
      if (isFromSessionStorage === true) {
        window.sessionStorage.setItem(key, value);
        if (this.get('sessionStorage')) {
          this.get('sessionStorage').set(key, value);
        }
      } else {
        window.localStorage.setItem(key, value);
        if (this.get('localStorage')) {
          this.get('localStorage').set(key, value);
        }
      }
    },
    removeItem(key, isFromSessionStorage) {
      if (isFromSessionStorage === true) {
        window.sessionStorage.removeItem(key);
      } else {
        window.localStorage.removeItem(key);
      }
    },
    getLocalStorageKey(key) {
      return this.getItem(key);
    },
    setLocalStorageKey(key, value) {
      return this.setItem(key, value);
    },
    removeLocalStorageKey(key) {
      return this.removeItem(key);
    },
    getSessionStorageKey(key) {
      return this.getItem(key, true);
    },
    setSessionStorageKey(key, value) {
      return this.setItem(key, value, true);
    },
    removSessionStorageKey(key) {
      return this.removeItem(key, true);
    },
    getSessionStorageKeyForPage(key, page) {
      key = key + "_" + (page || extractKeyFromPath());
      return this.getItem(key, true);
    },
    setSessionStorageKeyForPage(key, value, page) {
      key = key + "_" + (page || extractKeyFromPath());
      return this.setItem(key, value, true);
    },
    removSessionStorageKeyForPage(key, page) {
      key = key + "_" + (page || extractKeyFromPath());
      return this.removeItem(key, true);
    },
    clearSessionStates() {
      var sessionStates = this.getSessionStorageKey('sessionStates');
      sessionStates = sessionStates ? sessionStates.split(',') : [];
      sessionStates.forEach((key, i) => {
        this.removSessionStorageKey(key);
      });
      this.removSessionStorageKey('sessionStates');
    },
    removeSessionState(id) {
      this.removSessionStorageKey(id);
    },
    setSessionState(state) {
      if (state && state.id) {
        var sessionStates = this.getSessionStorageKey('sessionStates', true);
        sessionStates = sessionStates ? sessionStates.split(',') : [];
        this.setSessionStorageKey(state.id, JSON.stringify(state));
        if (sessionStates.indexOf(state.id) < 0) {
          sessionStates.push(state.id);
        }
        this.setSessionStorageKey('sessionStates', sessionStates.join(','));
      }
    },
    getSessionState(id) {
      var state = this.getSessionStorageKey(id);
      state = state ? TryParse(state) : null;
      return state;
    },
    getSessionStateItemsReady(id) {
      var state = this.getSessionState(id);
      if (state && state.itemIds && state.itemIds.length && state.modelName) {
        var items = [];
        state.itemIds.forEach((id, i) => {
          items.push(this.store.peekRecord(state.modelName, id));
        });
        items = items.filter(p => p);
        state.items = items;
        return state;
      }
      return null;
    }
  });
});