define("bet-ember/helpers/to-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toHtml = toHtml;
  function toHtml([str]) {
    var div = document.createElement('div');
    div.innerHTML = str;
    return div;
  }
  var _default = _exports.default = Ember.Helper.helper(toHtml);
});