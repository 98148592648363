define("bet-ember/helpers/index-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.indexOf = indexOf;
  function indexOf(params) {
    var strA = params[0];
    var strB = params[1];
    if (strA != null && strA.toLowerCase && strB != null && strB.toLowerCase) {
      return strA.toLowerCase().indexOf(strB.toLowerCase());
    } else {
      return null;
    }
  }
  var _default = _exports.default = Ember.Helper.helper(indexOf);
});