define("bet-ember/components/matrices/rules-popup-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    on
  } = Ember;
  var _default = _exports.default = Ember.CustomComponent.extend({
    onCompInit: Ember.on('init', Ember.observer('showModal', function () {
      var rows = [];
      rows.pushObject(Ember.Object.create(this.get('newRow')));
      this.set('rows', rows);
    })),
    newRow: Ember.computed(function () {
      return {
        operation: "EQ",
        from: 0,
        to: 1,
        priceAdjustment: 0,
        step: 0.1,
        size: null
      };
    }),
    actions: {
      addRow() {
        this.get('rows').pushObject(Ember.Object.create(this.get('newRow')));
      },
      removeRow(row) {
        this.get('rows').removeObject(row);
      },
      closeModal() {
        this.set('showModal', false);
      },
      save() {
        this.alert.clearAll();
        var formIsValid = this.formIsValid({
          selector: ".rules-save-form"
        });
        if (!formIsValid) {
          return;
        }
        this.get('rows').forEach((row, i) => {
          let from = parseFloat(row.from);
          let to = parseFloat(row.to);
          let priceAdjustment = parseFloat(row.priceAdjustment);
          for (var i = from; i < to - 0.00001;) {
            i = parseFloat(i + parseFloat(row.step));
            this.get('rules').pushObject(Ember.Object.create({
              targetA: Ember.Object.create({
                operation: row.operation,
                price: parseFloat(parseFloat(i).toFixed(precision(parseFloat(row.step)))),
                priceAdjustment: priceAdjustment,
                size: row.size
              }),
              targetB: Ember.Object.create({
                operation: row.operation
              })
            }));
          }
        });
        this.set('showModal', false);
      }
    }
  });
});