define("bet-ember/helpers/regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.regex = regex;
  function regex([key]) {
    let patterns = {
      password: "(?=.*\\d)(?=.*\\W)(.){8,128}",
      //At least one digit, at least one special character
      email: "[\\w\\.-]{2,30}@(([\\w_-]+\\.)+[^\\W_]{2,4})$",
      //userName: "[^\\d\\^@#%*/&\\+-=~`':;,\\.<>()№€{}\\$\\?\\!\\|\\[\\]\\\"_\\\\]{1,45}",//Anything except characters in the [brackets]
      //organizationName: ".*",
      //deviceName: ".*",
      //attributeName: ".*",
      //productName: ".*",
      //notificationName: ".*",

      serialNumber: "([A-Za-z]{1})([0-9A-Za-z]{5})",
      flowRate: "^([0-9])*[.{1}]([0-9])*$"
    };
    return patterns[key];
  }
  var _default = _exports.default = Ember.Helper.helper(regex);
});