define("bet-ember/helpers/models-by-brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.modelsByBrand = modelsByBrand;
  function modelsByBrand([vehicleBrandId]) {
    return this.get('vehicleModelService.all').filter(p => p.get('vehicleBrand.id') == vehicleBrandId);
  }
  var _default = _exports.default = Ember.Helper.extend({
    vehicleModelService: Ember.inject.service('vehicle-model'),
    compute: modelsByBrand
  });
});