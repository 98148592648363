define("bet-ember/components/matrices/search-item-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.CustomComponent.extend({
    tagName: '',
    actions: {
      select(item) {
        this.router.transitionTo('home.matrices.view', item.get('id'));
      }
    }
  });
});