define("bet-ember/templates/components/form-group-label-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ia2Hc3OF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"label\"]],[24,[\"enableLineFix\"]]],null]],null,{\"statements\":[[7,\"label\",true],[11,\"class\",[29,[\"control-label \",[28,\"if\",[[24,[\"enableLineFix\"]],\"line-fix\"],null]]]],[11,\"for\",[22,\"componentId\"]],[8],[0,\"\\n  \"],[1,[22,\"label\"],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"ficon\"]],[24,[\"ficonText\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"ficonAction\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",[29,[\"ficon \",[22,\"ficon\"]]]],[3,\"action\",[[23,0,[]],[24,[\"ficonAction\"]]]],[8],[1,[22,\"ficonText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"ficon \",[22,\"ficon\"]]]],[8],[1,[22,\"ficonText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"labelInfo\"]]],null,{\"statements\":[[4,\"info-component\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"m-5\"],[8],[1,[28,\"to-html\",[[24,[\"labelInfo\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[2,\" {{utils/option-list-component noLabel=true ficon=\\\"ficon-more-horizontal\\\"}} \"],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/form-group-label-component.hbs"
    }
  });
});