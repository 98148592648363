define("bet-ember/templates/components/popup/header-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0cjImtxD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"header\",true],[10,\"class\",\"popup-header\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"header-actions\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\\t\"],[1,[28,\"utils/btn-component\",null,[[\"type\",\"ficon\",\"action\",\"label\"],[\"main\",\"ficon-cross\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],[28,\"or\",[[24,[\"closeLabel\"]],[28,\"t\",[\"close\"],null]],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bet-ember/templates/components/popup/header-component.hbs"
    }
  });
});